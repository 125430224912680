import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {FormDataService} from '../../shared/services/form-data/form-data.service';
import {TascaService} from '../services/tasca/tasca.service';
import {Tasca} from '../../shared/models/bustia/tasca.model';
import {GeecFormDTOModel} from '../../shared/models/forms/geec-form-dto.model';
import {Observable} from 'rxjs';
import {CustomUtils} from '../../shared/utils/custom.utils';

import {CustomRouterReuseService} from '../services/custom-router-reuse/custom-router-reuse.service';
import {ControllerResponse} from "../../shared/models/forms/controller-response.model";
import {of} from "rxjs/internal/observable/of";
import {flatMap} from "rxjs/operators";
import {AppConstants} from "../../app.constants";


@Injectable()
export class FormMassiveDataResolve implements Resolve<ControllerResponse<GeecFormDTOModel>> {

  constructor(private formDataService: FormDataService, private tascaService: TascaService,
              private router: Router, private routerReuseService: CustomRouterReuseService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ControllerResponse<GeecFormDTOModel>> {
    if (this.routerReuseService.isRouteBeingReused) {
      return of(null);
    }

    const taskId: string = route.params['taskId'];
    if (CustomUtils.isDefined(taskId)) {
      if (taskId) {
        let selectedTasca: Tasca = null;
        // acces per URL directe
        return <Observable<ControllerResponse<GeecFormDTOModel>>>this.tascaService.getTasca(taskId).pipe(flatMap((tasca: Tasca) => {
          if (CustomUtils.isDefined(tasca.taskeableId)) {
            this.tascaService.selectTasca(tasca);
            selectedTasca = tasca;
            return this.formDataService.getFormData(selectedTasca);
          } else {
            this.router.navigateByUrl(AppConstants.routeBustia);
          }
        }));
      }
    } else {
      this.router.navigateByUrl(AppConstants.routeBustia);
    }
  }
}

