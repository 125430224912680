import {Injectable} from "@angular/core";
import {SelectItem} from "primeng/api";
import {DadaMestra, DadaMestraService, DadaMestraType} from "../../../services/dada-mestra/dada-mestra.service";
import {CustomUtils} from "../../../utils/custom.utils";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ContratacionEstrategica} from "../../../models/indicadors/contratacion-estrategica";

@Injectable({
  providedIn: 'root'
})
export class IndicadorsControllerService {

  constructor(private _dadaMestraService: DadaMestraService) {
  }

  public getContratacionEstrategica(isMenor: boolean): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.CONTRATACION_ESTRATEGICA).pipe(map((data: DadaMestra[]) => {
      let contratacionEstrategica: DadaMestra[] = [];

      data.forEach((item) => {
        if (isMenor) {
          if (item.id !== ContratacionEstrategica.COMPRA_INNOVADORA) {
            contratacionEstrategica.push(item);
          }
        } else {
          contratacionEstrategica.push(item);
        }

      });

      return CustomUtils.toSelectItemArray(contratacionEstrategica);
    }, (err: any) => console.error(err)));
  }

  public getClausulasSociales(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.CLAUSULAS_SOCIALES).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }

  public getClausulasAmbientales(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.CLAUSULAS_AMBIENTALES).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }

  public getCompraInnovadora(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.COMPRA_INNOVADORA).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }

  public getVehiculosDirectiva(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.VEHICULOS_DIRECTIVA).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }
}
