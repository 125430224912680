import {NgModule, Type} from "@angular/core";
import {GeecFormDirective} from "./geec-form.directive";
import {GeecFormGroupDirective} from "./geec-form-group.directive";
import {GeecFormBuilder} from "./geec-form-builder";
import {GeecFormControlNameDirective} from "./geec-form-control-name.directive";
import {ShowErrorsComponent} from '../../../shared/components/show-errors/show-errors.component';
import {HelpButtonComponent} from "../../../shared/components/help-button/help-button.component";

const DIRECTIVES: Type<any>[] = [
  GeecFormDirective,
  GeecFormGroupDirective,
  GeecFormControlNameDirective
];

@NgModule({
  declarations: DIRECTIVES,
  providers: [GeecFormBuilder],
  exports: [DIRECTIVES],
  entryComponents: [
    ShowErrorsComponent,
    HelpButtonComponent
  ]
})
export class GeecReactiveFormsModule {
}
