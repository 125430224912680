import {LooseObject} from "./forms/reactive-form-validator";

export class TableStateModel {

  name: string = null;

  sortField: string = null;

  // 1 = ASCENDING, -1 = DESCENDING
  sortOrder: number = null;

  visibleColumns: string[] = [];

  filters: LooseObject<any>;

  rowsPerPage: number = 20;

  enabledExpansion: boolean = null;

}
