import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {AcordMarc} from "../../../models/expedient/acord-marc.model";
import {AcordMarcService} from "../../../services/acord-marc/acord-marc.service";
import {ExpedientIncasolService} from "../../../services/expedient-incasol/expedient-incasol.service";
import {ControllerResponse} from "../../../models/forms/controller-response.model";
import {NotificationsService} from "../../../../core/services/notifications/notifications.service";
import {UnitatPromotora} from "../../../../app.constants";
import {Message} from "primeng/api";
import {FormUtils} from "../../../utils/form-utils";
import {AutocompleteComponent} from "../../../components/autocomplete/autocomplete.component";

@Component({
  selector: 'geec-acord-marc-incasol',
  templateUrl: './acord-marc-incasol.html'
})
export class AcordMarcIncasolComponent implements OnInit {

  @Input() idExpedientIncasol: number;
  @Input() idProcediment: number;
  @Input() numeroExpedientParent: string;
  @Input() display: boolean;
  @Output() displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  acordMarcOrigenOptions: AcordMarc[];

  selectedAcordMarcOrigen: AcordMarc;

  @ViewChild(AutocompleteComponent) acordMarc: AutocompleteComponent;

  messages: Message[] = [];

  constructor(private _acordMarcService: AcordMarcService, private _expedientIncasolService: ExpedientIncasolService, private _notificationsService: NotificationsService, private _changeDetectorRef: ChangeDetectorRef) {
  }

  public getAcordsMarcOrigen(event: Event): void {
    this._loadAcordsMarc(event);
  }


  private _loadAcordsMarc(event: Event): void {
    this._acordMarcService.getAcordsMarcOrigen(UnitatPromotora.INCASOL, this.idProcediment, this.numeroExpedientParent).subscribe((acords: AcordMarc[]) => {
      this.acordMarcOrigenOptions = acords;
      if (this.acordMarcOrigenOptions.length === 1) {
        this.acordMarc.selectItem(this.acordMarcOrigenOptions[0]);
        this.acordMarc.setDisabledState(true);
        this.acordMarc.handleDropdownClick(event);
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  public crearExpedientIncasol(): void {
    this._expedientIncasolService.crearExpedientIncasol(this.idExpedientIncasol, this.selectedAcordMarcOrigen.id, this.selectedAcordMarcOrigen.nom).subscribe((response: ControllerResponse<string>) => {
      this._notificationsService.notify(response.messages[0].severity, response.messages[0].summary, response.messages[0].detail);
      this.displayChange.emit(false);
    }, error => {
      this.messages = FormUtils.handleError(error);
    });
  }

  ngOnInit(): void {
  }

  onHide(): void {
    this.displayChange.emit(false);
  }
}
