import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioTaskNode} from "./tramitacio-task-node";

export class TramitacioTaskEnviarSntNode extends TramitacioTaskNode {

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);

    this.taskIcon = "fa fa-envelope-o";
  }

}
