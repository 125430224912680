import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs/index";
import {AppConstants} from "../../../app.constants";
import {TramitModel} from "../../models/expedient/tramit.model";
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {CustomUtils} from "../../utils/custom.utils";

@Injectable()
export class TramitsService {
  constructor(private http: HttpClient) {
  }

  loadTramits(searchText: string): Observable<TramitModel[]> {
    let paramsMap: LooseObject<string> = {};
    if (CustomUtils.isNotEmpty(searchText)) {
      paramsMap['like'] = searchText;
    }
    let params: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.get<TramitModel[]>(`${AppConstants.urlTramits}`, {params: params});
  }

}
