import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CustomUtils} from '../../utils/custom.utils';

@Component({
  selector: 'geec-boolean-check',
  templateUrl: './boolean-check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanCheckComponent {

  checked: boolean;

  valueDefined: boolean;

  @Input() set value(val: boolean) {
    this.valueDefined = CustomUtils.isDefined(val);
    this.checked = val;
  }

}
