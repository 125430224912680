import {TramitacioNode} from "./tramitacio-node";
import {EstatActuacioContractual, TramitacioNodeAction, TramitacioNodeType} from "./model";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {AppConstants} from "../../app.constants";

enum TramitacioEstructuralNodeType {
  CODI_MANAMENT = "CODI_MANAMENT",
  INFORME_FISCAL = "INFORME_FISCAL"
}

export class TramitacioEstructuralNode extends TramitacioNode {

  public processInstanceId: number;
  public subprocessInstanceId: number | null;
  public tipusNode: TramitacioNodeType;
  public tipusNodeEstructural: TramitacioEstructuralNodeType;
  public idReferencia: number;
  public ordre: number;
  public estat?: string;
  public integracioOrigen?: number;
  public pluriennal?: boolean;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.ordre = backendJSON.ordre;
    this.processInstanceId = backendJSON.processInstanceId;
    this.subprocessInstanceId = backendJSON.subprocessInstanceId;
    this.tipusNode = backendJSON.tipusNode;
    this.tipusNodeEstructural = backendJSON.tipusNodeEstructural;
    this.idReferencia = backendJSON.idReferencia;
    this.estat = backendJSON.estat;
    this.integracioOrigen = backendJSON.integracioOrigen;
    this.pluriennal = backendJSON.pluriennal;

    this.styleClass = "estructural";
    switch (this.tipusNodeEstructural) {
      case TramitacioEstructuralNodeType.CODI_MANAMENT:
        this.styleClass = (backendJSON.estat === "ACTIVE") ? "complete" : "document-anulat";
        this.icon = 'fa fa-money';
        break;
      case TramitacioEstructuralNodeType.INFORME_FISCAL:
        this.styleClass = null;
        break;
      default:
        if (!backendJSON.children) {
          this.icon = 'fa fa-folder';
        }
        break;
    }

    if (this.tipusNode === TramitacioNodeType.raju) {
      this.selectable = true;
      this.actions.push({
        key: TramitacioNodeAction.anularActuacio,
        labelButton: 'Anul·lar reajustament',
        iconButton: 'fa fa-hand-o-down',
        callbackData: "Iniciar procés reajustament"
      });

      if (this.estat && this.estat === EstatActuacioContractual.ANULADA) {
        this.styleClass = this.estat.toLowerCase() || `${this.styleClass} ${this.estat.toLowerCase()}`;
      }
    } else if (this.tipusNode === TramitacioNodeType.modificacio) {
      this.selectable = true;
      if (this.estat === EstatActuacioContractual.EN_TRAMIT) {
        this.actions.push(
          {
            key: TramitacioNodeAction.anularActuacio,
            labelButton: 'Baixa/Anul·lació modificació',
            iconButton: 'fa fa-hand-o-down',
            callbackData: "Iniciar procés modificació"
          });
      } else if (this['motiuBaixa']) {
        this.actions.push(
          {
            key: TramitacioNodeAction.motiu,
            labelButton: 'Veure motiu de baixa de la modificació',
            iconButton: 'fa fa-eye'
          });
      }

      if (this.estat && this.estat === EstatActuacioContractual.ANULADA) {
        this.styleClass = this.estat.toLowerCase() || `${this.styleClass} ${this.estat.toLowerCase()}`;
      }
    } else if (this.tipusNode === TramitacioNodeType.sbem) {
      this.selectable = true;
      if (this.estat === EstatActuacioContractual.EN_TRAMIT) {
        this.actions.push(
          {
            key: TramitacioNodeAction.anularActuacio,
            labelButton: 'Baixa sbem',
            iconButton: 'fa fa-hand-o-down',
            callbackData: "Iniciar procés sbem"
          });
      } else if (this['motiuBaixa']) {
        this.actions.push(
          {
            key: TramitacioNodeAction.motiu,
            labelButton: 'Veure motiu de baixa del sbem',
            iconButton: 'fa fa-eye'
          });
      }
    } else if (this.tipusNode === TramitacioNodeType.sboc) {
      // Decomentar quan es pugui fer la baixa de l'SBOC
      // this.selectable = true;

      // if (this.estat === EstatActuacioContractual.EN_TRAMIT) {
      //   this.actions.push(
      //     {
      //       key: TramitacioNodeAction.anularActuacio,
      //       labelButton: 'Baixa sboc',
      //       iconButton: 'fa fa-hand-o-down',
      //       callbackData: "Iniciar procés sboc"
      //     });
      // } else if (this['motiuBaixa']) {
      //   this.actions.push(
      //     {
      //       key: TramitacioNodeAction.motiu,
      //       labelButton: 'Veure motiu de baixa del sboc',
      //       iconButton: 'fa fa-eye'
      //     });
      // }

      if (this.estat && this.estat === EstatActuacioContractual.ANULADA) {
        this.styleClass = this.estat.toLowerCase() || `${this.styleClass} ${this.estat.toLowerCase()}`;
      }
    }

    if (this._potIniciarTraspasSenseIntegracio()) {
      this.actions.push(
        {
          key: TramitacioNodeAction.traspasSenseIntegracio,
          labelButton: `Iniciar procés traspàs ${TramitacioEstructuralNode.calculateSituacioContractualMessage(this.tipusNode)}`,
          iconButton: 'fa-hand-o-down',
          callbackData: {idTaskeable: this.idReferencia, numero: this.label, tipus: this.tipusNode}
        }
      );
    }

    if (this.isActiveNode) {
      this.styleClass = 'assigned';
    }
  }

  public static calculateSituacioContractualMessage(tipus: TramitacioNodeType): string {
    switch (tipus) {
      case TramitacioNodeType.sbem:
        return "subrogació";
      case TramitacioNodeType.modificacio:
        return "modificació";
      case TramitacioNodeType.sboc:
        return "sboc";
      default:
        return "situació contractual";
    }
  }

  private _potIniciarTraspasSenseIntegracio(): boolean {
    return (this.tipusNode === TramitacioNodeType.sbem || this.tipusNode === TramitacioNodeType.modificacio)
      && (this.estat === EstatActuacioContractual.EN_TRAMIT || this.estat === EstatActuacioContractual.FINALITZADA)
      && this.isSenseIntegracioComptableAndPluriennal();
  }

  public isSenseIntegracioComptableAndPluriennal(): boolean {
    return this.integracioOrigen === AppConstants.SENSE_INTEGRACIO_COMPTABLE && this.pluriennal;
  }
}
