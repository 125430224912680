import {Injectable} from '@angular/core';
import {BustiaTableStateModel} from "../../../bustia/model/bustia-table-state.model";

@Injectable()
export class BustiaService {

  constructor() {
  }

  private _activeTableState: BustiaTableStateModel;

  get activeTableState(): BustiaTableStateModel {
    return this._activeTableState;
  }

  set activeTableState(activeTableState: BustiaTableStateModel) {
    this._activeTableState = activeTableState;
  }

}
