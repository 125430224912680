export enum TipusContracte {
  SERVEIS = 393,
  SUBMINISTRAMENTS = 394,
  OBRES = 395,
  CONCESSIO_SERVEIS = 396,
  ADMINISTRATIU_ESPECIAL = 397,
  CONCESSIO_OBRES = 398,
  PATRIMONIAL = 399,
  PRIVADO = 498,
  SECTORES_EXCLUIDOS = 499
}
