import {TreeNode} from "primeng/api";
import {CustomUtils} from "./custom.utils";

export class TreeUtils {

  static expandAllNodes(nodes: TreeNode[]): void {
    if (CustomUtils.isArrayNotEmpty(nodes)) {
      nodes.forEach((node: TreeNode) => {
        TreeUtils.expandRecursively(node, true);
      });
    }
  }

  static collapseAllNodes(nodes: TreeNode[]): void {
    if (CustomUtils.isArrayNotEmpty(nodes)) {
      nodes.forEach((node: TreeNode) => {
        TreeUtils.expandRecursively(node, false);
      });
    }
  }

  static applyNodeStyle(nodes: TreeNode[], checkFunction: (node: TreeNode) => boolean, styleClassIfTrue: string, styleClassIfFalse?: string): void {
    if (CustomUtils.isArrayNotEmpty(nodes)) {
      nodes.forEach((node: TreeNode) => {
        if (checkFunction(node)) {
          node.styleClass = styleClassIfTrue;
        } else if (styleClassIfFalse) {
          node.styleClass = styleClassIfFalse;
        }
        TreeUtils.applyNodeStyle(node.children, checkFunction, styleClassIfTrue, styleClassIfFalse);
      });
    }
  }

  static findNode(nodes: TreeNode[], checkFunction: (node: TreeNode) => boolean): TreeNode {
    if (CustomUtils.isArrayNotEmpty(nodes)) {
      for (let node of nodes) {
        if (checkFunction(node)) {
          return node;
        }
        const found: TreeNode = TreeUtils.findNode(node.children, checkFunction);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  private static expandRecursively(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode: TreeNode) => {
        this.expandRecursively(childNode, isExpand);
      });
    }
  }

}
