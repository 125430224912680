import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {AppConstants} from "../../../app.constants";
import {BlockedTask, TascaReduida} from "../../../shared/models/tramitacio-massiva/tasca-reduida.model";
import {Confirmation, ConfirmationService} from "primeng/api";
import {ControllerResponse} from "../../../shared/models/forms/controller-response.model";
import {GeecFormDTOModel} from "../../../shared/models/forms/geec-form-dto.model";
import {DocumentacioMassivaFormModel} from "../../../documentacio-massiva/models/documentacio-massiva-form.model";
import {DocumentExpedient} from "../../../documentacio/models/document-expedient.model";
import {FrontDTO} from "../../../shared/models/forms/front-dto.model";
import {PortasignaturesFormModel} from "../../../documentacio/models/portasignatures-form.model";
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";

@Injectable()
export class TramitacioMassivaService {

  constructor(private _http: HttpClient, private _confirmationService: ConfirmationService) {
  }

  getForm(generalTaskId: string): Observable<ControllerResponse<GeecFormDTOModel>> {
    return this._http.get<ControllerResponse<GeecFormDTOModel>>(`${AppConstants.urlTramitacioMassiva}/forms/${generalTaskId}`);
  }

  getTasquesTramitacioMassiva(taskId: string): Observable<TascaReduida[]> {
    return this._http.get<TascaReduida[]>(`${AppConstants.urlTramitacioMassiva}/${taskId}`);
  }

  submitForm(generalTaskId: string, formDTO: any): Observable<ControllerResponse<GeecFormDTOModel>> {
    return this._http.post<ControllerResponse<GeecFormDTOModel>>(`${AppConstants.urlTramitacioMassiva}/save/${generalTaskId}`, formDTO);
  }

  saveNewDocument(document: DocumentExpedient, taskId: string): Observable<ControllerResponse<DocumentacioMassivaFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioMassivaFormModel>>(`${AppConstants.urlTramitacioMassiva}/saveDocument/${taskId}`, document);
  }

  continuarTramit(generalTaskId: string, docObligatori: boolean = true, formDTO: any): Observable<ControllerResponse<GeecFormDTOModel>> {
    const paramsMap: LooseObject<string> = {};
    paramsMap['docObligatori'] = docObligatori.toString();
    const httpParams: HttpParams = new HttpParams({fromObject: paramsMap});
    return this._http.post<ControllerResponse<GeecFormDTOModel>>(`${AppConstants.urlTramitacioMassiva}/next/${generalTaskId}`, formDTO, {params: httpParams});
  }

  bockTasks(taskIds: string[]): Observable<BlockedTask[]> {
    return this._http.post<BlockedTask[]>(`${AppConstants.urlTramitacioMassiva}/blockTasks`, taskIds);
  }

  unblockTasks(generalTaskId: string): Observable<null> {
    return this._http.get<null>(`${AppConstants.urlTramitacioMassiva}/unblockTasks/${generalTaskId}`);
  }

  confirmation(confirmation: Confirmation): void {
    this._confirmationService.confirm(confirmation);
  }

  deleteDocument(generalTaskId: string): Observable<ControllerResponse<DocumentacioMassivaFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioMassivaFormModel>>(`${AppConstants.urlTramitacioMassiva}/esborrar/${generalTaskId}`, null);
  }

  createDocument(generalTaskId: string): Observable<ControllerResponse<DocumentacioMassivaFormModel>> {
    return this._http.post<ControllerResponse<DocumentacioMassivaFormModel>>(`${AppConstants.urlTramitacioMassiva}/plantilla/${generalTaskId}`, null);
  }

  getPortaSignatures(generalTaskId: string): Observable<{ portaSignatures: PortasignaturesFormModel }> {
    return this._http.get<{ portaSignatures: PortasignaturesFormModel }>(`${AppConstants.urlTramitacioMassiva}/portaSignatures/${generalTaskId}`);
  }

  enviarPortasignatures(generalTaskId: string): Observable<ControllerResponse<any>> {
    return this._http.post<ControllerResponse<any>>(`${AppConstants.urlTramitacioMassiva}/portaSignatures/${generalTaskId}`, null);
  }

  triggerFormValidation(body: FrontDTO): Observable<GeecFormDTOModel> {
    return this._http.post<GeecFormDTOModel>(`${AppConstants.urlTramitacioMassiva}/change`, body);
  }

  translateTaskRoute(route: string): string {
    switch (route) {
      case "documentacio" :
        return "documentacio-massiva";
      case "dades-basiques-nm" :
        return "dades-basiques-nm/tramitacio-massiva";
      case "document-comptable" :
        return "document-comptable/tramitacio-massiva";
    }
  }

}
