
  /**
   * Branch: heads/feature/entorno-int-demo
   * Last commit: 
   *
   * commit 7d3852883f97c810bb99997f6794496f68cf37bc
   * Merge: 1ef91d7d6 88dcf28e3
   * Author: Juan Allanegui <juan.allanegui2@dxc.com>
   * Date:   Tue Dec 12 18:48:30 2023 +0100
   *     Merged in feature/entorno-int (pull request #210)
   *     
   *     # Conflicts:
   *     #       src/app/core/components/footer/footer.component.html
   *     #       src/environments/version.ts
   */
   
   
  export const version = '1.3.0-build.12-12-2023 19:51:28';

