import {Directive, EventEmitter, forwardRef, Inject, Optional, Self} from '@angular/core';
import {ControlContainer, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgForm} from "@angular/forms";
import {GeecFormGroup} from "./geec-form-group";
import {composeAsyncValidators, composeValidators} from "./shared";


const formDirectiveProvider: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => GeecFormDirective)
};

@Directive({
  selector: 'form:not([ngNoForm]):not([geecFormGroup]),ngGeecForm,[ngGeecForm]',
  providers: [formDirectiveProvider],
  host: {'(submit)': 'onSubmit($event)', '(reset)': 'onReset()'},
  outputs: ['ngSubmit'],
  exportAs: 'geecForm'
})
export class GeecFormDirective extends NgForm {
  form: GeecFormGroup;
  ngSubmit = new EventEmitter();

  constructor(@Optional() @Self() @Inject(NG_VALIDATORS) validators: any[],
              @Optional() @Self() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: any[]) {

    super(validators, asyncValidators);

    this.form = new GeecFormGroup({}, composeValidators(validators), composeAsyncValidators(asyncValidators));
  }


}
