import {
  Compiler,
  Component,
  ComponentFactory,
  ComponentRef,
  NgModule,
  NgModuleRef,
  Type,
  ViewContainerRef
} from "@angular/core";
import {RootInjectorService} from "../services/root-injector.service";

/**
 * @private
 * {Map<Function, ComponentFactory<any>>} _factoryMap - Stores component factories.
 */
const _factoryMap: Map<string, ComponentFactory<any>> = new Map();

/**
 * @class
 * @name DynamicViewBuilder
 * @desc A class to generate dynamic views from code.
 * @author uriel-huertas
 */
export class DynamicViewBuilder {

  /**
   * @private
   * @property
   * @readonly {Compiler} _compiler - The compiler.
   */
  private readonly _compiler: Compiler;

  /**
   * @private
   * @property
   * @readonly {NgModuleRef<any>>} _ngModuleRef - The module reference.
   */
  private readonly _ngModuleRef: NgModuleRef<any>;

  /**
   * @private
   * @property
   * @readonly {ViewContainerRef} _viewContainerRef - The view container reference.
   */
  private readonly _viewContainerRef: ViewContainerRef;

  /**
   * @constructor
   * @param {ViewContainerRef} _viewContainerRef
   */
  constructor(_viewContainerRef: ViewContainerRef) {
    this._viewContainerRef = _viewContainerRef;
    this._compiler = RootInjectorService.injector.get(Compiler);
    this._ngModuleRef = RootInjectorService.injector.get(NgModuleRef);
  }

  /**
   * Generates a simple view from a template.
   * (I'd rather not use this.)
   * @experimental
   * @param {string} template
   * @param {any} [clazz]
   * @return {ComponentRef<any>}
   */
  simpleGenerate(template: string, clazz: any = class {}): ComponentRef<any> {
    const tempComponent: Type<Component> = Component({template: template})(clazz);
    const tempModule: Type<NgModule> = NgModule({declarations: [tempComponent]})(class {});

    return this._build(tempModule, clazz.toString());
  }

  /**
   * Generates a view using a component and a module.
   * @param {Component} componentMetadata
   * @param {any} component
   * @param {NgModule} moduleMetadata
   * @param {any} [module]
   * @return {ComponentRef<T>>}
   */
  generate<T>(componentMetadata: Component, component: any, moduleMetadata: NgModule, module: any = class {}): ComponentRef<T> {
    const tempComponent: Type<Component> = Component(componentMetadata)(component);

    if (!moduleMetadata.declarations) {
      moduleMetadata.declarations = [];
    }

    moduleMetadata.declarations.push(tempComponent);

    const tempModule: NgModule = NgModule(moduleMetadata)(module);

    return this._build(<Type<NgModule>> tempModule, component.toString());
  }

  /**
   * Builds and compiles some module to get a component reference.
   * @private
   * @param {Type<NgModule>>} module
   * @param {*} componentFn
   * @return {ComponentRef<any>}
   */
  private _build(module: Type<NgModule>, componentFn: string): ComponentRef<any> {
    let factory: ComponentFactory<any> = _factoryMap.get(componentFn);

    if (!factory) {
      const factories = this._compiler.compileModuleAndAllComponentsSync(module);
      factory = factories.componentFactories.find((_factory: ComponentFactory<any>) => _factory.selector === 'ng-component');
      _factoryMap.set(componentFn, factory);
    }

    const componentRef: ComponentRef<any> = factory.create(RootInjectorService.injector, [], null, this._ngModuleRef);
    this._viewContainerRef.insert(componentRef.hostView);

    return componentRef;
  }

  /**
   * Deletes a certain component factory.
   * @param {T} component
   */
  public deleteFactory<T>(component: T): void {
    _factoryMap.delete(component.toString());
    this._compiler.clearCacheFor(<any>component);
  }

  /**
   * Deletes all factories in cache.
   */
  public deleteAllFactories(): void {
    _factoryMap.clear();
    this._compiler.clearCache();
  }

}
