import {NoticeBoardComponent} from "../../components/notice-board/notice-board.component";
import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from "@angular/core";
import {CustomUtils} from "../../../shared/utils/custom.utils";

@Injectable({
  providedIn: 'root'
})
export class NoticeBoardService {
  dialogComponentRef: ComponentRef<NoticeBoardComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  public openNoticeBoard(loadUrgentNoticeOnly?: boolean): void {
    const componentFactory: ComponentFactory<NoticeBoardComponent> = this.componentFactoryResolver.resolveComponentFactory(NoticeBoardComponent);
    const componentRef: ComponentRef<NoticeBoardComponent> = componentFactory.create(this.injector);
    if (CustomUtils.isDefined(loadUrgentNoticeOnly)) {
      componentRef.instance.loadUrgentNoticeOnly = loadUrgentNoticeOnly;
    }
    componentRef.instance.onClose$.subscribe(() => this.closeNewsBoard());
    this.appRef.attachView(componentRef.hostView);

    const domElem: HTMLElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;
  }

  private closeNewsBoard(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

}
