import {RolsUnitatModel} from "./rols-unitat.model";

export interface Usuari {
  id: number;
  nif: string;
  nom: string;
  cognoms: string;
  dataAlta: Date;
  dataBaixa: Date;
  email: string;
  telefon: string;
  rolsUnitat: RolsUnitatModel[];
  organismes: string[];
  permisos: PermisUsuari[];
  dataUltimAcces: Date;
  expertGeec: boolean;
  hasUrgentNotices: boolean;
}

export interface UsuariReduit {
  id: number;
  fullName: string;
}

export interface UsuariDadesUnitat {
  organisme: string;
  unitat: string;
  nom: string;
  cognoms: string;
  email: string;
  rol: string;
  dataInici: Date;
  dataFi: Date;
  dataBaixa: Date;
  expertGeec: boolean;
}

export enum PermisUsuari {
  CREAR_EXPEDIENT = "CREAR_EXPEDIENT",
  CONSULTA_LICITADOR = "CONSULTA_LICITADOR",
  MODIFICA_LICITADOR = "MODIFICA_LICITADOR",
  TASQUES_ADMINISTRADOR_ORGAN = "TASQUES_ADMINISTRADOR_ORGAN",
  MANTENIMENT_AJUDA = "MANTENIMENT_AJUDA",
  MANTENIMENT_USUARIS = "MANTENIMENT_USUARIS",
  SUPER_ADMIN = "SUPER_ADMIN",
  BUSTIA_INCASOL = "BUSTIA_INCASOL",
  BUSTIA_FACTURES = "BUSTIA_FACTURES",
  ACTUACIONS_CONTRACTUALS = "ACTUACIONS_CONTRACTUALS",
  GESTOR_AVISOS = "GESTOR_AVISOS",
  GESTIO_ENTITATS_PETICIONARIES = "GESTIO_ENTITATS_PETICIONARIES",
  LLISTAT_LIQUIDACIO = "LLISTAT_LIQUIDACIO",
  CREAR_ECOM = "CREAR_ECOM"
}
