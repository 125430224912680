import {FormField, LooseObjectFieldData, SelectItemData, ValuesField} from './geec-form-dto.model';
import {CustomUtils} from '../../utils/custom.utils';
import {HelpTextType} from "../../components/help-button/models/help-text-type.enum";
import {TranslateService} from "@ngx-translate/core";


export interface LooseObject<T> {
  [key: string]: T;
}

export class ReactiveFormValidator implements LooseObject<any> {

  constructor(private _rawFields: FormField[], private _readonly: boolean = false,
              private _translate: TranslateService) {
    _rawFields = _rawFields.sort((leftEle, rightEle) => leftEle.name > rightEle.name ? 1 : -1);
    this._createFormValidator(_rawFields);
  }

  private traducir(tag: string): string {
    let ruta = 'reactive-form-validator';
    return this._translate.instant(ruta + '.' + tag);
  }

  /**
   * Converts LooseObjectFieldData to SelectItemData
   * @param {LooseObjectFieldData} data
   * @returns {SelectItemData}
   */
  // tslint:disable-next-line:member-ordering
  private static _toSelectItemData(data: LooseObjectFieldData): SelectItemData {
    let obj: SelectItemData = {
      label: data.description,
      value: JSON.parse(data.value)
    };

    if (CustomUtils.isDefined(data['calculable']) && CustomUtils.isDefined(data['percent'])) {
      obj.data = data['percent'];
    } else if (CustomUtils.isDefined(data['code'])) {
      obj.data = data['code'];
    }

    return obj;
  }

  /**
   * Coerces types
   * @param value
   * @private
   */
  // tslint:disable-next-line:member-ordering
  private static _forceCoercion(value: any): any {
    let result: any = null;

    try {
      result = JSON.parse(value);
    } catch (err) {
      result = value;
    }

    return result;
  }

  /**
   * Splits field names by dots.
   * @param {FormField[]} fields
   */
  private _createFormValidator(fields: FormField[]): void {
    fields.forEach(field => {
      let properties = field.name.split('.');
      this._findFormParts(this, properties, field);
    });
  }

  /**
   * Creates an object structure.
   * @param {Object} map
   * @param {string[]} properties
   * @param {FormField} value
   */
  private _findFormParts(map: object, properties: string[], value: FormField): void {
    const length: number = properties.length;
    const property: string = properties.shift();

    if (length > 1) {
      if (!map.hasOwnProperty(property)) {
        Object.defineProperty(map, property, {value: {}, enumerable: true});
      }
      this._findFormParts(map[property], properties, value);
    } else {
      map[property] = value;

      if (CustomUtils.HOPAID(map[property], 'defaultValue')) {
        map[property].defaultValue = ReactiveFormValidator._forceCoercion(map[property].defaultValue);
      }

      if (CustomUtils.HOPAID(map[property], 'values')) {
        map[property].values = map[property].values.map((valueModel: ValuesField) => {
          valueModel.value = ReactiveFormValidator._forceCoercion(valueModel.value);
          return valueModel;
        });
      }

      if (CustomUtils.HOPAID(map[property], 'data') && map[property].data.length) {
        map[property].data = map[property].data.map(ReactiveFormValidator._toSelectItemData);
        if (!map[property].mandatory) {
          map[property].data.unshift({label: this.traducir('label-selecciona'), value: null});
        }
      }

      if (CustomUtils.HOPAID(map[property], 'helpText')) {
        map[property].helpText = {
          type: HelpTextType.HELP,
          text: value['helpText']
        };
      } else if (CustomUtils.HOPAID(map[property], 'helpUrl')) {
        map[property].helpText = {
          type: HelpTextType.HELP_URL,
          text: value['helpUrl']
        };
      }

      if (this._readonly) {
        map[property].enabled = false;
      }

      delete map[property].helpUrl;

    }
  }


}
