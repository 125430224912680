import {TramitacioEstructuralNode} from "./tramitacio-estructural-node";
import {TramitacioNodeAction, TramitacioNodeStates} from "./model";
import {Usuari} from "../../shared/models/usuari/usuari.model";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {CustomUtils} from "../../shared/utils/custom.utils";

export class TramitacioTaskNode extends TramitacioEstructuralNode {

  public taskKey: string;
  public taskType: string;
  public taskId: string;
  public taskeableId: string;
  public estat: TramitacioNodeStates;
  public route: string;
  public usuari: null;
  public lastModifiedBy: Usuari;
  public createDate: Date;
  public startDate: Date;
  public endDate: Date;
  public dueDate: Date;
  public reopenDate: Date;
  public lastModified: Date;
  public dto: string;
  public reopen: boolean;
  public readOnly: boolean;
  public tascaOmesa: boolean;

  // Custom
  public taskIcon: string;

  public retornat: boolean;
  public motiuRetorn: string;
  public taskReturnedIcon: string;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.taskKey = backendJSON.taskKey;
    this.taskType = backendJSON.taskType;
    this.taskId = backendJSON.taskId;
    this.taskeableId = backendJSON.taskeableId;
    this.estat = backendJSON.estat;
    this.route = backendJSON.route;
    this.usuari = backendJSON.usuari;
    this.lastModifiedBy = backendJSON.lastModifiedBy;
    this.createDate = backendJSON.createDate;
    this.startDate = backendJSON.startDate;
    this.endDate = backendJSON.endDate;
    this.dueDate = backendJSON.dueDate;
    this.reopenDate = backendJSON.reopenDate;
    this.lastModified = backendJSON.lastModified;
    this.dto = backendJSON.dto;
    this.reopen = backendJSON.reopen;
    this.taskIcon = 'fa fa-newspaper-o fa-search';
    this.readOnly = backendJSON.readOnly;
    this.retornat = backendJSON.retornat;
    this.motiuRetorn = backendJSON.motiuRetorn;
    this.tascaOmesa = backendJSON.tascaOmesa;

    if (this.estat) {
      this.styleClass = this.estat.toLowerCase();

      if (this.tascaOmesa && this.estat === TramitacioNodeStates.complete) {
        this.styleClass = 'complete';
      }

      this.icon = this._setStatusIcon();

      if (this.estat !== TramitacioNodeStates.inactive && this.taskId) {
        this.selectable = true;
      }

      if (this.reopen) {
        this.actions.push({key: TramitacioNodeAction.reobrir, labelButton: 'Reabrir', iconButton: 'fa fa-unlock'});
      }
      if (this.estat === TramitacioNodeStates.assigned ||
        this.estat === TramitacioNodeStates.unassigned ||
        this.estat === TramitacioNodeStates.canceled ||
        this.estat === TramitacioNodeStates.complete) {
        this.actions.push({
          key: TramitacioNodeAction.veure,
          labelButton: 'Ver tarea',
          iconButton: 'fa fa-search'
        });
      }
    }

    if (CustomUtils.isDefined(this.motiuRetorn)) {
      this.taskReturnedIcon = 'fa fa-warning';
    }
  }

  private _setStatusIcon(): string {
    let value: string = null;

    switch (this.estat) {
      case TramitacioNodeStates.complete:
        value = 'no-icon';
        if (!this.tascaOmesa) {
          value = 'fa fa-check';
        }
        break;
      case TramitacioNodeStates.assigned:
      case TramitacioNodeStates.unassigned:
        value = 'fa fa-long-arrow-right';
        break;
      case TramitacioNodeStates.canceled:
      case TramitacioNodeStates.anulat:
        value = 'fa fa-times';
        break;
      case TramitacioNodeStates.inactive:
        this.expandedIcon = '';
        this.collapsedIcon = '';
        break;
    }

    return value;
  }
}
