import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {UserPreference} from '../../models/cache/user-preference.model';
import {Observable} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {MapperUtils} from '../../utils/mapper-utils';
import {CustomUtils} from '../../utils/custom.utils';
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {map} from "rxjs/operators";

@Injectable()
export class UserPreferenceService {

  constructor(private http: HttpClient) {

  }

  private static createUserPreferenceRequest(key: string, modelVersion: string, subKey: string = null): LooseObject<string> {

    let paramsMap: LooseObject<string> = {};
    paramsMap["key"] = key;
    paramsMap["modelVersion"] = modelVersion;
    if (CustomUtils.isDefined(subKey)) {
      paramsMap["subKey"] = subKey;
    }
    return paramsMap;
  }

  getUserPreference(userId: number, key: string, modelVersion: string, subKey?: string): Observable<UserPreference> {
    let paramsMap: LooseObject<string> = UserPreferenceService.createUserPreferenceRequest(key, modelVersion, subKey);
    const requestParams: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.get<UserPreference>(`${AppConstants.urlUserPreferences}/${userId}`, {params: requestParams});
  }

  saveUserPreference(userPreference: UserPreference): Observable<UserPreference> {
    return this.http.put<UserPreference>(`${AppConstants.urlUserPreferences}/${userPreference.userId}`, userPreference,
      {headers: new HttpHeaders().set('no-load', 'false')}).pipe(map(
      (preference: UserPreference) => {
        if (MapperUtils.isValidModel(preference, new UserPreference())) {
          return preference;
        } else {
          return null;
        }
      }));
  }

  deleteUserPreference(userId: number, key: string, modelVersion: string, subKey?: string): Observable<UserPreference> {
    let paramsMap: LooseObject<string> = UserPreferenceService.createUserPreferenceRequest(key, modelVersion, subKey);
    const requestParams: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.delete<UserPreference>(`${AppConstants.urlUserPreferences}/${userId}`, {
      params: requestParams,
      headers: new HttpHeaders().set('no-load', 'false'),
    });
  }

}
