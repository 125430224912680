import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Router} from '@angular/router';
import {AppConstants} from '../../app.constants';
import {environment} from "../../../environments/environment";
import {GeecError} from "../../shared/models/geec-error.model";
import {GeecErrorService} from "../../shared/services/geec-error/geec-error.service";
import {tap} from "rxjs/operators";
import * as format from "date-fns/format";
import {coerceBooleanProperty} from "../../shared/utils/coercion/boolean-property";

@Injectable()
export class GeecAppInterceptor implements HttpInterceptor {

  constructor(private router: Router, private geecErrorService: GeecErrorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let naviReq: HttpRequest<any>;

    // Only include Navi header if the app is in Develop Environment.
    if (environment.type === 'local') {
      naviReq = req.clone({headers: req.headers.set('Navi', 'Hey, listen!')});
    } else {
      naviReq = req.clone();
    }

    const offline: boolean = coerceBooleanProperty(naviReq.headers.get("offline"));
    return next.handle(naviReq).pipe(tap(() => {
    }, (err: any) => {
      this.geecErrorService.error = <GeecError>err['error'];
      if (err instanceof HttpErrorResponse && !offline) {
        if (err.status === 401 || err.status === 0) {
          // unauthorized / cors error
          if (environment.production) {
            window.location.replace(AppConstants.loginGICAR);
          } else {
            window.location.href = "gconf/login";
          }
        } else if (err.status === 403) {
          this.router.navigateByUrl("access-denied");
        } else if ((err.status === 408) || (err.status === 504) || (err.status === 599) || (err.status === 503)) {
          this.router.navigateByUrl("no-disponible");
        } else if (err.status !== 422) {
          // unexpected error
          if (this.geecErrorService.error) {
            console.log("Error: (" + this.geecErrorService.error.timestamp + ") " + err.message);
          } else {
            console.log("Error: (" + format(new Date(), 'YYYY/MM/DD') + ") " + err.message);
          }
          this.router.navigateByUrl(AppConstants.errorPage);
        }
      }
    }));
  }
}
