import {NoticeAttachment} from "./notice-attachment";

export class Notice {
  id: number;
  title: string;
  body: string;
  startDate: Date;
  endDate: Date;
  urgent: boolean;
  offline: boolean;
  active: boolean;
  attachments: NoticeAttachment[];
  tipusNoticeId: number;
}
