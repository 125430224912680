import {TramitacioTaskNode} from "./tramitacio-task-node";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {iconMap, TramitacioNodeAction, TramitacioNodeStates} from "./model";
import {TramitacioTaskDocumentVersioNode} from "./tramitacio-task-document-versio-node";
import {Mapper} from "../../core/decorators/mapper/mapper.decorator";
import {Mapping} from "../../core/decorators/mapper/classes/mapping";
import {CustomUtils} from "../../shared/utils/custom.utils";

export class TramitacioTaskDocumentNode extends TramitacioTaskNode {

  public contentType: string;
  public documentActiuId: number;
  public isOpcional: boolean;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);

    this.contentType = backendJSON.contentType;
    this.documentActiuId = backendJSON.documentActiuId;
    this.isOpcional = backendJSON.isOpcional;
    this.taskIcon = iconMap.get(this.contentType) || 'fa fa-file-text-o';

    switch (this.estat) {
      case TramitacioNodeStates.complete:
        if (CustomUtils.isDefined(this.documentActiuId)) {
          this.actions.push({key: TramitacioNodeAction.copy, labelButton: 'Demanar còpia', iconButton: 'fa fa-book'});
        }
        break;
      case TramitacioNodeStates.assigned:
        if (this.isOpcional) {
          this.actions.push({
            key: TramitacioNodeAction.deleteOpcional,
            labelButton: 'Eliminar',
            iconButton: 'fa fa-trash'
          });
        }
      default:
        break;
    }

  }

  protected _children: Array<TramitacioTaskDocumentVersioNode>;

  get children(): Array<TramitacioTaskDocumentVersioNode> {
    return this._children;
  }

  set children(children: Array<TramitacioTaskDocumentVersioNode>) {
    this._children = children.map(this._childrenMapper).filter((child: TramitacioTaskDocumentVersioNode) => {
      return child.visible;
    });

    if (this._children.length > 0 && this.leaf) {
      this.leaf = false;
      this.expanded = true;
    }
  }

  @Mapper([
    new Mapping('dataCreacio', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dataUltimaModificacio', null, {constructor: Date, params: 'SELF'}),
    new Mapping('dataValidacio', null, {constructor: Date, params: 'SELF'})
  ])
  private _childrenMapper(child: TramitacioTaskDocumentVersioNode) {
    return new TramitacioTaskDocumentVersioNode(child);
  }

}
