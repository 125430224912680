import {ChangeDetectionStrategy, Component, ElementRef, HostListener} from '@angular/core';
import {CustomUtils} from "../../../shared/utils/custom.utils";

@Component({
  selector: 'geec-go-top-button',
  templateUrl: './go-top-button.component.html',
  styleUrls: ['./go-top-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoTopButtonComponent {

  showButton: boolean = false;

  constructor(public el: ElementRef) {
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll(): void {
    const scrollPosition: number = window.pageYOffset;
    this.showButton = scrollPosition > 500;
  }

  scrollToTop(): void {
    CustomUtils.scrollToTop();
  }

}
