import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {AcordMarc} from "../../models/expedient/acord-marc.model";
import {AppConstants} from "../../../app.constants";
import {HttpClient, HttpParams} from "@angular/common/http";
import {CustomUtils} from "../../utils/custom.utils";

@Injectable({
  providedIn: 'root'
})
export class AcordMarcService {

  constructor(private _http: HttpClient) {
  }

  public getAcordsMarcOrigen(unitatPromotora: number, idProcediment?: number, numeroExpedientParent?: string): Observable<AcordMarc[]> {
    let httpParameters: HttpParams = new HttpParams();

    if (CustomUtils.isDefined(idProcediment)) {
      httpParameters = httpParameters.set("idProcediment", idProcediment.toString());
    }

    if (CustomUtils.isDefined(numeroExpedientParent)) {
      httpParameters = httpParameters.set("numeroExpedientParent", numeroExpedientParent);
    }

    return this._http.get<AcordMarc[]>(`${AppConstants.urlBasats}/acordsMarcActius/${unitatPromotora}`, {params: httpParameters});
  }

  public getAcordsMarc(acordMarcId: number): Observable<AcordMarc[]> {
    return this._http.get<AcordMarc[]>(`${AppConstants.urlBasats}/lotsAcordMarcActius/${acordMarcId}`);
  }

}
