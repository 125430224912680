import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../../shared/services/auth/auth.service';
import {Observable} from "rxjs/internal/Observable";
import {catchError, map} from "rxjs/operators";
import {ApplicationInitService} from "../../../application-init/application-init.service";
import {of} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService,
              private initService: ApplicationInitService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.initService.canLogin) {
      return this.authService.getCurrentUsuari().pipe(map(() => true), catchError(() => of(false)));
    } else {
      this.router.navigateByUrl("no-autoritzat");
      return false;
    }
  }

}
