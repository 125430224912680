export class DadesBasiquesFacturaModel {
  id: number = null;
  idBustiaFactura: number = null;
  numero: string = null;
  dadesGeneralsExpedient: DadesExpedientFacturaFormModel;
  dadesFactura: DadesFacturaFormModel;
  facturesRegistrades: FacturesRegistradesModel;

  constructor() {
    this.dadesGeneralsExpedient = new DadesExpedientFacturaFormModel();
    this.dadesFactura = new DadesFacturaFormModel();
    this.facturesRegistrades = new FacturesRegistradesModel();
  }
}

class DadesExpedientFacturaFormModel {
  unitatPromotora: string = null;
  importLicitacio: number = null;
  indicadorImpostos: number = null;
  importLicitacioIva: number = null;
  importAdjudicacio: number = null;
  importAdjudicacioIva: number = null;
  importModificacio: number = null;
  importGarantia: number = null;
  importContracteModificat: number = null;
}

class DadesFacturaFormModel {
  titol: string = null;
  referencia: string = null;
  anyFactura: number = null;
  dataFactura: Date = null;
  registre: string = null;
  dataRegistreFactura: Date = null;
  dataLliuramentServeiInici: Date = null;
  dataLliuramentServeiFi: Date = null;
  adjudicatari: string = null;
  nif: string = null;
  creditor: string = null;
  efactura: boolean = null;
  certificacio: string = null;
  importFactura: number = null;
  importFacturaIva: number = null;
  moneda: string = null;
  retencioPreus: boolean = null;
  estudisDictamens: boolean = null;
  cessioCredit: boolean = null;
  nifCessionari: string = null;
  cessionari: string = null;
  idRegistreAdministratiu: string = null;
  dataRegistreAdministratiu: Date = null;
  horaRegistreAdministratiu: string = null;
  motiuRebuig: string = null;
  dataRebuig: Date = null;
  facturaElectronica: boolean = null;
  sistemaOrigen: string = null;
}

export class LiniaFacturaRegistrada {
  numDocumentFactura: number = null;
  exerciciFactura: string = null;
  numOrdreFactura: number = null;
  documentFactura: string = null;
  dataDocumentFactura: Date = null;
  dataComptabilitzacioDocumentFactura: Date = null;
  textPosicioFactura: string = null;
  centreGestorFactura: number = null;
  partidaPressupostariaFactura: number = null;
  fonsFactura: number = null;
  // codiPepFactura: string = null; // Pendent definir
  // codiPepModificatFactura: string = null; // Pendent definir
  importPosicioFactura: number = null;
  indicadorImpostosPosicioFactura: number = null;
  importIvaPosicioFactura: number = null;
  estatFactura: number = null;
  dataPagamentFactura: Date = null;
  pagadorAlternatiuFactura: string = null;
  nomPagadorAlternatiuFactura: string = null;
  importRetencioFactura: number = null;

  codiExpedientFactura: number = null;
  unitatPromotoraFactura: number = null;
  dataRegistreFactura: Date = null;
  dataLliuramentIniciFactura: Date = null;
  dataLliuramentFiFactura: Date = null;
  dataAcceptacioFactura: Date = null;

}

export class FacturesRegistradesModel {
  numero: string = null;
  totalImportPosicions: number = null;
  totalImportPosicionsIva: number = null;
  importLicitacioIva: number = null;
  importAdjudicacioIva: number = null;
  radPendentConsumir: number = null;
  facturesComptabilitzades: number = null;
  facturesPreliminars: number = null;
  importPagat: number = null;
  liniesFacturesRegistrades: LiniaFacturaRegistrada[] = null;
}
