import {CustomUtils} from '../../utils/custom.utils';
import {LooseObject} from "../../models/forms/reactive-form-validator";

export class AutoCompleteConfig {
  page?: number;
  size?: number;
  lang?: string;
  loading?: boolean;

  constructor(page: number, size: number, loading: boolean, lang?: string) {
    this.page = page;
    this.size = size;
    this.loading = loading;
    this.lang = lang;
  }

  static loadConfig(paramsMap: LooseObject<string> = {}, config: AutoCompleteConfig): LooseObject<string> {
    if (CustomUtils.isDefined(config)) {
      if (CustomUtils.isDefined(config.page)) {
        paramsMap["page"] = JSON.stringify(config.page);
      }
      if (CustomUtils.isDefined(config.size)) {
        paramsMap["size"] = JSON.stringify(config.size);
      }
      if (CustomUtils.isDefined(config.lang)) {
        paramsMap["lang"] = config.lang;
      }
      return paramsMap;
    }
  }
}
