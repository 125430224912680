import {Component, EventEmitter, Input, OnDestroy, Output} from "@angular/core";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {DatesContracteSharedFormValidatorModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {BehaviorSubject, Subject} from "rxjs";
import {ReactiveFormValidator} from "../../../models/forms/reactive-form-validator";
import {takeUntil} from "rxjs/operators";
import {CustomUtils} from "../../../utils/custom.utils";
import {FormUtils} from "../../../utils/form-utils";
import {FormGroup} from "@angular/forms";
import {DatesContracteService} from "./dates-contracte.service";
import {GeecLocale} from "../../../utils/calendar-utils";

@Component({
  template: ''
})
export class DatesContracteControllerComponent implements OnDestroy {

  @Input()
  public parentFormGroup: GeecFormGroup;

  @Input()
  public locale: GeecLocale;

  public templateValidators: DatesContracteSharedFormValidatorModel;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input()
  public set formValidators(subject: BehaviorSubject<ReactiveFormValidator>) {
    subject.pipe(takeUntil(this._ngUnsubscribe)).subscribe((data: ReactiveFormValidator) => {
      if (CustomUtils.isDefined(data)) {
        this.templateValidators = data['datesContracte'];
        FormUtils.updateFormValues(this.datesContracteForm, this.templateValidators);
      }
    });
  }

  @Output()
  public triggerValidation?: EventEmitter<any> = new EventEmitter();

  constructor(protected _service: DatesContracteService) {
  }

  get datesContracteForm(): FormGroup {
    return <FormGroup>this.parentFormGroup.get("datesContracte");
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  formValidatorEventEmitter(event: Event): void {
    this.triggerValidation.next(event);
  }
}
