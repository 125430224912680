import {Component, ComponentRef, CUSTOM_ELEMENTS_SCHEMA, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CustomUtils} from '../../utils/custom.utils';
import {ControllerResponse, ResponseMessage} from '../../models/forms/controller-response.model';
import {ExecuteChildMethod} from '../../services/utils/common';
import {TascaService} from '../../../core/services/tasca/tasca.service';
import {CustomRouterReuseService, NavigateBackConfig} from '../../../core/services/custom-router-reuse/custom-router-reuse.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Tasca} from '../../models/bustia/tasca.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ContentHeaderComponent} from "../content-header/content-header.component";
import {DocumentsOpcionalsComponent} from "../documents-opcionals/documents-opcionals.component";
import {Message} from 'primeng/api';
import {GeecFormDTOModel, Taskable, TaskableType, TaskUsageState} from "../../models/forms/geec-form-dto.model";
import {FormService} from "../../../core/services/form.service";
import {NotificationsService} from "../../../core/services/notifications/notifications.service";
import {RootInjectorService} from "../../../core/services/root-injector.service";
import {takeUntil} from "rxjs/operators";
import {FormUtils} from "../../utils/form-utils";
import {TramitacioNodeStates} from "../../../arbre-tramitacio/classes/model";
import {Ecofin, Expedient} from "../../models/expedient/expedient.model";
import {GeecFormController, SubmitPopupEvent} from "../../../core/classes/geec-form.controller";
import {DialogModule} from "primeng/dialog";
import {DynamicViewBuilder} from "../../../core/classes/dynamic-view-builder";
import {NgModule} from "@angular/core/src/metadata/ng_module";
import {ReopenPopup} from "./reopen-popup";
import {FormsModule} from "@angular/forms";
import {FrontDTO} from "../../models/forms/front-dto.model";
import {Procediments} from "../../models/expedient/procediments";
import {TaskKey} from "../../../app.constants";
import {EnvironmentUtils} from "../../../../environments/environment.utils";

export enum TaskOptions {
  // TaskOptions (opcions perque el camunda sapiga què ha de fer al fer un next)
  DESAR_I_NOU = "repeatDocAdj",
  DESFER_ADJUDICACIO = "descartarAdj",
  RESOLUCIO_SIMPLE = "resolucioSimple",
  MOTIU_DESFER_ADJUDICACIO = "MOTIU_DESFER_ADJUDICACIO",
  FORMALITZACIO = "formalitzacio"
}

@Component({
  template: ''
})
export class TascaComponent implements OnInit, OnDestroy {

  // view child is needed to create documents opcionals after form save
  @ViewChild("docOpcionals") documentsOpcionalsComponent: DocumentsOpcionalsComponent;

  @ViewChild("header") formHeader: ContentHeaderComponent;

  hasRoutesStored: boolean;

  tasca: Tasca;

  taskable: Taskable;

  expedient: Expedient;

  expedientId: number;

  formName: string;

  errorMsgs: Message[] = [];

  readonly: boolean = false;

  taskKey: string;

  taskKeyTypes: typeof TaskKey = TaskKey;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected ignoreBeforeUnload: boolean = false;

  private _formService: FormService;

  public _notificationService: NotificationsService;

  private _dialog: ComponentRef<ReopenPopup>;

  protected _controller: GeecFormController<any>;

  get isMenor(): boolean {
    let procedimentId: number = Procediments.CONTRACTE_MENOR_ID;
    if (this.tasca && this.tasca.procedimentId) {
      procedimentId = this.tasca.procedimentId;
    } else if (this.expedient && this.expedient.procedimentId) {
      procedimentId = this.expedient.procedimentId;
    }
    return procedimentId === Procediments.CONTRACTE_MENOR_ID;
  }


  get taskeableId(): number {
    let taskeableId: number;
    if (this.tasca && this.tasca.taskeableId) {
      taskeableId = this.tasca.taskeableId;
    } else if (this.taskable && this.taskable.id) {
      taskeableId = this.taskable.id;
    }
    return taskeableId;
  }

  constructor(protected router: Router, protected tascaService: TascaService,
              protected routerService: CustomRouterReuseService, protected route: ActivatedRoute,
              protected _viewContainerRef: ViewContainerRef) {

    this.setPrivateDependency();

    if (CustomUtils.HOPAID(this.route.snapshot.data, ['formData'])) {
      const resolve: ControllerResponse<GeecFormDTOModel> = this.route.snapshot.data['formData'];

      if (CustomUtils.HOPAID(resolve, ['data', 'taskeable'])) {
        this.taskable = resolve.data.taskeable;
      }

      if (CustomUtils.HOPAID(resolve, ['data', 'expedientId'])) {
        this.expedientId = resolve.data.expedientId;
      } else if (CustomUtils.HOPAID(resolve, ['data', 'expedient', 'id'])) {
        this.expedientId = resolve.data.expedient['id'];
      }

      if (CustomUtils.HOPAID(resolve, ['data', 'expedient'])) {
        this.expedient = resolve.data.expedient;
        if (!CustomUtils.HOPAID(this.expedient, 'integracioOrigenId')) {
          this.expedient.integracioOrigenId = Ecofin.GECAT_ID;
        }
      }

      if (CustomUtils.HOPAID(resolve, ['data', 'expedientTaskCacheResponse'])) {
        if ((resolve.data.expedientTaskCacheResponse.responseCode !== TaskUsageState.OK) &&
          (resolve.data.expedientTaskCacheResponse.responseCode !== TaskUsageState.OTHER_SESSION_UNLOCK)) {
          this.readonly = true;
        }
        if (CustomUtils.isArrayNotEmpty(resolve.messages)) {
          resolve.messages.forEach((message: ResponseMessage) => {
            this._notificationService.notify(message.severity, message.summary, message.detail);
          });
        }
      }
    }
  }

  private _buildReopenedByAdminPopup() {
    const clazz = ReopenPopup;
    const template: string = `
        <p-dialog #dialog [header]="Confirmacio"
                          [draggable]="false" [resizable]="false"
                          [responsive]="true" [(visible)]="visible"
                          [modal]="true" [contentStyle]="{'width': '40rem'}" appendTo="body">
            <p>
              Este formulario ha sido reabierto por un administrador de Órgano y se han modificado los datos
            </p><br/>
            <div class="field">
              <label class="label" for="motiu">Indique el motivo:</label>
              <textarea id="motiu" title="Motiu baixa" class="textarea" pInputTextarea [rows]="5"
              [(ngModel)]="motiu"></textarea>
            </div>
            <p-footer>
                <button class="button is-canigo" (click)="submit()" [disabled]="!isMotiuValid()">
                    <span>Continuar</span>
                </button>
            </p-footer>
        </p-dialog>
    `;

    return {template: template, clazz: clazz};
  }

  private _injectReopenedByAdminPopup() {
    this._controller.onReopenedByAdmin$.subscribe((value: boolean) => {
      if (value && !this._dialog) {
        const builder: DynamicViewBuilder = new DynamicViewBuilder(this._viewContainerRef);
        const {template, clazz} = this._buildReopenedByAdminPopup();
        const componentMetadata: Component = {template: template};
        const moduleMetadata: NgModule = {imports: [FormsModule, DialogModule], schemas: [CUSTOM_ELEMENTS_SCHEMA]};

        this._dialog = builder.generate<ReopenPopup>(componentMetadata, clazz, moduleMetadata);

        this._controller.onSubmitReopenedByAdmin$.subscribe((_value: SubmitPopupEvent) => {
          this._dialog.instance.visible = _value.visible;
          this._dialog.instance.eventSubmit.action = _value.action;
          this._dialog.instance.eventSubmit.body = _value.body;
          this._dialog.instance.eventSubmit.cb = _value.cb;
        });

        this._dialog.instance.onSubmit$.subscribe((_value: SubmitPopupEvent) => {
          let body: FrontDTO = null;

          if (!_value.body) {
            body = this._controller.prepareFrontDTO();
          } else {
            body = this._controller.prepareFrontDTO(null, _value.body);
          }

          body.reopenMotiu = _value.motiu;

          this._controller.validateAndSubmit(null, _value.action, body, false, _value.cb);

          this._dialog.instance.visible = false;
        });
      }
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate(event?: BeforeUnloadEvent): boolean {
    if (!this.ignoreBeforeUnload) {
      if (!this.readonly && this.tasca) {
        this._formService.formUnlock(this.tasca.expedientId, this.tasca.id).subscribe();
      }

      CustomUtils.sleep(750);
    } else {
      this.ignoreBeforeUnload = false;
    }

    return true;
  }

  ngOnInit() {
    this.tasca = this.tascaService.getSelectedTasca();
    if (!this.tasca || this.tasca.readOnly || (this.tasca && (this.tasca.taskStatus === TramitacioNodeStates.complete || this.tasca.taskStatus === TramitacioNodeStates.canceled))) {
      this.readonly = true;
    }
    this.routerService.routesStored.pipe(takeUntil(this.ngUnsubscribe)).subscribe(hasRoutesStored => {
      this.hasRoutesStored = hasRoutesStored;
    });
    if (this.tasca) {
      this.taskKey = this.tasca.keyTask;
    }

    this._injectReopenedByAdminPopup();

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  resetErrorMessages(): void {
    this.errorMsgs.length = 0;
  }

  handleError(errors: ResponseMessage[] | HttpErrorResponse): void {
    if (Array.isArray(errors)) {
      this.errorMsgs = errors;
    } else {
      this.errorMsgs = FormUtils.handleError(errors);
    }
  }

  executeChildMethod(componentMethod: ExecuteChildMethod): void {
    if (this[componentMethod.component] && this[componentMethod.component][componentMethod.method] &&
      CustomUtils.isFunction(this[componentMethod.component][componentMethod.method])) {
      this[componentMethod.component][componentMethod.method]();
    }
  }

  goStoredRoute(): void {
    let navigateBackConfig: NavigateBackConfig = this.routerService.goBack();
    if (this.hasToBlockTasca(navigateBackConfig)) {
      this.tascaService.selectTasca(navigateBackConfig.tasca);
      this._formService.formLock(navigateBackConfig.tasca.dto, navigateBackConfig.tasca.expedientId,
        navigateBackConfig.tasca.id).subscribe((response: ControllerResponse<void>) => {
        if (CustomUtils.isArrayNotEmpty(response.messages)) {
          this._notificationService.notify(response.messages[0].severity, response.messages[0].summary, response.messages[0].detail);
          this.tascaService.tornarBustia(this.router);
        } else {
          this.router.navigate(navigateBackConfig.route);
        }
      }, (err: HttpErrorResponse) => {
        this.errorMsgs = FormUtils.handleError(err);
      });
    } else {
      this.router.navigate(navigateBackConfig.route);
    }
  }

  goToDadesBasiques(force: boolean = false): void {
    this.tascaService.selectTasca(null);
    this.tascaService.setTaskable(this.taskable);

    let commands: any[];
    const taskeableId: number = this.taskeableId;

    if (!force) {
      switch (this.taskable.type) {
        case TaskableType.EXPEDIENT:
          commands = this.getT1Commands(this.tasca, taskeableId);
          break;
        case TaskableType.SBEM:
          commands = this.routerService.prepareToNavigate(['dades-basiques-sbem', taskeableId], this.tasca);
          break;
        case TaskableType.RAJU:
          commands = this.routerService.prepareToNavigate(['dades-basiques-raju', taskeableId], this.tasca);
          break;
        case TaskableType.FACTURA:
          commands = this.routerService.prepareToNavigate(['dades-basiques-factura', taskeableId], this.tasca);
          break;
        case TaskableType.MODIFICACIO:
          commands = this.routerService.prepareToNavigate(['dades-basiques-modificacio', taskeableId], this.tasca);
          break;
        case TaskableType.VERI:
          commands = this.routerService.prepareToNavigate(['dades-basiques-veri', taskeableId], this.tasca);
          break;
        case TaskableType.ECOM:
          commands = this.routerService.prepareToNavigate(['dades-basiques-ecom', taskeableId], this.tasca);
          break;
        case TaskableType.SBOC:
          commands = this.routerService.prepareToNavigate(['dades-basiques-sboc', taskeableId], this.tasca);
          break;
      }
    } else {
      commands = this.getT1Commands(this.tasca, taskeableId);
    }

    this.router.navigate(commands);
  }

  goToDadesBasiquesActuacioContractual(): void {
    let commands: any[];
    if (this.isMenor) {
      commands = this.routerService.prepareToNavigate(['dades-basiques', this.expedientId], this.tasca);
    } else {
      let url: string = 'dades-basiques-nm';
      if (this.expedient.isProrroga) {
        url = 'dades-basiques-prorroga';
      }
      commands = this.routerService.prepareToNavigate([url, this.expedientId], this.tasca);
    }

    this.tascaService.selectTasca(null);
    this.tascaService.setTaskable(this.taskable);
    this.router.navigate(commands);
  }

  goToDadesBasiquesExpedientOrigen(): void {
    this.tascaService.selectTasca(null);
    this.tascaService.setTaskable(this.taskable);

    let idOrigen: number = this.expedient.lotOrigenId;
    if (CustomUtils.isUndefinedOrNull(idOrigen)) {
      idOrigen = this.expedient.id;
    }
    this.router.navigate(this.routerService.prepareToNavigate(['dades-basiques-nm', idOrigen], this.tasca));
  }

  goBack(): void {
    if ((EnvironmentUtils.isPRE || EnvironmentUtils.isPRO || EnvironmentUtils.isInt) && CustomUtils.isDefined(this.taskKey)) {

    }
    if (this.hasRoutesStored) {
      this.goStoredRoute();
    } else {
      this.tascaService.tornarBustia(this.router);
    }
  }

  private getT1Commands(tasca: Tasca, taskeableId: number): any[] {
    let commands: any[] = null;

    if (this.isMenor) {
      commands = this.routerService.prepareToNavigate(['dades-basiques', taskeableId], tasca);
    } else {
      let url: string = 'dades-basiques-nm';
      if (this.expedient.isProrroga) {
        url = 'dades-basiques-prorroga';
      }
      commands = this.routerService.prepareToNavigate([url, taskeableId], tasca);
    }

    return commands;
  }

  private setPrivateDependency() {
    this._formService = RootInjectorService.injector.get<FormService>(FormService);
    this._notificationService = RootInjectorService.injector.get<NotificationsService>(NotificationsService);
  }

  private hasToBlockTasca(navigateBackConfig: NavigateBackConfig): boolean {
    return (navigateBackConfig.tasca && navigateBackConfig.tasca.taskStatus !== TramitacioNodeStates.complete &&
      (<string>navigateBackConfig.route[0]).indexOf(navigateBackConfig.tasca.descripcio) === 1);
  }
}
