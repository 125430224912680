import {TramitacioEstructuralNode} from "./tramitacio-estructural-node";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioNodeAction} from "./model";

export class TramitacioFacturaNode extends TramitacioEstructuralNode {

  public canBeReturned: boolean;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.icon = 'fa fa-eur';
    this.canBeReturned = backendJSON.canBeReturned;
    if (this.canBeReturned) {
      this.selectable = true;
      this.actions.push({key: TramitacioNodeAction.retornar, labelButton: 'Retornar', iconButton: 'fa fa-undo'});
    }
  }

}
