import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {NgModuleRef} from "@angular/core/src/linker/ng_module_factory";
import {RootInjectorService} from "./app/core/services/root-injector.service";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then((app: NgModuleRef<AppModule>) => {
  RootInjectorService.injector = app.injector;
});
