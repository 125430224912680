import {Component} from '@angular/core';

@Component({
  templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent {

  constructor() {
  }

}
