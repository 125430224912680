import {OperationHandler} from "../../abstract/operation-handler";
import {Mapping} from "../../mapping";

export class ConversionHandler extends OperationHandler {
  constructor(mapping: Mapping) {
    super(mapping);
  }

  operate(target: object): PropertyDescriptor {
    // TODO: Implement me!!
    // TODO: Delete here the incorrect property.
    return null;
  }

}
