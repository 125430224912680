import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {ControllerResponse} from "../../models/forms/controller-response.model";
import { DocumentacioFormModel } from '../../../documentacio/models/documentacio-form.model';

@Injectable()
export class CopiaAutenticaService {

  constructor(private http: HttpClient) {
  }

  getCopiaAutentica(idDoc: number): Observable<ControllerResponse<DocumentacioFormModel>> {
    return this.http.post<ControllerResponse<DocumentacioFormModel>>(`${AppConstants.urlDocuments}/${idDoc}/demanarCopiaAutentica`, null);
  }
}
