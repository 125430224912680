import {DadaMestra} from "../../services/dada-mestra/dada-mestra.service";

export class DadaMestraCache extends DadaMestra {
  isCache: boolean = false;

  constructor(dadaMestra: DadaMestra, isCache: boolean = false) {
    super();
    this.id = dadaMestra.id;
    this.nom = dadaMestra.nom;
    this.isCache = isCache;
  }
}
