export class Globals {
  public static maxFileSize: number = 50000000;
  public static maxFileSizeSTDfiles: number = 10000000;
  public static maxFileSizeSTDfilesMB: number = 10;
  public static maxLogoSize: number = 1000000;
  public static invalidFileSizeMessageSummary: string = "{0}: Medida de fichero inválida, ";
  public static invalidFileSizeMessageDetail: string = "la medida máxima permitida es {0}.";
  public static invalidFileTypeMessageSummary: string = "{0}: Tipo de fichero inválido, ";
  public static invalidFileTypeMessageDetail: string = "tipos permitidos: {0}.";
}

export enum EnvironmentType {
  DES = 'des',
  LOCAL = 'local',
  MASTER = 'master',
  INT = 'int',
  PRE = 'pre',
  PRO = 'pro',
  STABLE = 'stable'
}
