import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {ReplaySubject, Observable} from 'rxjs';

@Injectable()
export class LoadingIndicatorService {

  private _loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  /** real value of loading */
  private loading: boolean = false;

  /** internal value of loading */
  private _loading: boolean = false;

  // onLoadingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Stores all currently active requests */
  private requests: HttpRequest<any>[] = [];

  /** Stores all route paths for currently loading lazy modules */
  private lazyLoadModuleRequests: string[] = [];

  /** miliseconds to wait after all requests finish before hiding loading indicator **/
  private interval: number = 300;

  private timer: any;

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  /**
   * Adds request to the storage and notifies observers
   */
  onStarted(req: HttpRequest<any>): void {
    this.requests.push(req);
    this.notify();
  }

  /**
   * Adds request to the storage and notifies observers
   */
  onStartedLazyLoad(req: string): void {
    this.lazyLoadModuleRequests.push(req);
    this.notify();
  }

  /**
   * Removes request from the storage and notifies observers
   */
  onFinished(req: HttpRequest<any>): void {
    const index = this.requests.indexOf(req);
    if (index !== -1) {
      this.requests.splice(index, 1);
    }
    this.notify();
  }

  /**
   * Removes request from the storage and notifies observers
   */
  onFinishedLazyLoad(req: string): void {
    const index = this.lazyLoadModuleRequests.indexOf(req);
    if (index !== -1) {
      this.lazyLoadModuleRequests.splice(index, 1);
    }
    this.notify();
  }

  doLocalRequest(requestName: string, cb: Function): void {
    this.onStartedLazyLoad(requestName);
    cb();
    this.onFinishedLazyLoad(requestName);
  }

  /**
   * Notifies observers about whether there are any requests on fly
   */
  private notify(): void {
    const loadingStatus = (this.requests.length !== 0) || (this.lazyLoadModuleRequests.length !== 0);
    if (loadingStatus && !this.loading) {
      // a new request came in and loader indicator is not showing, so we show it
      this.clearTimer();
      this._loading = loadingStatus;
      this.updateLoadingStatus();
    } else if (!loadingStatus && this.loading) {
      // there are no more requests and loader indicator is showing, prepare buffer time
      this._loading = loadingStatus;
      this.clearTimer();
      this.timer = setTimeout(() => {
        this.updateLoadingStatus();
      }, this.interval);
    } else if (loadingStatus && !this._loading) {
      // a new request came in before the buffer time expired, so we cancel the timer
      this._loading = loadingStatus;
      this.clearTimer();
    }
  }

  private updateLoadingStatus() {
    this.loading = this._loading;
    this._loading$.next(this.loading);
  }

  private clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

}
