import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AppConstants} from '../../../app.constants';
import {Observable} from 'rxjs';
import {CustomUtils} from '../../utils/custom.utils';
import {AutoCompleteConfig} from '../utils/auto-complete-config';
import {Poblacio} from '../../models/licitadors/poblacio.model';
import {LooseObject} from "../../models/forms/reactive-form-validator";

@Injectable()
export class PoblacioService {

  constructor(private http: HttpClient) {
  }

  getPoblacionsWithFilter(searchText: string, config: AutoCompleteConfig): Observable<Poblacio[]> {
    let paramsMap: LooseObject<string> = {};
    if (searchText) {
      paramsMap["like"] = searchText;
    }
    if (config) {
      paramsMap = AutoCompleteConfig.loadConfig(paramsMap, config);
    }
    let params: HttpParams = new HttpParams({fromObject: paramsMap});
    if (config && !CustomUtils.isUndefinedOrNull(config.loading) && !config.loading) {
      return this.http.get<Poblacio[]>(AppConstants.urlGetPoblacions, {
        headers: new HttpHeaders().set('no-load', 'false'),
        params: params,
      });
    } else {
      return this.http.get<Poblacio[]>(AppConstants.urlGetPoblacions, {
        params: params
      });
    }
  }

}
