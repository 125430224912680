import {Observable} from "rxjs";
import {AppConstants} from "../../../app.constants";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ControllerResponse} from "../../models/forms/controller-response.model";
import {CreateDocumentOpcionalFrontDTO} from "../../../documentacio/documentacio.service";

@Injectable()
export class DocumentService {

  constructor(private _http: HttpClient) {
  }

  deleteOpcional(idReferencia: number): Observable<void> {
    return this._http.delete<void>(`${AppConstants.urlDocuments}/deleteOpcional/${idReferencia}`);
  }

  createTascaDocumentOpcional(idTipusDocument: number, idTask: string): Observable<ControllerResponse<CreateDocumentOpcionalFrontDTO>> {
    return this._http.post<ControllerResponse<CreateDocumentOpcionalFrontDTO>>(`${AppConstants.urlDocuments}/createOpcional/${idTask}`, <CreateDocumentOpcionalFrontDTO>{tipusDocumentId: idTipusDocument});
  }

  createDocumentOpcionalExpedientSap(idExpedient: number): Observable<ControllerResponse<null>> {
    return this._http.post<ControllerResponse<null>>(`${AppConstants.urlDocuments}/createOpcionalExpedientSap/${idExpedient}`, null);
  }
}
