import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChildren,
  ElementRef,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  ChangeDetectionStrategy
} from '@angular/core';
import {GeecTemplateDirective} from '../../directives/geec-template.directive';
import {ComponentFactory} from "@angular/core/src/linker/component_factory";
import {HelpButtonComponent} from "../help-button/help-button.component";

@Component({
  selector: 'geec-content-header',
  templateUrl: './content-header.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ContentHeaderComponent implements AfterContentInit, AfterViewInit, OnDestroy {

  _title: string;
  get title(): string {
    return this._title;
  }
  @Input() set title(value: string) {
    this._title = value;
  }

  @Input() styleClass: string;

  @ContentChildren(GeecTemplateDirective) templates: QueryList<any>;

  private readonly _helpButton: ComponentRef<HelpButtonComponent>;

  view: EmbeddedViewRef<any>;

  leftTemplate: TemplateRef<any>;

  rightTemplate: TemplateRef<any>;

  ngOnInit() {}
  get helpButton(): HelpButtonComponent {
    return this._helpButton.instance;
  }

  constructor(private _viewContainerRef: ViewContainerRef,
              private _componentFactoryResolver: ComponentFactoryResolver,
              private _renderer: Renderer2,
              private _elementRef: ElementRef) {
    const factory: ComponentFactory<HelpButtonComponent> = this._componentFactoryResolver.resolveComponentFactory(HelpButtonComponent);
    this._helpButton = this._viewContainerRef.createComponent(factory);
  }

  ngAfterViewInit() {
    const rootRef: HTMLElement = this._elementRef.nativeElement;
    this._renderer.appendChild(rootRef.querySelector("#titol-tasca"), this._helpButton.location.nativeElement);
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'left-content':
          this.leftTemplate = item.template;
          break;

        case 'right-content':
          this.rightTemplate = item.template;
          break;

        default:
          this.rightTemplate = item.template;
          break;
      }
    });
  }

  ngOnDestroy() {
    this.view = null;
  }

}
