import {AppConstants} from '../../app.constants';
import {EntityModel} from '../models/entity.model';
import {CustomUtils} from './custom.utils';
import {CacheItem} from '../models/cache/cache-item.model';
import {CacheItemList} from '../models/cache/cache-item-list.model';

export interface CacheItemKey {
  key: string;
  userId?: number;
  subKey?: string;
  modelVersion?: string;
}

export class BrowserStorageUtils {

  /**
   * Retrieves a {@link CacheItem} from browser storage only if it exists and has a valid model.
   * @param {string} key
   * @param {string} modelVersion
   * @param {boolean} transient
   * @returns {CacheItem}
   */
  public static getCacheItem(key: CacheItemKey, transient: boolean = true): CacheItem {
    if (CustomUtils.isDefined(key) && CustomUtils.isDefined(key.modelVersion)) {
      const cacheItem: CacheItem = BrowserStorageUtils.retrieveItem(key, key.modelVersion, transient);
      if (CacheItem.isValid(cacheItem)) {
        return cacheItem;
      } else {
        BrowserStorageUtils.removeItem(key, transient);
        return null;
      }
    } else {
      throw new Error(`missing parameters in getCacheItem.`);
    }
  }

  /**
   * Retrieves a {@link CacheItemList} from browser storage only if it exists and has a valid model (array must not be empty).
   * @param {string} key
   * @param {string} modelVersion
   * @param {boolean} transient
   * @returns {CacheItemList}
   */
  public static getCacheItemList(key: CacheItemKey, modelVersion: string, transient: boolean = true): CacheItemList {
    if (CustomUtils.isDefined(key) && CustomUtils.isDefined(modelVersion)) {
      const cacheItemList: CacheItem = BrowserStorageUtils.retrieveItem(key, modelVersion, transient);
      if (CacheItemList.isValid(cacheItemList)) {
        return <CacheItemList>cacheItemList;
      } else {
        BrowserStorageUtils.removeItem(key, transient);
        return null;
      }
    } else {
      throw new Error(`missing parameters in getCacheItemList`);
    }
  }

  /**
   * Adds entity to the {@link CacheItemList}. If it does not exist a new CacheItemList is created.
   * @param {string} key
   * @param {string} modelVersion
   * @param {EntityModel} entity
   * @param {boolean} transient
   */
  public static addToCacheList(key: CacheItemKey, modelVersion: string, entity: EntityModel, transient: boolean = true): void {
    if (CustomUtils.isDefined(entity) && CustomUtils.isDefined(key) && CustomUtils.isDefined(modelVersion)) {
      let cacheItemList: CacheItemList = BrowserStorageUtils.getCacheItemList(key, modelVersion, transient);
      if (CustomUtils.isDefined(cacheItemList)) {
        // cacheItemList is a valid CacheItemList and content is a not-empty array
        let entities: EntityModel[] = cacheItemList.content;
        const index: number = entities.findIndex((item: EntityModel) => item.id === entity.id);
        if (index === -1) {
          // take the first (X - 1) cached objects and put the new one
          BrowserStorageUtils.setCacheItemList(key, modelVersion, [entity, ...entities.slice(0, AppConstants.maximumCachedObjects)], transient);
        } else {
          // "move" the element back to first place
          entities.splice(index, 1);
          BrowserStorageUtils.setCacheItemList(key, modelVersion, [entity, ...entities], transient);
        }
      } else {
        BrowserStorageUtils.setCacheItemList(key, modelVersion, [entity], transient);
      }
    } else {
      throw new Error(`missing parameters in addToCacheList`);
    }
  }

  /**
   * Creates a new {@link CacheItemList} and stores it in browser storage.
   * @param {string} key
   * @param {string} modelVersion
   * @param content
   * @param {boolean} transient
   */
  public static setCacheItemList(key: CacheItemKey, modelVersion: string, content: EntityModel[], transient: boolean = true): void {
    BrowserStorageUtils.setCacheItem(key, modelVersion, content, transient);
  }

  /**
   * Creates a new {@link CacheItem} and stores it in browser storage.
   * @param {string} key
   * @param {string} modelVersion
   * @param content
   * @param {boolean} transient
   * @returns {CacheItem}
   */

  public static setCacheItem(key: CacheItemKey, modelVersion: string, content: any, transient: boolean = true): CacheItem {
    let cacheItem: CacheItem = BrowserStorageUtils.createCacheItem(modelVersion, content);
    BrowserStorageUtils.storeItem(key, cacheItem, transient);
    return cacheItem;
  }

  /**
   * Creates a new {@link CacheItem} instance.
   * @param {string} modelVersion
   * @param content
   * @returns {CacheItem}
   */
  public static createCacheItem(modelVersion: string, content: any): CacheItem {
    let cacheItem: CacheItem = new CacheItem();
    cacheItem.modelVersion = modelVersion;
    cacheItem.content = content;
    return cacheItem;
  }

  /**
   * Removes an item from browser storage.
   * @param {CacheItemKey} cacheItemKey
   * @param {boolean} transient
   */
  public static removeItem(cacheItemKey: CacheItemKey, transient: boolean = true): void {
    let key: string = BrowserStorageUtils.stringifyCacheItemKey(cacheItemKey);
    if (transient) {
      window.sessionStorage.removeItem(key);
    } else {
      window.localStorage.removeItem(key);
    }
  }

  /**
   * Stores a {@link CacheItem} instance in browser storage.
   * @param {CacheItemKey} cacheItemKey
   * @param {CacheItem} item
   * @param {boolean} transient
   */
  private static storeItem(cacheItemKey: CacheItemKey, item: CacheItem, transient: boolean = true): void {
    let key: string = BrowserStorageUtils.stringifyCacheItemKey(cacheItemKey);
    if (transient) {
      window.sessionStorage.setItem(key, JSON.stringify(item));
    } else {
      window.localStorage.setItem(key, JSON.stringify(item));
    }
  }

  /**
   * Retrieves a {@link CacheItem} from browser storage. If the item exists but has a different model version it is removed from storage.
   * @param {CacheItemKey} cacheItemKey
   * @param {string} modelVersion
   * @param {boolean} transient
   * @returns {CacheItem}
   */
  private static retrieveItem(cacheItemKey: CacheItemKey, modelVersion: string, transient: boolean = true): CacheItem {
    let item: CacheItem;
    let key: string = BrowserStorageUtils.stringifyCacheItemKey(cacheItemKey);
    if (transient) {
      item = JSON.parse(window.sessionStorage.getItem(key));
    } else {
      item = JSON.parse(window.localStorage.getItem(key));
    }
    if (CustomUtils.isDefined(item) && CustomUtils.isDefined(item.modelVersion) && (item.modelVersion === modelVersion)) {
      return item;
    } else {
      BrowserStorageUtils.removeItem(cacheItemKey, transient);
      return null;
    }
  }

  /**
   * Returns a string containing the stringified cache item key.
   * @param {CacheItemKey} cacheItemKey
   * @returns {string}
   */
  private static stringifyCacheItemKey(cacheItemKey: CacheItemKey): string {
    let resultKey: string = '';
    if (CustomUtils.isDefined(cacheItemKey.userId)) {
      resultKey = `${resultKey}:${cacheItemKey.userId}:${cacheItemKey.key}`;
    } else {
      resultKey = `${cacheItemKey.key}`;
    }
    if (cacheItemKey.subKey) {
      resultKey = `${resultKey}:${cacheItemKey.subKey}`;
    }
    return resultKey;
  }

}
