export abstract class Procediments {
  static readonly CONTRACTE_OBERT_ID: number = 401;
  static readonly RESTRINGIT_ID: number = 402;
  static readonly CONTRACTE_MENOR_ID: number = 403;
  static readonly PROCEDIMENT_NEGOCIAT: number = 404;
  static readonly PROCEDIMENT_DIALOGO_COMPETITIVO: number = 405;
  static readonly CONTRACTE_OBERT_SIMPLIFICAT: number = 419;
  static readonly CONTRACTE_OBERT_SIMPLIFICAT_ABREUJAT: number = 420;
  static readonly PROCEDIMENT_NEGOCIAT_SENSE_PUBLICITAT: number = 421;
  static readonly BASAT_CONCRECIO_CONDICIONS = 11197;
  static readonly BASAT_ENCARREC_DIRECTE = 11198;
  static readonly BASAT_NOVA_LICITACIO = 11199;
  static readonly ADJUDICACIO_DIRECTA = 11084;
  static readonly TRAMITACIO_EMERGENCIA = 11421;
  static readonly SDA_ESPECIFIC_CONCRECIO_CONDICIONS: number = 11671;
  static readonly SDA_ESPECIFIC_NOVA_LICITACIO: number = 11672;
  static readonly PROCEDIMIENTOS_ASOCIACION_INNOVACION: number = 11697;
  static readonly CONCURSOS_PROYECTOS: number = 11698;
  static readonly CONTRACTE_MENOR_PUBLICIDAD_ID: number = 29403;
  static readonly MEDIOS_PROPIOS: number = 40582;

  static readonly MENORS: Procediments[] = [
    Procediments.CONTRACTE_MENOR_ID,
    Procediments.CONTRACTE_MENOR_PUBLICIDAD_ID
  ];

  static readonly BASATS: Procediments[] = [
    Procediments.BASAT_CONCRECIO_CONDICIONS,
    Procediments.BASAT_ENCARREC_DIRECTE,
    Procediments.BASAT_NOVA_LICITACIO
  ];

  static readonly SDA_ESPECIFICS: Procediments[] = [
    Procediments.SDA_ESPECIFIC_CONCRECIO_CONDICIONS,
    Procediments.SDA_ESPECIFIC_NOVA_LICITACIO
  ];

  static readonly NEGOCIATS: Procediments[] = [
    Procediments.PROCEDIMENT_NEGOCIAT,
    Procediments.PROCEDIMENT_NEGOCIAT_SENSE_PUBLICITAT
  ];

  static isBasatCCorED(procediment: number): boolean {
    return (procediment === Procediments.BASAT_CONCRECIO_CONDICIONS) || (procediment === Procediments.BASAT_ENCARREC_DIRECTE);
  }
}
