import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subscription, merge} from 'rxjs';

@Component({
  selector: 'geec-panel-errors-icon',
  templateUrl: './panel-errors-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelErrorsIconComponent implements OnDestroy {

  @Input() display: boolean = false;

  @Input() get control(): AbstractControl {
    return this._control;
  }

  set control(control: AbstractControl) {
    this._unsubscribeIfNeeded();
    this._control = control;
    this._subscribeIfNeeded();
  }

  private _control: AbstractControl;

  private statusSubscription: Subscription;

  constructor(private ref: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this._unsubscribeIfNeeded();
  }

  private _updateDisplay(): void {
    const display: boolean = this.display;
    this.display = this.control.dirty && this.control.invalid;
    if (this.display !== display) {
      // manually trigger change detection since value has changed
      this.ref.detectChanges();
    }
  }

  private _subscribeIfNeeded(): void {
    if (this.control) {
      this.statusSubscription = merge(this.control.valueChanges, this.control.statusChanges).subscribe(() => this._updateDisplay());
    }
  }

  private _unsubscribeIfNeeded(): void {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

}
