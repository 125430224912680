import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {CustomUtils} from '../../utils/custom.utils';
import {LooseObject} from "../../models/forms/reactive-form-validator";

export interface UsuariValidadorConfig {
  page?: number;
  size?: number;
  lang?: string;
  loading?: boolean;
}

function loadConfig(paramsMap: LooseObject<string>, config: UsuariValidadorConfig): LooseObject<string> {
  if (config.page) {
    paramsMap["page"] = JSON.stringify(config.page);
  }
  if (config.size) {
    paramsMap["size"] = JSON.stringify(config.size);
  }
  if (config.lang) {
    paramsMap["lang"] = config.lang;
  }
  return paramsMap;
}

@Injectable()
export class UsuariValidadorService {

  constructor(private http: HttpClient) {
  }

  getOptionsWithFilter(searchText: string, config: UsuariValidadorConfig): Observable<any[]> {
    let paramsMap: LooseObject<string> = {};
    if (searchText) {
      paramsMap["like"] = searchText;
    }
    if (config) {
      paramsMap = loadConfig(paramsMap, config);
    }
    const httpParams: HttpParams = new HttpParams({fromObject: paramsMap});
    if (config && !CustomUtils.isUndefinedOrNull(config.loading) && !config.loading) {
      return this.http.get<any[]>(AppConstants.urlGetUsuarisValidadors, {
        headers: new HttpHeaders().set('no-load', 'false'),
        params: httpParams,
      });
    } else {
      return this.http.get<any[]>(AppConstants.urlGetUsuarisValidadors, {
        params: httpParams
      });
    }
  }

}
