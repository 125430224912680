import {TreeNode} from "primeng/api";
import {ButtonSettings} from "./model";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {CustomUtils} from "../../shared/utils/custom.utils";

export class TramitacioNode implements TreeNode {

  /*
    Implements TreeNode
   */
  label?: string;
  data?: any;
  icon?: any;
  expandedIcon?: any = 'fa fa-folder-open';
  collapsedIcon?: any = 'fa fa-folder';
  leaf?: boolean = true;
  expanded?: boolean = false;
  type?: string;
  parent?: TreeNode;
  partialSelected?: boolean;
  styleClass?: string;
  draggable?: boolean = false;
  droppable?: boolean = false;
  selectable?: boolean = false;

  /*
    Own properties
   */
  public id: number;
  public actions: Array<ButtonSettings> = [];
  public hasChildren: boolean;
  public isActiveNode: boolean;

  constructor(backendJSON: LooseObject<any>) {

    if (backendJSON.id) {
      this.id = backendJSON.id;
    }

    if (backendJSON.nom) {
      this.nom = backendJSON.nom;
    }

    this.hasChildren = backendJSON.hasChildren;

    if (this.hasChildren && !this.children) {
      this.children = [];
    }

    this.isActiveNode = backendJSON.isActiveNode;
  }

  protected _children?: Array<TramitacioNode>;

  get children(): Array<TramitacioNode> {
    return this._children;
  }

  set children(children: Array<TramitacioNode>) {
    this._children = children;
    if (this.leaf) {
      this.leaf = false;
      this.expanded = true;
    }
    if (this.hasChildren && !CustomUtils.isArrayNotEmpty(this._children)) {
      this.expanded = false;
    }
  }

  private _nom: string;

  get nom(): string {
    return this._nom;
  }

  set nom(value: string) {
    this._nom = value;
    this.label = this._nom;
  }

}
