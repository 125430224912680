import {Pipe, PipeTransform} from '@angular/core';
import {CustomUtils} from "../../utils/custom.utils";

@Pipe({
  name: 'isNotEmpty',
  pure: true
})
export class IsNotEmptyPipe implements PipeTransform {

  transform(value: any): any {
    return CustomUtils.isNotEmpty(value);
  }

}
