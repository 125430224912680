import {
  DadesBasiquesSharedFormModel,
  DadesExecucioSharedFormModel,
  DadesGeneralsSharedFormModel,
  DatesContracteSharedFormModel,
  ImportsSharedFormModel,
  ImportUnitatSharedFormModel,
  IndicadorsSharedFormModel
} from "../../shared/models/forms/dades-basiques/dades-basiques-shared-form.model";
import {GarantiaFormModel} from "../../shared/standalone/components/garanties/models/garantia-form.model";
import {PublicacionsFormModel} from "../../shared/standalone/components/publicacions/models/publicacions-form.model";
import {AcordMarc} from "../../shared/models/expedient/acord-marc.model";
import {CriterioSubjetivoFormModel} from "../../shared/standalone/components/criterio-subjetivo/models/criterio-subjetivo-form.model";
import {CriterioObjetivoFormModel} from "../../shared/standalone/components/criterio-objetivo/models/criterio-objetivo-form.model";
import {CriterioExclusionLicitadorFormModel} from "../../shared/standalone/components/criterio-exclusion-licitador/models/criterio-exclusion-licitador-form.model";
import {VehiculosLimpiosFormModel} from "../../shared/standalone/components/vehiculos-limpios/models/vehiculos-limpios-form.model";
import {SubcontratistaFormModel} from "../../shared/standalone/components/subcontratista/models/subcontratista-form.model";

export class DadesBasiquesNmFormModel extends DadesBasiquesSharedFormModel {

  dadesGenerals: DadesGeneralsNmFormModel;
  indicadors: IndicadorsNmFormModel;
  imports: ImportsNmFormModel;
  datesContracte: DatesContracteNmFormModel;
  dadesExecucio: DadesExecucioNmFormModel;
  solvencia: SolvenciaNmFormModel;
  convocatoria: ConvocatoriaNmFormModel;
  garanties: GarantiaFormModel[];
  criterioSubjetivo: CriterioSubjetivoFormModel[];
  criterioObjetivo: CriterioObjetivoFormModel[];
  lots: DadesBasiquesNmFormModel[];
  publicacions: PublicacionsFormModel;
  avisReobertura: string;
  criterioExclusionLicitador: CriterioExclusionLicitadorFormModel[];
  vehiculosLimpios: VehiculosLimpiosFormModel[];
  subcontratista: SubcontratistaFormModel[];

  constructor() {
    super();
    this.dadesGenerals = new DadesGeneralsNmFormModel();
    this.indicadors = new IndicadorsNmFormModel();
    this.imports = new ImportsNmFormModel();
    this.datesContracte = new DatesContracteNmFormModel();
    this.dadesExecucio = new DadesExecucioNmFormModel();
    this.solvencia = new SolvenciaNmFormModel();
    this.convocatoria = new ConvocatoriaNmFormModel();
    this.garanties = new Array<GarantiaFormModel>();
    this.criterioSubjetivo = new Array<CriterioSubjetivoFormModel>();
    this.criterioObjetivo = new Array<CriterioObjetivoFormModel>();
    this.lots = new Array<DadesBasiquesNmFormModel>();
    this.publicacions = new PublicacionsFormModel();
    this.avisReobertura = '';
    this.criterioExclusionLicitador = new Array<CriterioExclusionLicitadorFormModel>();
    this.vehiculosLimpios = new Array<VehiculosLimpiosFormModel>();
    this.subcontratista = new Array<SubcontratistaFormModel>();
  }
}

export class DadesGeneralsNmFormModel extends DadesGeneralsSharedFormModel {
  criterisValoracio: number = null;
  supositNegociat: number = null;
  prorrogaPrevista: boolean = null;
  descripcionProrroga: string = null;
  numeroProrrogas: number = null;
  plazoProrroga: number = null;
  tipoPlazoProrroga: number = null;
  modificacioPrevista: boolean = null;
  revisioPreus: boolean = null;
  revisioPreusOpcio: number = null;
  solicitudsParticipacio: boolean = null;
  lotAcordMarcOrigen: AcordMarc = null;

  constructor() {
    super();
  }
}

export class IndicadorsNmFormModel extends IndicadorsSharedFormModel {

  constructor() {
    super();
  }

  harmonitzat: boolean = null;
  eximitGarantia: boolean = null;
  abonamentTotalPreu: boolean = null;
  pagamentUnic: boolean = null;
  encarrecGestioOrigen: string = null;
  teCompraInnovadora: boolean = null;
  comprasInnovadoras: number[] = null;
  subhastaElectronica: boolean = null;
  anunciPrevi: boolean = null;
  variant: boolean = null;
  contracteEstranger: boolean = null;
  renting: boolean = null;
  leasing: boolean = null;
  pagEspecies: boolean = null;
  preuUnitari: boolean = null;
  sectorExclos: boolean = null;
  contConjunta: boolean = null;
  instrumentoPublicidad: number = null;
}

export class ImportsNmFormModel extends ImportsSharedFormModel {

  constructor() {
    super();
  }

  importsUnitat: ImportUnitatNmFormModel[] = new Array<ImportUnitatNmFormModel>();
}

export class ImportUnitatNmFormModel extends ImportUnitatSharedFormModel {

  constructor() {
    super();
  }

  public lot: number = null;
  public titol: string = null;
  public objecte: string = null;
}

export class DatesContracteNmFormModel extends DatesContracteSharedFormModel {

  constructor() {
    super();
  }

  dataDesert: Date = new Date();
  dataIniciComput: Date = new Date();
  dataIncoacio: Date = new Date();
  dataAprovExpedient: Date = new Date();
  dataNotificacio: Date = new Date();
  dataAcordExecucio: Date = new Date();
  dataAcceptacio: Date = new Date();
  dataInvitacioLicitadors: Date = new Date();
}

export class DadesExecucioNmFormModel extends DadesExecucioSharedFormModel {

  constructor() {
    super();
  }

  dataIniciGarantia: Date = new Date();
  dataIniciFormalitzacio: Date = new Date();
  dataFiFormalitzacio: Date = new Date();
  dataFiTerminiProrrogat: Date = new Date();
  dataFiPeriodeGarantia: Date = new Date();

  tipusTerminiFormalitzacio: number = null;
  terminiFormalitzacio: number = null;

  terminiGarantia: number = null;
  tipusTerminiGarantia: number = null;

  terminiMaximProrroga: number = null;
  tipusTerminiMaximProrroga: number = null;

}

export class SolvenciaNmFormModel {

  constructor() {
  }

  id: number = null;
  classificacioEmpresarial: ClassificacioEmpresarialNmFormModel[] = new Array<ClassificacioEmpresarialNmFormModel>();
  criterisSolvencia: CriterisSolvenciaNmFormModel[] = new Array<CriterisSolvenciaNmFormModel>();
  criterisSolvenciaTecnica: CriterisSolvenciaTecnicaNmFormModel[] = new Array<CriterisSolvenciaTecnicaNmFormModel>();
}

export class CriterisSolvenciaNmFormModel {

  constructor() {

  }

  id: number = null;
  tipusCriteriSolvencia: number = null;
  determinacioCriteriSolvencia: number = null;
  descripcio: string = null;
  ponderacio: number = null;
}

export class CriterisSolvenciaTecnicaNmFormModel {

  constructor() {

  }

  id: number = null;
  tipusCriteriSolvenciaTecnica: number = null;
  determinacioCriteriSolvenciaTecnica: number = null;
  descripcioTecnica: string = null;
  // ponderacio: number = null;
  observacions: string = null;
}

export class ClassificacioEmpresarialNmFormModel {

  constructor() {
  }

  id: number = null;
  grup: number = null;
  subgrup: number = null;
  categoria: number = null;
  tipusContracte: number = null;
}

export class ConvocatoriaNmFormModel {

  constructor() {
  }

  id: number = null;
  dataHoraLimitRecepcio: Date = new Date();
  llocRecepcio: string = null;
  dataObertura: Date = new Date();
  llocObertura: string = null;
  fechaAperturaAdministrativo: Date = new Date();
  fechaAperturaTecnico: Date = new Date();
  fechaAperturaEconomico: Date = new Date();
  criterisObjectiusAbsolut: boolean = null;
  criterisObjectiusMaxMin: boolean = null;
  nombreAbsolutCandidats: number = null;
  nombreMinimCandidats: number = null;
  nombreMaximCandidats: number = null;
  text: string = null;
  solicituds: SolicitudsNmFormModel[] = new Array<SolicitudsNmFormModel>();
}

export class SolicitudsNmFormModel {

  constructor() {
  }

  invitacio: string = null;
  licitador: string = null;
  nif: string = null;
  nom: string = null;
  ute: boolean = null;
  nomUte: string = null;
  invitacioParticipar: boolean = null;
  estatSolicitud: number = null;
  nomApoderat: string = null;
  nifApoderat: string = null;
  correuElectronic: string = null;
}
