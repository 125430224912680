import {Injectable} from '@angular/core';
import {Tasca} from '../../../shared/models/bustia/tasca.model';
import {AppConstants, massiveTaskNameMap} from "../../../app.constants";
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from "rxjs";
import {CustomUtils} from '../../../shared/utils/custom.utils';
import {Router} from '@angular/router';
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";
import {AgrupacioTasques} from '../../../bustia/model/agrupacio-tasques.model';
import {CustomRouterReuseService} from '../custom-router-reuse/custom-router-reuse.service';
import {Page} from '../../../shared/interfaces/paging/page';
import {BustiaService} from "../../../shared/services/bustia/bustia.service";
import {Taskable} from "../../../shared/models/forms/geec-form-dto.model";
import {NumberUtils} from "../../../shared/utils/number-utils";
import {tap} from "rxjs/operators";
import {BustiaCountModel} from "../../../bustia/model/bustia-count.model";

export const DEFAULT_BUSTIA_PAGE_SIZE: number = 20;

const DEFAULT_PAGE: Page<any> = {
  content: [],
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  size: 0,
  sort: null,
  totalElements: 0,
  totalPages: 0
};

export interface PageableConfig {
  page: number;
  size: number;
  sortField?: string;
  sortOrder?: number;
}

@Injectable()
export class TascaService {

  /** last parameters used in search */
  private lastParameters: HttpParams;
  private tasques: Page<Tasca>;
  private tasquesAgrupades: Page<AgrupacioTasques>;
  private selectedTasca: Tasca;
  private taskable: Taskable;

  constructor(private http: HttpClient, private router: Router, private routerReuseService: CustomRouterReuseService,
              private bustiaService: BustiaService) {
    this._selectedTasca$ = new BehaviorSubject<Tasca>(null);
    this._tasques$ = new BehaviorSubject<BustiaCountModel>(new BustiaCountModel(DEFAULT_PAGE, 0));
    this._tasquesAgrupades$ = new BehaviorSubject<Page<AgrupacioTasques>>(DEFAULT_PAGE);
  }

  private _selectedTasca$: BehaviorSubject<Tasca>;

  get selectedTasca$(): Observable<Tasca> {
    return this._selectedTasca$.asObservable();
  }

  private _tasques$: BehaviorSubject<BustiaCountModel>;

  get tasques$(): Observable<BustiaCountModel> {
    return this._tasques$.asObservable();
  }

  private _tasquesAgrupades$: BehaviorSubject<Page<AgrupacioTasques>>;

  get tasquesAgrupades$(): Observable<Page<AgrupacioTasques>> {
    return this._tasquesAgrupades$.asObservable();
  }

  private static groupTasques(page: Page<Tasca>): Page<AgrupacioTasques> {
    let groupedArray: AgrupacioTasques[] = [];
    const groupField: string = "expedientId";
    for (let item of page.content) {
      if (CustomUtils.HOPAID(item, groupField)) {
        let index = groupedArray.findIndex(agrupacioTasques => agrupacioTasques[groupField] === item[groupField]);
        if (index !== -1) {
          // group by
          groupedArray[index].tasques.push(item);
        } else {
          groupedArray.push({
            expedientId: item['expedientId'],
            expedientCodi: item['expedientCodi'],
            expedientTitol: item['expedientTitol'],
            tasques: [item]
          });
        }
      }
    }
    return {
      content: groupedArray,
      first: page.first,
      last: page.last,
      number: page.number,
      numberOfElements: page.numberOfElements,
      size: page.size,
      sort: page.sort,
      totalElements: page.totalElements,
      totalPages: page.totalPages
    };
  }

  getSelectedTasca(): Tasca {
    return this.selectedTasca;
  }

  selectTasca(tasca: Tasca): void {
    this.selectedTasca = tasca;
    this._selectedTasca$.next(this.selectedTasca);
  }

  getTaskable(): Taskable {
    return this.taskable;
  }

  setTaskable(value: Taskable): void {
    this.taskable = value;
  }

  updateTasques(mapString: LooseObject<string> = {}): Observable<Page<Tasca>> {
    let httpParameters: HttpParams = new HttpParams({fromObject: mapString});
    this.lastParameters = httpParameters;

    return this.http.get<Page<Tasca>>(AppConstants.urlGetTasques, {params: httpParameters})
      .pipe(tap((resultatBustia: BustiaCountModel) => {
        this.fixDatesTasques(resultatBustia.tasquesBustia.content);
        /* Propagate tasques to the subscribed components. */
        this._updateTasques(resultatBustia);
      }));
  }

  // TODO remove router param in the future
  tornarBustia(router?: Router): void {
    this.router.navigateByUrl(AppConstants.routeBustia).then(() => {
      this.refreshTasques().subscribe();
    });
  }

  refreshTasques(): Observable<BustiaCountModel> {
    // search first page as we are refreshing
    if (this.lastParameters) {
      this.lastParameters = this.lastParameters.set("page", JSON.stringify(0));
    } else {
      const paramsMap: LooseObject<string> = {};
      paramsMap["page"] = JSON.stringify(0);
      paramsMap["size"] = JSON.stringify(DEFAULT_BUSTIA_PAGE_SIZE);
      if (CustomUtils.isDefined(this.bustiaService.activeTableState)) {
        paramsMap["agrupada"] = JSON.stringify(this.bustiaService.activeTableState.groupedTable);
      } else {
        paramsMap["agrupada"] = JSON.stringify(false);
      }
      this.lastParameters = new HttpParams({fromObject: paramsMap});
    }

    return this.http.get<BustiaCountModel>(AppConstants.urlGetTasques, {params: this.lastParameters})
      .pipe(tap((resultatBustia: BustiaCountModel) => {
        this.fixDatesTasques(resultatBustia.tasquesBustia.content);
        /* Propagate tasques to the subscribed components. */
        this._updateTasques(resultatBustia);
      }));
  }

  navigateNextAndNew(nextTask: Tasca): void {
    this.routerReuseService.nextAndNew = true;
    this.navigateToTask(nextTask, ['/' + nextTask.descripcio, nextTask.taskeableId, nextTask.id]);
  }

  navigateToTaskWithRouteReuse(nextTask: Tasca, data?: any): void {
    const commands: any[] = this.routerReuseService.prepareToNavigate(['/' + nextTask.descripcio, nextTask.taskeableId, nextTask.id], nextTask, data);
    this.navigateToTask(nextTask, commands);
  }

  navigateToTask(nextTask: Tasca, commands?: any[]): void {
    this.selectTasca(nextTask);
    this.router.navigate(commands ? commands : ['/' + nextTask.descripcio, nextTask.taskeableId, nextTask.id]);
  }

  getTasca(idTasca: string): Observable<Tasca> {
    return this.http.get<Tasca>(`${AppConstants.urlGetTasques}/${idTasca}`);
  }

  potTramitacioMassiva(tasca: Tasca): boolean {
    return !AppConstants.tasquesNoTramitacioMassiva.includes(tasca.descripcio);
  }

  getNomTascaMassiva(taskKey: string, nomTascaActual: string) {
    const nomPantalla: string = massiveTaskNameMap.get(taskKey);
    return CustomUtils.isDefined(nomPantalla) ? nomPantalla : nomTascaActual;
  }

  private _updateTasques(bustiaCountModel: BustiaCountModel): void {
    this.tasques = bustiaCountModel.tasquesBustia;
    this.tasquesAgrupades = TascaService.groupTasques(bustiaCountModel.tasquesBustia);
    this._tasques$.next(bustiaCountModel);
    this._tasquesAgrupades$.next(this.tasquesAgrupades);
  }

  private fixDatesTasques(tasques: Tasca[]): void {
    tasques.forEach((tasca: Tasca) => {
      if (NumberUtils.isNumeric(tasca.dataCreacio)) {
        tasca.dataCreacio = new Date(tasca.dataCreacio);
      }
      if (NumberUtils.isNumeric(tasca.dataExpiracio)) {
        tasca.dataExpiracio = new Date(tasca.dataExpiracio);
      }
    });
  }

}
