import {Component, Input} from '@angular/core';
import {TableColumn, TableColumnType} from '../../interfaces/table-column';
import {LooseObject} from '../../models/forms/reactive-form-validator';
import {SelectItemData} from '../../models/forms/geec-form-dto.model';
import {TableUtils} from '../../utils/table-utils/table-utils';
import {CustomUtils} from "../../utils/custom.utils";
import {MapperUtils} from "../../utils/mapper-utils";

@Component({
  selector: 'geec-display-field',
  templateUrl: './display-field.component.html',
  // TODO change TableColumn to a class with an event emitter to check for options changes
  // TODO subscribe to the event emitter if column type is SELECT or AUTOCOMPLETE in order to call markForChanges
  // TODO also create a TableService similar to FormBuilder to manage table columns
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayFieldComponent {

  private _column: TableColumn;
  private _value: LooseObject<any>;

  get column(): TableColumn {
    return this._column;
  }

  @Input() set column(column: TableColumn) {
    this._column = column;
    this._updateInternalValue();
  }

  get value(): LooseObject<any> {
    return this._value;
  }

  @Input() set value(value: LooseObject<any>) {
    this._value = value;
    this._updateInternalValue();
  }

  readonly columnTypes: typeof TableColumnType = TableColumnType;

  getSelectLabel: (value: number, options: SelectItemData[]) => string = TableUtils.getSelectLabel;

  internalValue: any;

  constructor() {
  }

  private _updateInternalValue(): void {
    if (CustomUtils.areDefined(this.column, this.value)) {
      this.internalValue = MapperUtils.resolveFieldData(this.value, this.column.field);
    }
  }

}
