import {Injectable} from "@angular/core";
import {TreeNode} from "primeng/api";
import {Observable} from "rxjs";
import {TableColumn, TableColumnType} from "../../../interfaces/table-column";
import {GeecFormBuilder} from "../../../../core/directives/reactive-forms/geec-form-builder";
import {UnitatService} from "../../../../core/services/unitat/unitat.service";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {AppConstants} from "../../../../app.constants";
import {ImportUnitatSharedFormModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form.model";
import {GeecValidators} from "../../../validators/geec-validators";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ImportsService {

  traducciones: any = {};
  constructor(private _gfb: GeecFormBuilder, private _unitatService: UnitatService,
              private _translate: TranslateService) {
    this.traducirColumnas();
    this._translate.onLangChange.subscribe(()=> {
      this.traducirColumnas();
    })
  }

  private traducir(tag: string) : string{
    let ruta = 'imports';
    return this._translate.instant(ruta + '.' + tag);
  }

  private traducirColumnas(){
    this.traducciones = {
      unitatPromotora: this.traducir('column-unidad-promotora'),
      importLicitacio: this.traducir('column-importe-licitacion'),
      tipusIvaLicitacion: this.traducir('column-IVA-licitacion'),
      importLicitacioIva: this.traducir('column-importe-licitacion-IVA'),
      importAdjudicacio: this.traducir('column-adjudicacion'),
      tipusIvaAdjudicacio: this.traducir('column-IVA-adjudicacion'),
      importAdjudicacioIva: this.traducir('column-importe-adjudicacion-IVA'),
      moneda: this.traducir('column-moneda')
    }
  }

  getTableColumns(): TableColumn[] {
    return [
      {field: "unitatPromotora", header: this.traducciones.unitatPromotora, width: "20em"},
      {field: "importLicitacio", header: this.traducciones.importLicitacio, width: "10em", type: TableColumnType.DECIMAL},
      {field: "tipusIvaLicitacio", header: this.traducciones.tipusIvaLicitacio, width: "12em", type: TableColumnType.SELECT},
      {field: "importLicitacioIva", header: this.traducciones.importLicitacioIva, width: "12em", type: TableColumnType.DECIMAL},
      {field: "importAdjudicacio", header: this.traducciones.importAdjudicacio, width: "12em", type: TableColumnType.DECIMAL},
      {field: "tipusIvaAdjudicacio", header: this.traducciones.tipusIvaAdjudicacio, width: "12em", type: TableColumnType.SELECT},
      {field: "importAdjudicacioIva", header: this.traducciones.importAdjudicacioIva, width: "14em", type: TableColumnType.DECIMAL},
      {field: "moneda", header: this.traducciones.moneda, width: "7em", type: TableColumnType.SELECT},
    ];
  }

  getUnitatPromotoraLabel(id: number): Observable<TreeNode> {
    return this._unitatService.findUnitatInTree(id);
  }

  createForm(): GeecFormGroup {
    const {min, max} = AppConstants.importRange;
    return this._gfb.group({
      importLicitacio: [null, GeecValidators.range(min, max)],
      importLicitacioIVA: [null],
      importAdjudicacio: [null, GeecValidators.range(min, max)],
      importAdjudicacioIVA: [null],
      tipusIvaLicitacio: [null],
      tipusIvaAdjudicacio: [null],
      valorEstimat: [null, GeecValidators.range(min, max)],
      periodicitat: [null],
      moneda: [null],
      importPublicacio: [null, GeecValidators.range(min, max)],
      canon: [null, GeecValidators.range(min, max)],
      pagamentCanon: [null],
      aportacionsPubliques: [null],
      importLicitacioTotal: [null],
      importLicitacioTotalIva: [null],
      importAdjudicacioTotal: [null],
      importAdjudicacioTotalIva: [null],
      importsUnitat: [[]]
    });
  }

  createImportUnitatForm(expedientId: number, original?: ImportUnitatSharedFormModel): GeecFormGroup {
    const {min, max} = AppConstants.importRange;
    return this._gfb.group({
      expedient: [original ? original.expedient : expedientId],
      unitatPromotora: [original ? original.unitatPromotora : null],
      importLicitacio: [original ? original.importLicitacio : null, GeecValidators.range(min, max)],
      tipusIvaLicitacio: [original ? original.tipusIvaLicitacio : null],
      tipusIvaAdjudicacio: [original ? original.tipusIvaAdjudicacio : null],
      importLicitacioIva: [original ? original.importLicitacioIva : null],
      importAdjudicacio: [original ? original.importAdjudicacio : null],
      importAdjudicacioIva: [original ? original.importAdjudicacioIva : null],
      moneda: [original ? original.moneda : null],
    });
  }
}
