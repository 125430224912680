import {ComponentRef, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {CustomRouterReuseService} from './services/custom-router-reuse/custom-router-reuse.service';
import {CustomUtils} from '../shared/utils/custom.utils';

interface StoredRouteHandle {
  route: string;
  handle: DetachedRouteHandle;
  componentName: string;
  isStaticComponent: boolean;
}

@Injectable()
export class CustomRouterReuseStrategy implements RouteReuseStrategy {

  private routeQueue: StoredRouteHandle[] = [];

  constructor(private routerReuseService: CustomRouterReuseService) {
  }

  private static getComponentName(route: ActivatedRouteSnapshot): string {
    let key: string = null;
    if (route.component) {
      key = route.component['name'];
    } else if (CustomUtils.HOPAID(route, ['firstChild', 'component', 'name'])) {
      key = route.firstChild.component['name'];
    }
    return key;
  }

  private static getFutureRoute(): string {
    const route: string = window.location.pathname;
    return route.replace("/gconf", "");
  }

  /** Determines if this route (and its subtree) should be detached to be reused later */
  /** if true call store method */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.routerReuseService.componentMustBeSaved(route);
  }

  /** Stores the detached route */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const currentPath: string = this.getCurrentRoute();
    const componentName: string = CustomRouterReuseStrategy.getComponentName(route);
    if (CustomUtils.isDefined(handle)) {
      this.routerReuseService.markComponentToSave(false);
      this.addRouteToQueue({
        route: currentPath,
        handle: handle,
        componentName: componentName,
        isStaticComponent: this.routerReuseService.isStaticComponent(route)
      });
    } else {
      this.routerReuseService.markComponentToRetrieve(false);
      this.popRouteFromQueue();
    }
  }

  /** Determines if this route (and its subtree) should be reattached */
  /** if true call retrieve method then store method with null value */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const futurePath: string = CustomRouterReuseStrategy.getFutureRoute();
    let shouldRouteBeReused: boolean = false;
    if (route.routeConfig && this.routerReuseService.componentMustBeRetrieved(route)) {
      shouldRouteBeReused = this.routeIsNextInQueue(futurePath);
    } else if (this.routerReuseService.nextAndNew) {
      // clear the flag and do not store the route
      this.routerReuseService.nextAndNew = false;
    }
    return shouldRouteBeReused;
  }

  /** Retrieves the previously stored route */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    let retrievedRoute: DetachedRouteHandle = null;
    if (this.routerReuseService.isNormalNavigation(route)) {
      this.destroyAllStoredRoutes();
    }

    if (this.routerReuseService.componentMustBeRetrieved(route) && route.routeConfig) {
      if (this.routerReuseService.isStaticComponent(route)) {
        this.destroyAllStoredRoutes();
      }
      const queuedRoute: { route: string, handle: DetachedRouteHandle } = this.getLastRouteInQueue();
      if (queuedRoute) {
        retrievedRoute = queuedRoute.handle;
      }
    }
    return retrievedRoute;
  }

  /** Determines if a route should be reused */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return (future.routeConfig === curr.routeConfig);
  }

  private addRouteToQueue(routeHandle: StoredRouteHandle): void {
    this.routeQueue.push(routeHandle);
    this.routerReuseService.addRouteToQueue(routeHandle.route, routeHandle.componentName, routeHandle.isStaticComponent);
  }

  private popRouteFromQueue(): void {
    this.routeQueue.pop();
    this.routerReuseService.popRouteFromQueue();
  }

  private getLastRouteInQueue(): StoredRouteHandle {
    if (CustomUtils.isArrayNotEmpty(this.routeQueue)) {
      return this.routeQueue[this.routeQueue.length - 1];
    }
    return null;
  }

  private routeIsNextInQueue(route: string): boolean {
    let nextRoute: string = '';

    if (CustomUtils.isArrayNotEmpty(this.routeQueue)) {
      nextRoute = this.routeQueue[this.routeQueue.length - 1].route;
    }

    return nextRoute === route;
  }

  private getCurrentRoute(): string {
    return this.routerReuseService.getCurrentRoute();
  }

  private destroyAllStoredRoutes(): void {
    if (CustomUtils.isArrayNotEmpty(this.routeQueue)) {
      this.routeQueue = this.routeQueue.filter((routeHandle: StoredRouteHandle) => {
        if (!routeHandle.isStaticComponent) {
          const componentRef: ComponentRef<any> = routeHandle.handle['componentRef'];
          if (componentRef) {
            componentRef.destroy();
          }
        }
        return routeHandle.isStaticComponent;
      });
    }
    this.routerReuseService.clearStoredRoutes();
  }

}
