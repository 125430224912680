import {TableColumn, TableColumnType} from '../../interfaces/table-column';
import {CustomUtils} from '../custom.utils';
import {FormField, SelectItemData} from '../../models/forms/geec-form-dto.model';
import {proxyHandler} from "../../../core/decorators/mapper/utils/proxyHandler";

export class TableUtils {

  /**
   * @param {TableColumn[]} columns
   * @param fieldsDefinition
   */
  static updateTableColumnOptions(columns: TableColumn[], fieldsDefinition: any): void {
    if (CustomUtils.isArrayNotEmpty(columns)) {
      columns.forEach((column: TableColumn) => {
        const proxyFieldsDefinition: any = new Proxy(fieldsDefinition, proxyHandler);
        if (TableUtils.isSelectAndHasOptions(column.type, proxyFieldsDefinition[column.field])) {
          column.options = proxyFieldsDefinition[column.field].data;
        }
      });
    }
  }

  /**
   * @param {TableColumn} column
   * @param {SelectItemData} option
   */
  static addTableColumnOption(column: TableColumn, option: SelectItemData): void {
    if (column && option) {
      if (CustomUtils.isArrayNotEmpty(column.options)) {
        if (!TableUtils.getTableColumnOption(option.value, column.options)) {
          column.options.push(option);
        }
      } else {
        column.options = [option];
      }
    }
  }

  /**
   * @param {number} value
   * @param {SelectItemData[]} options
   * @returns {string}
   */
  static getSelectLabel(value: number, options: SelectItemData[]): string {
    let label: string = null;
    const option: SelectItemData = TableUtils.getTableColumnOption(value, options);
    if (option) {
      label = option.label;
    }

    return label;
  }

  /**
   * @param {TableColumn[]} columns
   * @param {string} field
   * @returns {TableColumn}
   */
  static getTableColumn(columns: TableColumn[], field: string): TableColumn {
    if (CustomUtils.isArrayNotEmpty(columns)) {
      return columns.find((column: TableColumn) => column.field === field);
    }
    return null;
  }

  /**
   * @param {number} value
   * @param {SelectItemData[]} options
   * @returns {SelectItemData}
   */
  static getTableColumnOption(value: number, options: SelectItemData[]): SelectItemData {
    let option: SelectItemData = null;
    if (CustomUtils.isDefined(value) && CustomUtils.isArrayNotEmpty(options)) {
      option = options.find(
        (item: SelectItemData) => String(item.value) === String(value)
      );
    }
    return option;
  }

  static optionExists(column: TableColumn, optionValue: number): boolean {
    if (column && CustomUtils.isDefined(optionValue) && CustomUtils.isArrayNotEmpty(column.options)) {
      return column.options.findIndex((option: SelectItemData) => option.value === optionValue) !== -1;
    }
    return false;
  }

  /**
   * @param {TableColumnType} type
   * @param {FormField} fieldDefinition
   * @returns {boolean}
   */
  private static isSelectAndHasOptions(type: TableColumnType, fieldDefinition: FormField): boolean {
    return (type === TableColumnType.SELECT) && fieldDefinition && CustomUtils.isArrayNotEmpty(fieldDefinition.data);
  }

}
