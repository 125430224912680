import {Message} from "primeng/api";
import {ResponseMessage} from "../models/forms/controller-response.model";
import {CustomUtils} from "./custom.utils";
import {HttpResponse} from "@angular/common/http";
import {Direction} from '../interfaces/paging/direction';
import {isArray, isDate, isNull, isObject, isUndefined} from "util";

export function arrayBufferToBase64(arrayBuffer): string {
  return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
}

export function parseSortOrder(property: string, order: number): string {
  let direction: Direction = Direction.ASC;
  if (order === -1) {
    direction = Direction.DESC;
  }
  return `${property},${direction}`;
}

export function mapResponseMessages(msgs: ResponseMessage[]): Message[] {
  let res: Message[] = [];
  if (CustomUtils.isArrayNotEmpty(msgs)) {
    res = msgs.map<Message>((msg: ResponseMessage) => responseMessageToMessage(msg));
  }
  return res;
}

function responseMessageToMessage(msg: ResponseMessage): Message {
  return {
    severity: msg.severity,
    summary: msg.summary,
    detail: msg.detail
  };
}

export function responseBlobToFile(res: HttpResponse<Blob>): File {
  const filenameHeader: string = res.headers.get('content-disposition');
  const fileName: string = filenameHeader.split(';').reduce((old, part) => {
    let toReturn: string = old || null;

    part = part.trim();
    if (/^filename="[a-zA-Z.]+"$/.test(part)) {
      toReturn = part.match(/"((?:\\.|[^"\\])*)"/)[1];
    }

    return toReturn;
  });

  return new File([res.body], fileName);
}

/**
 * Configurable via options parameter:
 * indices: include array indices in FormData keys
 * nullsAsUndefineds: treat null values like undefined values and ignore them
 * includeEmptyArray: include empty array in FormData
 */
export function objectToFormData(obj: any,
                                 options?: { indices?: boolean, nullsAsUndefineds?: boolean, includeEmptyArray?: boolean },
                                 formData?: FormData, keyPrefix?: string): FormData {
  options = options || {};
  options.indices = isUndefined(options.indices) ? false : options.indices;
  options.nullsAsUndefineds = isUndefined(options.nullsAsUndefineds) ? false : options.nullsAsUndefineds;
  formData = formData || new FormData();

  if (isUndefined(obj)) {
    return formData;
  } else if (isNull(obj)) {
    if (!options.nullsAsUndefineds) {
      formData.append(keyPrefix, '');
    }
  } else if (isArray(obj)) {
    if (!obj.length) {
      if (options.includeEmptyArray) {
        const key: string = keyPrefix + '[]';

        formData.append(key, '');
      }
    } else {
      obj.forEach((value: any, index: number) => {
        const key = keyPrefix + '[' + (options.indices ? index : '') + ']';

        objectToFormData(value, options, formData, key);
      });
    }
  } else if (isDate(obj)) {
    formData.append(keyPrefix, obj.toISOString());
  } else if (isObject(obj) && !CustomUtils.isFile(obj) && !CustomUtils.isBlob(obj)) {
    Object.keys(obj).forEach((prop: string) => {
      const value: any = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key: string = keyPrefix ? keyPrefix + '.' + prop : prop;

      objectToFormData(value, options, formData, key);
    });
  } else {
    formData.append(keyPrefix, obj);
  }

  return formData;
}
