import {NgModule} from '@angular/core';
import {NumberDecimalPipe} from './number-decimal/number-decimal.pipe';
import {BooleanTextPipe} from './boolean-text/boolean-text.pipe';
import {BooleanPositiuNegatiuPipe} from "./boolean-positiu-negatiu/boolean-positiu-negatiu.pipe";
import {PrettyPrintPipe} from "./pretty-print/pretty-print.pipe";
import {OptionLabelPipe} from './option-label/option-label.pipe';
import {KeysPipe} from './keys/keys.pipe';
import {ArrayFirstPipe} from './array-first/array-first.pipe';
import {IsNotEmptyPipe} from "./is-not-empty/is-not-empty.pipe";
import {FileSizeFormatPipe} from './file-size-format/file-size-format.pipe';
import { SafePipe } from './security/safe.pipe';

@NgModule({
  declarations: [
    NumberDecimalPipe,
    BooleanTextPipe,
    BooleanPositiuNegatiuPipe,
    PrettyPrintPipe,
    OptionLabelPipe,
    KeysPipe,
    ArrayFirstPipe,
    IsNotEmptyPipe,
    FileSizeFormatPipe,
    SafePipe
  ],
  exports: [
    NumberDecimalPipe,
    BooleanTextPipe,
    BooleanPositiuNegatiuPipe,
    PrettyPrintPipe,
    OptionLabelPipe,
    KeysPipe,
    ArrayFirstPipe,
    IsNotEmptyPipe,
    FileSizeFormatPipe,
    SafePipe
  ],
})
export class PipesModule {

}
