import {environment} from "./environment";
import {EnvironmentType} from "../app/shared/globals";

export class EnvironmentUtils {

  static get isPRO(): boolean {
    return !environment.isPre && environment.production;
  }

  static get isPRE(): boolean {
    return environment.isPre;
  }

  static get isDev(): boolean {
    return !environment.production;
  }

  static get isInt(): boolean {
    return !environment.isPre && environment.type === EnvironmentType.INT;
  }

  static get isLoadTreeChildrenByDefault(): boolean {
    return environment.loadTreeChildrenByDefault;
  }

  static get urlIframe() {
    return environment.urlIframe;
  }
}
