import {AbstractControl, FormArray, FormGroup, ValidatorFn} from '@angular/forms';
import {CustomUtils} from './custom.utils';
import {GeecFormControl} from "../../core/directives/reactive-forms/geec-form-control";
import {FormField} from "../models/forms/geec-form-dto.model";
import {GeecFormGroup} from '../../core/directives/reactive-forms/geec-form-group';
import {ResponseMessage} from "../models/forms/controller-response.model";
import {HttpErrorResponse} from "@angular/common/http";
import {Message} from "primeng/api";
import {ReactiveFormValidator} from "../models/forms/reactive-form-validator";
import {coerceArray} from "./coercion/array-property";
import {AutocompleteSearchEvent} from "../interfaces/primeng/autocomplete-search-event";
import {GeecFormArray} from "../../core/directives/reactive-forms/geec-form-array";
import {Severity} from "../../core/services/notifications/notifications.service";


export class FormUtils {

  /**
   * Set form control values using model object
   * @param {FormGroup} form
   * @param obj
   * @param emitEvent
   */
  static setFormValues(form: FormGroup, obj: any, emitEvent: boolean = true): void {
    if (CustomUtils.isDefined(obj)) {
      Object.keys(form.controls).forEach((key: string) => {
        const control: AbstractControl = form.get(key);
        if (control instanceof FormArray) {
          this._setFormArrayValues(control, obj[key], emitEvent);
        } else if ((form.get(key) instanceof FormGroup)) {
          FormUtils.setFormValues(form.get(key) as FormGroup, obj[key], emitEvent);
        } else {
          form.get(key).setValue(obj[key], {emitEvent: emitEvent});
        }
      });
    }
  }

  static updateFormValues(formGroup: FormGroup, formValidatorModel: any): void {
    if (CustomUtils.isDefined(formGroup) && CustomUtils.isDefined(formValidatorModel)) {
      let _dummy = new FormField();
      Object.keys(formValidatorModel).filter((prop: string) => !_dummy.hasOwnProperty(prop)).forEach((prop: string) => {
        if (CustomUtils.HOPAID(formValidatorModel, prop)) {
          const validator: FormField = formValidatorModel[prop];
          const abstractControl: AbstractControl = formGroup.get(prop);
          if (CustomUtils.isDefined(abstractControl)) {
            switch (abstractControl.constructor) {
              case GeecFormControl:
                return (<GeecFormControl>abstractControl).update(validator);
              case FormGroup:
              case GeecFormGroup:
                return this.updateFormValues(<FormGroup>abstractControl, validator);
              case GeecFormArray:
                if (CustomUtils.isDefined((<GeecFormArray>abstractControl).controls)) {
                  (<GeecFormArray>abstractControl).controls.forEach((control: AbstractControl) => {
                    return this.updateFormValues(<FormGroup>control, validator);
                  });
                }
            }
          }
        }
      });

      _dummy = null;
    }
  }

  static autocompleteSearchType(event: AutocompleteSearchEvent, currentValue: any, normalSearch: (searchText: string) => void, cacheSearch?: (searchText: string) => void) {

    let searchText: string = "";
    if (event != null) {
      searchText = event.query.toLowerCase();
      if (event.originalEvent instanceof MouseEvent && CustomUtils.isDefined(currentValue)) {
        searchText = "";
      }
    }

    if (searchText.length > 0) {
      if (CustomUtils.isDefined(normalSearch)) {
        normalSearch(searchText);
      }
    } else {
      if (CustomUtils.isDefined(cacheSearch)) {
        cacheSearch(searchText);
      } else if (CustomUtils.isDefined(normalSearch)) {
        normalSearch(searchText);
      }
    }
  }

  static isControlValid(control: AbstractControl, markAsDirty: boolean = true): boolean {
    if (control) {
      // disabled controls are neither valid nor invalid, so we consider them valid
      const valid: boolean = control.valid || control.disabled;
      if (!valid && markAsDirty) {
        if (control instanceof GeecFormGroup) {
          control.markAsDirty({onlySelf: true, propagateDown: true});
        } else {
          control.markAsDirty({onlySelf: true});
        }
      }
      return valid;
    } else {
      return false;
    }
  }

  static containsErrors(form: AbstractControl | AbstractControl[]): boolean {
    return coerceArray(form).some((f: AbstractControl) => f && f.invalid && f.dirty);
  }

  static handleError(err: HttpErrorResponse): Message[] {
    let messages: Message[];
    if (CustomUtils.isDefined(err)) {
      if (CustomUtils.HOPAID(err, ['error', 'messages']) && CustomUtils.isArrayNotEmpty(err.error.messages)) {
        messages = this.handleMessages(err.error.messages);
        CustomUtils.scrollToTop();
        return messages;
      } else {
        console.error(new Error(`Error del servidor: ${err.message}`));
        return [{severity: Severity.ERROR, summary: 'Error del servidor', detail: 'Error indeterminado'}];
      }
    }
  }

  static handleMessages(messages: ResponseMessage[]): Message[] {
    return (<ResponseMessage[]>messages).map((msg: ResponseMessage) => {
      return {severity: msg.severity, summary: msg.summary, detail: msg.detail};
    });
  }

  static isButtonVisible(formValidations: ReactiveFormValidator, buttonName: string): boolean {
    if (buttonName) {
      return this.checkButtonExistence(formValidations, buttonName) &&
        CustomUtils.HOPAID(formValidations, ['buttons', buttonName, 'visible']) &&
        formValidations['buttons'][buttonName].visible;
    }
  }

  static isButtonEnabled(formValidations: ReactiveFormValidator, buttonName: string): boolean {
    if (buttonName) {
      return this.checkButtonExistence(formValidations, buttonName) &&
        CustomUtils.HOPAID(formValidations, ['buttons', buttonName, 'enabled']) &&
        formValidations['buttons'][buttonName].enabled;
    }
  }

  static updateValueAndValidityRecursively(control: AbstractControl, emitEvent: boolean = false): void {
    if (control instanceof FormGroup) {
      Object.keys((<FormGroup>control).controls).forEach((key: string) => {
        FormUtils.updateValueAndValidityRecursively(control.get(key), emitEvent);
      });
    } else if (control instanceof FormArray) {
      if (CustomUtils.isArrayNotEmpty((<FormArray>control).controls)) {
        (<FormArray>control).controls.forEach((arrayControl: AbstractControl) => {
          FormUtils.updateValueAndValidityRecursively(arrayControl, emitEvent);
        });
      }
    }
    control.updateValueAndValidity({onlySelf: true, emitEvent: emitEvent});
  }

  static getControlName(control: AbstractControl): string | null {
    const formGroup = control.parent.controls;
    return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
  }

  static findControlValue(parentControl: AbstractControl, findFn: (c: AbstractControl) => AbstractControl): any | null {
    if (findFn && parentControl) {
      const control: AbstractControl = findFn(parentControl);
      if (control) {
        return control.value;
      }
    }
    return null;
  }

  static addNewValidatorRecursively(form: GeecFormGroup, newValidator: ValidatorFn, except?: string[]): void {
    if (form.controls) {
      Object.keys(form.controls).forEach((key: string) => {
        if (CustomUtils.isUndefinedOrNull(except) || CustomUtils.isUndefinedOrNull(except.find((prop: string) => {
          return prop === key;
        }))) {
          const subForm: GeecFormGroup = <GeecFormGroup>form.get(key);
          const currentValidators: ValidatorFn = subForm.validator;
          subForm.setValidators(currentValidators ? [currentValidators, newValidator] : newValidator);
          FormUtils.addNewValidatorRecursively(subForm, newValidator, except);
        }
      });
    }
  }

  private static checkButtonExistence(formValidations: ReactiveFormValidator, buttonName: string): boolean {
    if (buttonName) {
      return CustomUtils.isDefined(formValidations) && CustomUtils.HOPAID(formValidations, ['buttons', buttonName]);
    }
  }

  // TODO improve function to work with FormArray of FormControl | FormArray, right now
  // it only works with FormArray of FormGroup
  private static _setFormArrayValues(control: FormArray, arr: any, emitEvent: boolean): void {
    if (CustomUtils.isArrayNotEmpty(arr)) {
      if (arr.length > control.length) {
        // object property has more members, create the necessary form groups
        if ((control instanceof GeecFormArray) && CustomUtils.isDefined(control.createGroupFn)) {
          for (let i = control.length; i < arr.length; i++) {
            control.push(control.createGroupFn());
          }
        } else {
          console.error(`The object array is larger than the FormArray instance and createGroupFn is not defined [field ${FormUtils.getControlName(control)}]`);
          return;
        }
      } else if (arr.length < control.length) {
        // object property has less members, remove controls from form array as needed
        for (let i = arr.length - 1; i > control.length - 1; i--) {
          control.removeAt(i);
        }
      }
      // both arrays have same length
      const controls: AbstractControl[] = control.controls;
      Object.keys(controls).forEach((key: string) => {
        FormUtils.setFormValues(control.controls[key] as FormGroup, arr[key], emitEvent);
      });
    } else {
      // clear form array since obj array is empty
      while (control.length !== 0) {
        control.removeAt(0);
      }
    }
  }

}
