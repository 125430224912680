import {Pipe, PipeTransform} from '@angular/core';
import {coerceBooleanProperty} from '../../utils/coercion/boolean-property';

@Pipe({
  name: 'booleanText',
  pure: true
})
export class BooleanTextPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return coerceBooleanProperty(value) ? 'Sí' : 'No';
  }

}
