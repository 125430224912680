import {PublicacioFormModel} from "./publicacio-form.model";
import {FasePublicacio} from "./fase-publicacio";

export class PublicacionsFormModel {
  [FasePublicacio.LICITACIO]: PublicacioFormModel = null;
  [FasePublicacio.ADJUDICACIO]: PublicacioFormModel = null;
  [FasePublicacio.FORMALITZACIO]: PublicacioFormModel = null;
  [FasePublicacio.FORMALITZACIO_MODIFICACIO]: PublicacioFormModel = null;
  [FasePublicacio.FORMALITZACIO_PRORROGA]: PublicacioFormModel = null;
}
