import {NgModule} from '@angular/core';
import {GeecReactiveFormsModule} from "./reactive-forms/reactive-forms.module";
import {GeecTemplateDirective} from '../../shared/directives/geec-template.directive';
import {CalendarDefaultDirective} from "./calendar-default.directive";

@NgModule({
  declarations: [
    GeecTemplateDirective,
    CalendarDefaultDirective
  ],
  exports: [
    GeecReactiveFormsModule,
    GeecTemplateDirective,
    CalendarDefaultDirective
  ],
  imports: [
    GeecReactiveFormsModule
  ]
})
export class DirectivesModule {

}
