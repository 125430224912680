import {EntityModel} from '../entity.model';
import {CustomUtils} from '../../utils/custom.utils';
import {MapperUtils} from '../../utils/mapper-utils';
import {CacheItem} from './cache-item.model';

export class CacheItemList extends CacheItem {
  content: EntityModel[] = [];

  static isValid(cacheItemList: any): boolean {
    if (CustomUtils.isDefined(cacheItemList) && MapperUtils.isValidModel(cacheItemList, new CacheItemList()) && Array.isArray(cacheItemList.content)) {
      const entityModel: EntityModel = new EntityModel();
      for (let entity of cacheItemList.content) {
        if (!MapperUtils.isValidModel(entity, entityModel)) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }
}
