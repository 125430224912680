import {CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TascaService} from "./services/tasca/tasca.service";
import {NotificationsService} from "./services/notifications/notifications.service";
import {MenuComponent} from './components/menu/menu.component';
import {HeaderComponent} from './components/header/header.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {LoadingIndicatorService} from './services/loading-indicator/loading-indicator.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoadingIndicatorInterceptor} from './interceptors/loading-indicator.interceptor';
import {CoreRoutingModule} from './core-routing.module';
import {GeecAppInterceptor} from './interceptors/geec-app.interceptor';
import {CommonModule} from '@angular/common';
import {PreloadingStrategy, RouteReuseStrategy, RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TenderTreeService} from "./services/arbre-tasques/tender-tree.service";
import {CustomRouterReuseService} from './services/custom-router-reuse/custom-router-reuse.service';
import {CustomRouterReuseStrategy} from './custom-router-reuse-strategy';
import {UserPreferenceService} from '../shared/services/user-preference/user-preference.service';
import {UserTableStateService} from '../shared/services/user-table-state/user-table-state.service';
import {GrowlModule} from 'primeng/growl';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {ConfirmationService} from 'primeng/api';
import {SharedModule} from '../shared/shared.module';
import {AuthGuard} from './guards/auth/auth.guard';
import {NotAuthorizedComponent} from './components/not-authorized/not-authorized.component';
import {DevelopmentGuard} from './guards/development/development.guard';
import {DeactivateTaskBlockGuard} from "./guards/deactivateTaskBlock/deactivate-task-block.guard";
import {GoTopButtonComponent} from './components/go-top-button/go-top-button.component';
import {GeecErrorService} from "../shared/services/geec-error/geec-error.service";
import {GeecPreloadingStrategy} from "./geec-preloading-strategy";
import {PermissionGuard} from "./guards/permission/permission.guard";
import {WorkflowService} from "../shared/services/reobrir/workflow.service";
import {NoticeBoardComponent} from './components/notice-board/notice-board.component';
import {UnsupportedBrowserComponent} from './components/unsupported-browser/unsupported-browser.component';
import {OutdatedBrowserComponent} from './components/outdated-browser/outdated-browser.component';
import {UnavailableAppComponent} from './components/unavailable-app/unavailable-app.component';
import {AccessDeniedComponent} from "./components/access-denied/access-denied.component";
import {FooterComponent} from './components/footer/footer.component';

@NgModule({
  declarations: [
    MenuComponent,
    HeaderComponent,
    NotificationsComponent,
    PageNotFoundComponent,
    ErrorPageComponent,
    NotAuthorizedComponent,
    GoTopButtonComponent,
    NoticeBoardComponent,
    UnsupportedBrowserComponent,
    OutdatedBrowserComponent,
    UnavailableAppComponent,
    AccessDeniedComponent,
    FooterComponent
  ],
  exports: [
    // export RouterModule so lazy loaded modules can insert their routes
    RouterModule,
    MenuComponent,
    HeaderComponent,
    NotificationsComponent,
    GoTopButtonComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    GrowlModule,
    DropdownModule,
    DialogModule,
  ],
  providers: [
    CustomRouterReuseService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouterReuseStrategy
    },
    {
      provide: PreloadingStrategy,
      useClass: GeecPreloadingStrategy
    },
    AuthGuard,
    DevelopmentGuard,
    DeactivateTaskBlockGuard,
    UserPreferenceService,
    TenderTreeService,
    TascaService,
    NotificationsService,
    LoadingIndicatorService,
    ConfirmationService,
    WorkflowService,
    UserTableStateService,
    GeecErrorService,
    PermissionGuard,
    {provide: HTTP_INTERCEPTORS, useClass: GeecAppInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingIndicatorInterceptor, multi: true}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [NoticeBoardComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
