import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from "primeng/api";
import {FacturaService} from "../../../services/factura/factura.service";
import {CustomUtils} from "../../../utils/custom.utils";
import {ControllerResponse, ResponseMessage} from "../../../models/forms/controller-response.model";
import {concat, Observable, Subject} from "rxjs";
import {NotificationsService} from "../../../../core/services/notifications/notifications.service";
import {DadaMestra, DadaMestraService, DadaMestraType} from "../../../services/dada-mestra/dada-mestra.service";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {GeecFormBuilder} from "../../../../core/directives/reactive-forms/geec-form-builder";
import {map, takeUntil, toArray} from "rxjs/operators";
import {IdentifiedObject, IdentifiedResponse} from "../../../../llistats/listing-detail/listing-detail.component";

enum MotiusRebutjarOferta {
  IMPORT_INCORRECTE = 903,
  FACTURA_DUPLICADA = 904,
  EXPEDIENT_INCORRECTE = 905,
  DESACORD_LLIURAMENT = 906,
  PETICIO_PROVEIDOR = 907,
  ALTRES = 908,
  MANCA_PERIODE_FACTURACIO = 11663,
  MANCA_DATA_OPERACIO = 11664,
  POLISSA_INCORRECTE = 11665,
  IMPORT_NO_CONCIALIAT = 11666
}

@Component({
  selector: 'geec-rebutjar-factures',
  templateUrl: './rebutjar-factures.component.html'
})
export class RebutjarFacturesComponent implements OnInit {

  @Input() display: boolean;
  @Input() facturesIdentificades: IdentifiedObject[];
  @Input() idExpedient: number;
  @Output() displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() messagesChange: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  @Output() searchBustia: EventEmitter<void> = new EventEmitter<void>();

  isNotEmpty: (value: string) => boolean = CustomUtils.isNotEmpty;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  enumMotius: typeof MotiusRebutjarOferta = MotiusRebutjarOferta;
  motiusDisponibles: DadaMestra[];
  rebutjarForm: GeecFormGroup;

  constructor(private facturaService: FacturaService, private notificationsService: NotificationsService,
              private gfb: GeecFormBuilder, private dadaMestraService: DadaMestraService) {
  }

  ngOnInit() {
    this.dadaMestraService.getOptions(DadaMestraType.MOTIU_REBUIG_FACTURA, null).subscribe((dadesMestra: DadaMestra[]) => {
      this.motiusDisponibles = dadesMestra;

      this.rebutjarForm = this.gfb.group({
        idMotiu: [null],
        motiuRebuig: [null]
      });
      this.rebutjarForm.get('idMotiu').valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(() => {
        this.rebutjarForm.get('motiuRebuig').setValue(null);
      });
    });
  }

  isAltresSelected(): boolean {
    return this.rebutjarForm.get('idMotiu').value === this.enumMotius.ALTRES;
  }

  rebutjarFactures(): void {
    this.messagesChange.emit(null);
    const calls: Observable<IdentifiedResponse>[] = [];

    this.facturesIdentificades.forEach((bustiaFacturaIdentificada: IdentifiedObject) => {
      let observable: Observable<ControllerResponse<ResponseMessage>> = this.facturaService.rebutjarFactura(bustiaFacturaIdentificada.keyField, this.rebutjarForm.get('idMotiu').value, this.rebutjarForm.get('motiuRebuig').value);
      let identifiedObservable: Observable<IdentifiedResponse> = observable.pipe(map((resp: ControllerResponse<ResponseMessage>) => {
        return {identifiedField: bustiaFacturaIdentificada.keyDefinitionField, response: resp};
      }));
      calls.push(identifiedObservable);
    });

    if (CustomUtils.isArrayNotEmpty(calls)) {
      concat(...calls).pipe(toArray()).subscribe((responses: IdentifiedResponse[]) => {
        const messages: Message[] = this.notificationsService.getMessagesAndNotify(this.notificationsService.identifyMessages(responses));
        this.displayChange.emit(false);
        if (CustomUtils.isArrayNotEmpty(messages)) {
          this.messagesChange.emit(messages);
        }
        this.searchBustia.emit();
      });
    }
  }

  onClose(): void {
    this.displayChange.emit(false);
  }
}
