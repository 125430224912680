import {version} from './version';
import { EnvironmentType } from '../app/shared/globals';

export const environment = {
  type: EnvironmentType.INT,
  production: false,
  version: version,
  offlineNoticeUrl: "./assets/motd/notice.json",
  api: `${window.location.origin}/gconb`,
  isPre: false,
  urlEnvironmentJson: 'assets/entorn.json',
  loadTreeChildrenByDefault: true,
  urlIframe: "https://preaplicaciones.aragon.es/mfe_core/rest/identification/GCONB/aHR0cHM6Ly9pbnQuZ2Nvbi5nYWxhLXN2Yy5uZXQvZ2NvbmIvbWZlanM="
};
