import {CustomUtils} from "./custom.utils";
import {Decimal} from 'decimal.js';
import {coerceNumberProperty} from "./coercion/number-property";
import {SelectItemData} from "../models/forms/geec-form-dto.model";

export class NumberUtils {

  static ONE_HUNDRED: Decimal = new Decimal(100);

  /**
   * @desc Finds how much value a percentage has over a base.
   * For example, a 20% of 20 is 4.
   * @param base
   * @param percentage
   * @returns {number}
   */
  static findPercentualValue(base: number, percentage: number): number {
    return new Decimal(coerceNumberProperty(base))
      .dividedBy(NumberUtils.ONE_HUNDRED)
      .times(new Decimal(coerceNumberProperty(percentage)))
      .toDecimalPlaces(2).toNumber();
  }

  /**
   * @desc Finds how much percentage is worth a number over a base.
   * @param base
   * @param value
   */
  static findPercentage(base: number, value: number): number {
    return new Decimal(coerceNumberProperty(value))
      .dividedBy(coerceNumberProperty(base))
      .times(NumberUtils.ONE_HUNDRED)
      .toDecimalPlaces(2).toNumber() || 0;
  }

  /**
   * Returns a new number whose value is ${@param base} plus the IVA percentage {@param percentage}.
   * The value returned is rounded down to 2 decimals using ROUND_HALF_UP.
   * For example, calling calculateIva(100.50, 10) would return 110.55.
   * @param {number} base
   * @param {number} percentage
   * @param {boolean} subtract
   * @returns {number}
   */
  static calculatePercent(base: number, percentage: number, subtract: boolean): number {
    if (CustomUtils.isUndefinedOrNull(base) || CustomUtils.isUndefinedOrNull(percentage)) {
      return null;
    }
    if ((base === 0) || (percentage === 0)) {
      return subtract ? 0 : base;
    }
    const baseDec: Decimal = new Decimal(base);
    let baseDocResult: Decimal = baseDec.times(new Decimal(percentage)).dividedBy(NumberUtils.ONE_HUNDRED);
    baseDocResult = baseDocResult.plus(baseDec);
    if (subtract) {
      baseDocResult = baseDocResult.sub(baseDec);
    }
    return baseDocResult.toDecimalPlaces(2).toNumber();
  }

  /**
   * Returns the original amount after a percentage increase or decrease.
   * @param value
   * @param percentage
   * @param decrease
   */
  static calculatePercentageOriginalNumber(value: number, percentage: number, decrease: boolean = false): number {
    const valueDec: Decimal = new Decimal(coerceNumberProperty(value));
    const percentageDec: Decimal = new Decimal(coerceNumberProperty(percentage));
    if (valueDec.toNumber() === 0) {
      if (!decrease) {
        return 0;
      } else {
        throw new Error('Cannot calculate original amount from a 100% decrease.');
      }
    } else if (percentageDec.toNumber() === 0) {
      return valueDec.toNumber();
    } else {
      let coeficient: Decimal;
      if (decrease) {
        coeficient = new Decimal(1).sub(percentageDec.dividedBy(NumberUtils.ONE_HUNDRED));
      } else {
        coeficient = new Decimal(1).add(percentageDec.dividedBy(NumberUtils.ONE_HUNDRED));
      }
      return valueDec.dividedBy(coeficient).toDecimalPlaces(2).toNumber();
    }
  }

  static sum(first: number, second: number): number {
    const firstDec: Decimal = new Decimal(coerceNumberProperty(first));
    const secondDec: Decimal = new Decimal(coerceNumberProperty(second));
    return firstDec.add(secondDec).toDecimalPlaces(2).toNumber();
  }

  static substract(first: number, second: number): number {
    const firstDec: Decimal = new Decimal(coerceNumberProperty(first));
    const secondDec: Decimal = new Decimal(coerceNumberProperty(second));
    return firstDec.sub(secondDec).toDecimalPlaces(2).toNumber();
  }

  /**
   * Returns a new number whose value is ${@param val} rounded down to ${@param digits} decimal places using ROUND_HALF_UP.
   * * For example, calling round(0.357, 2) would return 0.36.
   * @param {number} val
   * @param {number} digits
   * @returns {number}
   */
  static round(val: number, digits: number): number {
    return new Decimal(val).toDecimalPlaces(digits).toNumber();
  }

  /**
   * Returns a new number whose value is ${@param val} rounded down to ${@param digits} decimal places.
   * For example, calling truncate(0.357, 2) would return 0.35.
   * @param {number} val
   * @param {number} digits
   * @returns {number}
   */
  static truncate(val: number, digits: number): number {
    return new Decimal(val).toDecimalPlaces(digits, Decimal.ROUND_DOWN).toNumber();
  }

  /**
   * Returns a new number whose value is the number of decimal digits in ${@param value)}.
   * For example, calling countDecimals(3.55) would return 2.
   * @param {number} value
   * @returns {number}
   */
  static countDecimals(value: number): number {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split(".")[1].length || 0;
  }

  /**
   * Returns a new number whose value is the maximum possible number with ${@param digits} integer digits.
   * For example, calling maxValuePossible(5) would return 99999.
   * @param {number} digits
   * @returns {number}
   */
  static maxValuePossible(digits: number): number {
    let limit: number = 0;
    for (let sum: number = 0; sum < digits; sum++) {
      // 9, 99, 999, 9999, etc
      limit = limit + (9 * Math.pow(10, sum));
    }
    return limit;
  }

  /**
   * Retuns a new string with a formatted version of the number {@param value}.
   * For example, the value 5.34 is formatted to 5,34.
   * @param {number} value
   * @param {number} minimumFractionDigits
   * @param {number} maximumFractionDigits
   * @returns {string}
   */
  static formatImport(value: number, minimumFractionDigits: number = 2, maximumFractionDigits = 2): string {
    if (CustomUtils.isDefined(value)) {
      return value.toLocaleString('ca', {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
      });
    }
    return null;
  }

  /**
   * * Whether the provided value is considered a number.
   * @param val
   * @returns {boolean}
   */
  static isNumeric(val: any): boolean {
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    return !Array.isArray(val) && (val - parseFloat(val) + 1) >= 0;
  }

  static getTipusIvaPercent(indicadorImpostos: number, tipusIvaOptions: SelectItemData[]): number {
    let tipusIvaOption: SelectItemData;
    if (CustomUtils.isArrayNotEmpty(tipusIvaOptions) && CustomUtils.isDefined(indicadorImpostos)) {
      tipusIvaOption = tipusIvaOptions.find((option: SelectItemData) => option.value === indicadorImpostos);
    }
    return tipusIvaOption ? tipusIvaOption.data : null;
  }

  /**
   * There are two ways to use this method:
   * Case 1: tipusIva as % value, without using tipusIvaOptions
   * Case 2: tipusIva as id, specifying tipusIvaOptions
   */
  static calculateImportIva(importBase: number, tipusIva: number, tipusIvaOptions?: SelectItemData[]): number {
    let tipusIvaPercent: number = tipusIva;
    if (CustomUtils.isDefined(tipusIvaOptions)) {
      tipusIvaPercent = NumberUtils.getTipusIvaPercent(tipusIva, tipusIvaOptions);
    }

    if (CustomUtils.areDefined(importBase, tipusIvaPercent)) {
      return NumberUtils.calculatePercent(importBase, tipusIvaPercent, false);
    }

    return null;
  }

  static calculateBaseImposable(importBase: number, importIva: number): number {
    if (CustomUtils.isDefined(importBase) && CustomUtils.isDefined(importIva)) {
      return NumberUtils.round(importIva - importBase, 2);
    }
    return null;
  }

}
