import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'geec-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  versionFront: string = "1.3.1";
  versionBack: string = "1.3.0";

  constructor() { }

  ngOnInit() {
  }

}
