import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ImportsService} from "./imports.service";
import {Subject} from "rxjs";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {CustomUtils} from "../../../utils/custom.utils";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {ImportUnitatSharedFormModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form.model";
import {TableColumn} from "../../../interfaces/table-column";
import {ImportsSharedFormValidatorModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {NumberUtils} from "../../../utils/number-utils";
import {FormUtils} from "../../../utils/form-utils";
import {TaskKey} from "../../../../app.constants";
import {SelectItemData} from "../../../models/forms/geec-form-dto.model";
import {UnitatTreeModel} from "../../../../core/services/unitat/unitat.service";
import {TipusIva} from "../../../interfaces/expedient/tipus-iva";
import {TipusIvaController} from "../../../controllers/tipus-iva.controller";
import {TableUtils} from "../../../utils/table-utils/table-utils";


@Component({
  template: ''
})
export class ImportsControllerComponent implements OnInit, OnDestroy {

  @Input()
  public readOnly: boolean;

  @Input()
  public expedientId: number = null;

  @Input()
  public keyTask: string;

  private _templateValidators: ImportsSharedFormValidatorModel;

  public get templateValidators(): ImportsSharedFormValidatorModel {
    return this._templateValidators;
  }

  @Input()
  public set templateValidators(model: ImportsSharedFormValidatorModel) {
    this._templateValidators = model;
    TableUtils.updateTableColumnOptions(this.columnsSelect, model);
  }

  @Output()
  public triggerValidation: EventEmitter<any> = new EventEmitter();

  public getOptionLabel = CustomUtils.getOptionLabel;

  public displayImportUnitatDialog: boolean = false;

  public importUnitatForm: FormGroup;

  public selectedImportUnitatIndex: number;

  public importsForm: GeecFormGroup;

  public importsUnitatControl: FormControl;

  public nomUnitats: Map<number, string> = new Map<number, string>();

  public columns: TableColumn[];

  public columnsSelect: TableColumn[];

  private _importLicitacioActual: number = null;
  private _importAdjudicacioActual: number = null;

  protected _ngUnsubscribe: Subject<void> = new Subject<void>();

  private _tipusIvaController: TipusIvaController;

  constructor(protected _service: ImportsService) {
    this.columnsSelect = this._service.getTableColumns();
    this.columns = this.columnsSelect;
  }

  @Input()
  get parentFormGroup(): GeecFormGroup {
    return this._parentFormGroup;
  }

  set parentFormGroup(parentFormGroup: GeecFormGroup) {
    this.importsForm = <GeecFormGroup>parentFormGroup.get("imports");
    this.importsUnitatControl = <FormControl>this.importsForm.get("importsUnitat");
    this._parentFormGroup = parentFormGroup;
    this._importLicitacioActual = this.importLicitacio.value;
    this._importAdjudicacioActual = this.importAdjudicacio.value;
  }

  get importLicitacio(): FormControl {
    return <FormControl>this.importsForm.get("importLicitacio");
  }

  get importAdjudicacio(): FormControl {
    return <FormControl>this.importsForm.get("importAdjudicacio");
  }

  get tipusIvaLicitacio(): FormControl {
    return <FormControl>this.importsForm.get('tipusIvaLicitacio');
  }

  get tipusIvaAdjudicacio(): FormControl {
    return <FormControl>this.importsForm.get('tipusIvaAdjudicacio');
  }

  private _parentFormGroup: GeecFormGroup;

  public ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this._initDetectChanges();
    this._initUnitatsPromotores(this.parentFormGroup.get('dadesExpedient').get('unitatsPromotores').value);
    this._constructTipusIvaController();
  }

  public changeTableImportsUnitat(): void {
    let importsUnitat: ImportUnitatSharedFormModel[] = this.importsUnitatControl.value;
    if (CustomUtils.isArrayNotEmpty(importsUnitat)) {
      // remove the table
      this.importsForm.get("importsUnitat").setValue([]);
      this.importsForm.get("importLicitacioTotal").setValue(null);
      this.importsForm.get("importLicitacioTotalIva").setValue(null);
      this.importsForm.get("importAdjudicacioTotal").setValue(null);
      this.importsForm.get("importAdjudicacioTotalIva").setValue(null);
    } else {
      // add rows to table
      importsUnitat = [];
      this.parentFormGroup.get('dadesExpedient').get('unitatsPromotores').value.forEach((unitat: number) => {
        importsUnitat.push(this._createNewImportUnitat(unitat, this._parentFormGroup.get("dadesExpedient").get("id").value));
        this._loadNomUnitatPromotora(unitat);
      });
      this.importsForm.get("importsUnitat").setValue(importsUnitat);
      this.importsForm.get("importLicitacioTotal").setValue(0);
      this.importsForm.get("importLicitacioTotalIva").setValue(0);
      this.importsForm.get("importAdjudicacioTotal").setValue(0);
      this.importsForm.get("importAdjudicacioTotalIva").setValue(0);
    }
  }

  public closeImportUnitatDialog(): void {
    this.importUnitatForm = null;
  }

  public saveImportUnitat(): void {
    if (this.importUnitatForm.valid) {
      let item: ImportUnitatSharedFormModel = this._createImportUnitat(this.importUnitatForm);

      item.importLicitacioIva = this._tipusIvaController.getImportLicitacioAmbIva(item.importLicitacio);
      item.importAdjudicacioIva = this._tipusIvaController.getImportAdjudicacioAmbIva(item.importAdjudicacio);

      this.importsUnitatControl.value[this.selectedImportUnitatIndex] = item;
      this._roundImports();
      this.displayImportUnitatDialog = false;
    } else {
      (<GeecFormGroup>this.importUnitatForm).markAsDirty({onlySelf: true, propagateDown: true});
    }
  }

  public selectImportUnitat(importUnitat: ImportUnitatSharedFormModel, rowIndex: number): void {
    this.importUnitatForm = this._createImportUnitatForm(importUnitat);
    this.selectedImportUnitatIndex = rowIndex;
    this.displayImportUnitatDialog = true;
  }

  public updateTipusIvaLicitacio(): void {
    // syncronize both inputs for tipusIvaLicitacio
    this.tipusIvaLicitacio.patchValue(this.tipusIvaLicitacio.value, {
      onlySelf: true,
      emitEvent: false
    });
    // call corticon validations
    this.triggerValidation.next();
  }

  public updateTipusIvaAdjudicacio(): void {
    // syncronize both inputs for tipusIvaAdjudicacio
    this.tipusIvaAdjudicacio.patchValue(this.tipusIvaAdjudicacio.value, {
      onlySelf: true,
      emitEvent: false
    });
    // call corticon validations
    this.triggerValidation.next();
  }

  public updateValorEstimatLicitacio(): void {
    if (CustomUtils.isTask(this.keyTask, [TaskKey.T1, TaskKey.T_LOTS_ADJ, TaskKey.BAM_T1])) {
      this.importsForm.get('valorEstimat').setValue(null);
      if (this.importLicitacio.value) {
        this.importsForm.get('valorEstimat').setValue(this.importLicitacio.value);
      }
    }
  }

  // TODO: VALOR ESTIMAT ADJUDICACIO ???
  // public updateValorEstimatAdjudicacio(): void {
  //   if (CustomUtils.isTask(this.keyTask, [TaskKey.T1, TaskKey.T_LOTS_ADJ, TaskKey.BAM_T1])) {
  //     this.importsForm.get('valorEstimat').setValue(null);
  //     const licitacioValue: number = this.importLicitacio.value;
  //     if (licitacioValue) {
  //       this.importsForm.get('valorEstimat').setValue(licitacioValue);
  //     }
  //   }
  // }

  protected _initDetectChanges(): void {
    this._detectMonedaChanges();
    this._detectTipusIvaLicitacioChanges();
    this._detectTipusIvaAdjudicacioChanges();
    this._detectImportLicitacioChanges();
    this._detectImportAdjudicacioChanges();
    this._detectUnitatsPromotoresChanges();
  }

  protected _onControlChange<T>(control: AbstractControl, cb: (value: T) => void): void {
    control.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((item: T) => cb(item));
  }

  private _createNewImportUnitat(unitatPromotora: number, idExpedientActual: number): ImportUnitatSharedFormModel {
    let importUnitat = new ImportUnitatSharedFormModel();
    importUnitat.expedient = idExpedientActual;
    importUnitat.unitatPromotora = unitatPromotora;
    importUnitat.tipusIvaLicitacio = this.tipusIvaLicitacio.value;
    importUnitat.tipusIvaAdjudicacio = this.tipusIvaAdjudicacio.value;
    importUnitat.moneda = this.importsForm.get('moneda').value;
    return importUnitat;
  }

  private _updateLicitacioImportsPromotora(): void {
    this.importsUnitatControl.setValue(this.importsUnitatControl.value.map((importUnitat: ImportUnitatSharedFormModel) => {
      importUnitat.tipusIvaLicitacio = this.tipusIvaLicitacio.value;
      if (importUnitat.importLicitacio) {
        importUnitat.importLicitacioIva = this._tipusIvaController.getImportLicitacioAmbIva(importUnitat.importLicitacio);
      }
      return {...importUnitat};
    }));
  }

  private _updateAdjudicacioImportsPromotora(): void {
    this.importsUnitatControl.setValue(this.importsUnitatControl.value.map((importUnitat: ImportUnitatSharedFormModel) => {
      importUnitat.tipusIvaAdjudicacio = this.tipusIvaAdjudicacio.value;
      if (importUnitat.importAdjudicacio) {
        importUnitat.importAdjudicacioIva = this._tipusIvaController.getImportAdjudicacioAmbIva(importUnitat.importAdjudicacio);
      }
      return {...importUnitat};
    }));
  }

  private _roundImports(): void {
    const importsUnitat: ImportUnitatSharedFormModel[] = this.importsUnitatControl.value;
    let importLicitacioTotal: number = 0;
    let importLicitacioTotalIva: number = 0;
    let importAdjudicacioTotal: number = 0;
    let importAdjudicacioTotalIva: number = 0;

    importsUnitat.forEach((importUnitat: ImportUnitatSharedFormModel) => {
      if (importUnitat.importLicitacio) {
        importLicitacioTotal += importUnitat.importLicitacio;
      }

      if (importUnitat.importAdjudicacio) {
        importAdjudicacioTotal += importUnitat.importAdjudicacio;
      }

      if (importUnitat.importLicitacioIva) {
        importLicitacioTotalIva += importUnitat.importLicitacioIva;
      }

      if (importUnitat.importAdjudicacioIva) {
        importAdjudicacioTotalIva += importUnitat.importAdjudicacioIva;
      }
    });

    importLicitacioTotal = NumberUtils.round(importLicitacioTotal, 2);
    importLicitacioTotalIva = NumberUtils.round(importLicitacioTotalIva, 2);
    importAdjudicacioTotal = NumberUtils.round(importAdjudicacioTotal, 2);
    importAdjudicacioTotalIva = NumberUtils.round(importAdjudicacioTotalIva, 2);

    this.importsForm.get("importLicitacioTotal").setValue(importLicitacioTotal);
    this.importsForm.get("importLicitacioTotalIva").setValue(importLicitacioTotalIva);
    this.importsForm.get("importAdjudicacioTotal").setValue(importAdjudicacioTotal);
    this.importsForm.get("importAdjudicacioTotalIva").setValue(importAdjudicacioTotalIva);
  }

  private _createImportUnitatForm(original?: ImportUnitatSharedFormModel): GeecFormGroup {
    const form: GeecFormGroup = this._service.createImportUnitatForm(this.expedientId, original);
    if (this.templateValidators.importsUnitat) {
      FormUtils.updateFormValues(form, this.templateValidators.importsUnitat);
    }
    return form;
  }

  private _createImportUnitat(formGroup: FormGroup): ImportUnitatSharedFormModel {
    const importUnitat: ImportUnitatSharedFormModel = new ImportUnitatSharedFormModel();
    Object.keys(formGroup.controls).forEach((key: string) => {
      importUnitat[key] = formGroup.get(key).value;
    });
    return importUnitat;
  }

  private _initUnitatsPromotores(unitats: number[]): void {
    unitats.forEach((unitat: number) => {
      this._loadNomUnitatPromotora(unitat);
    });
  }

  private _detectUnitatsPromotoresChanges(): void {
    this._onControlChange<number[]>(this.parentFormGroup.root.get('dadesExpedient').get('unitatsPromotores'), (unitats: number[]) => {
      let importsUnitat: ImportUnitatSharedFormModel[] = [...this.importsUnitatControl.value];

      if (CustomUtils.isArrayNotEmpty(importsUnitat) && unitats) {
        if (unitats.length === 1) {
          // remove all elements from list as there are no multiple unitats selected
          this.nomUnitats.clear();
          this.importsForm.get('importsUnitat').setValue([]);
        } else {
          if (unitats.length > importsUnitat.length) {
            // add last selected unitat to table and form
            const lastUnitatSelected: number = unitats.pop();
            importsUnitat = [...importsUnitat, this._createNewImportUnitat(lastUnitatSelected, this._parentFormGroup.get("dadesExpedient").get("id").value)];
            this._loadNomUnitatPromotora(lastUnitatSelected);
            this.importsForm.get('importsUnitat').setValue(importsUnitat);
          } else if (unitats.length < importsUnitat.length) {
            // find the importUnitat associated with the unitat that was unselected
            const index: number = importsUnitat.findIndex((importUnitat: ImportUnitatSharedFormModel) => {
              return !unitats.find((unitat: number) => importUnitat.unitatPromotora === unitat);
            });

            if (index !== -1) {
              this.nomUnitats.delete(importsUnitat[index].unitatPromotora);
              // delete the row
              importsUnitat.splice(index, 1);
              this.importsForm.get('importsUnitat').setValue(importsUnitat);
            }
          }
        }
      }
    });
  }

  private _detectTipusIvaLicitacioChanges(): void {
    this._onControlChange<number>(this.tipusIvaLicitacio, (item: number) => {
      if (item !== TipusIva.IVA_N4) {
        if (CustomUtils.isDefined(this.tipusIvaLicitacio.value) && this.importsForm.get("importLicitacioIVA").disabled) {
          // recalculate import licitacio
          this._setImportLicitacioIva(this.importLicitacio.value);
        }
        if (CustomUtils.isArrayNotEmpty(this.importsUnitatControl.value)) {
          this._updateLicitacioImportsPromotora();
          this._roundImports();
        }
      }
    });
  }

  private _detectTipusIvaAdjudicacioChanges(): void {
    this._onControlChange<number>(this.tipusIvaAdjudicacio, (item: number) => {
      if (item !== TipusIva.IVA_N4) {
        if (CustomUtils.isDefined(this.tipusIvaAdjudicacio.value) && this.importsForm.get("importAdjudicacioIVA").disabled) {
          // recalculate import adjudicació
          this._setImportAdjudicacioIva(this.importAdjudicacio.value);
        }
        if (CustomUtils.isArrayNotEmpty(this.importsUnitatControl.value)) {
          this._updateAdjudicacioImportsPromotora();
          this._roundImports();
        }
      }
    });
  }

  private _detectImportLicitacioChanges(): void {
    this._onControlChange(this.importLicitacio, (importLicitacio: number) => {
      if (this._importLicitacioActual !== importLicitacio) {
        if (CustomUtils.isDefined(this.tipusIvaLicitacio.value) && this.tipusIvaLicitacio.value !== TipusIva.IVA_N4) {
          this._setImportLicitacioIva(importLicitacio);
        }
        this.updateValorEstimatLicitacio();
        this._importLicitacioActual = importLicitacio;
      }
    });
  }

  private _detectImportAdjudicacioChanges(): void {
    this._onControlChange(this.importAdjudicacio, (importAdjudicacio: number) => {
      if (this._importAdjudicacioActual !== importAdjudicacio) {
        if (CustomUtils.isDefined(this.tipusIvaAdjudicacio.value) && this.tipusIvaAdjudicacio.value !== TipusIva.IVA_N4) {
          this._setImportAdjudicacioIva(importAdjudicacio)
        }
        // TODO: ???
        // this.updateValorEstimatAdjudicacio();
        this._importAdjudicacioActual = importAdjudicacio;
      }
    });
  }

  private _onMonedaChange(imports: ImportUnitatSharedFormModel[], moneda: number): ImportUnitatSharedFormModel[] {
    return imports.map((_import: ImportUnitatSharedFormModel) => {
      _import.moneda = moneda;
      return _import;
    });
  }

  private _detectMonedaChanges(): void {
    this._onControlChange<number>(this.importsForm.get("moneda"), (item: number) => {
      this.importsUnitatControl.setValue(this._onMonedaChange(this.importsUnitatControl.value, item));
    });
  }

  private _loadNomUnitatPromotora(id: number): void {
    this._service.getUnitatPromotoraLabel(id).subscribe((unitat: UnitatTreeModel) => this.nomUnitats.set(id, unitat.nom));
  }

  private _constructTipusIvaController(): void {
    this._tipusIvaController = new TipusIvaController({
      importLicitacio: this.importLicitacio,
      importAdjudicacio: this.importAdjudicacio,
      tipusIvaLicitacioData: <SelectItemData[]>this.templateValidators.tipusIvaLicitacio.data,
      tipusIvaAdjudicacioData: <SelectItemData[]>this.templateValidators.tipusIvaAdjudicacio.data,
      ivaLicitacio: this.tipusIvaLicitacio,
      ivaAdjudicacio: this.tipusIvaAdjudicacio
    });
  }

  private _setImportLicitacioIva(importLicitacio: number): void {
    this.importsForm.get('importLicitacioIVA').setValue(this._tipusIvaController.getImportLicitacioAmbIva(importLicitacio));
  }

  private _setImportAdjudicacioIva(importAdjudicacio: number): void {
    this.importsForm.get('importAdjudicacioIVA').setValue(this._tipusIvaController.getImportAdjudicacioAmbIva(importAdjudicacio));
  }

}
