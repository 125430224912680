import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from "@angular/forms";
import {GeecFormControl} from "../../../core/directives/reactive-forms/geec-form-control";
import {CustomUtils} from "../../utils/custom.utils";
import {Tooltip} from 'primeng/tooltip';
import {DomHandler} from 'primeng/api';
import {Subject} from "rxjs";
import {HelpText} from "./models/help-text.model";
import {HelpTextType} from "./models/help-text-type.enum";
import {takeUntil} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'geec-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpButtonComponent implements OnDestroy {

  private _helpText: HelpText | null;

  private _tooltip: Tooltip;

  private _control: GeecFormControl;

  private _mouseEnterListener: Function;

  private _mouseLeaveListener: Function;

  private _mouseClickListener: Function;

  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  @HostBinding('class') baseClass: string = 'help-button';

  @Input()
  public set control(ctrl: AbstractControlDirective | AbstractControl) {
    if (ctrl instanceof GeecFormControl) {
      this._control = <GeecFormControl> ctrl;
      this._control.helpText$.pipe(takeUntil(this._ngUnsubscribe)).subscribe((subject: HelpText) => {
        if (CustomUtils.isDefined(subject)) {
          this.helpText = subject;
        }
      });
    }
  }

  get helpText(): HelpText {
    return this._helpText;
  }

  set helpText(help: HelpText) {
    this._helpText = help;
    this._createTooltipInstance(this._helpText);
    this._detectorRef.detectChanges();
  }

  constructor(private _ngZone: NgZone, private _elRef: ElementRef,
              private _domHandler: DomHandler, private _detectorRef: ChangeDetectorRef,
              private _translate: TranslateService) {
  }

  private traducir(tag: string) : string{
    let ruta = 'help-button';
    return this._translate.instant(ruta + '.' + tag);
  }

  ngOnDestroy(): void {
    this._unbindEvents();
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  /**
   * *ngIf Showing logic
   * @returns {boolean}
   */
  public showComponent(): boolean {
    return CustomUtils.isDefined(this.helpText);
  }

  /**
   * Creates a {@link Tooltip} instance dynamically and binds it to this ElementRef
   * @param {HelpText} config
   * @private
   */
  private _createTooltipInstance(config: HelpText): void {
    if (this._tooltip) {
      this._unbindEvents();
    }
    this._tooltip = new Tooltip(this._elRef, this._domHandler, this._ngZone);
    this._tooltip.escape = false;

    switch (config.type) {
      case HelpTextType.HELP:
        this._tooltip.text = config.text;
        break;
      case HelpTextType.HELP_URL:
        this._tooltip.text = this.traducir('tooltip-texto');
        break;
    }

    this._tooltip.tooltipPosition = 'top';

    this._ngZone.runOutsideAngular(() => {
      this._mouseEnterListener = this._tooltip.onMouseEnter.bind(this._tooltip);
      this._mouseLeaveListener = this._tooltip.onMouseLeave.bind(this._tooltip);
      if (config.type === HelpTextType.HELP_URL) {
        this._mouseClickListener = this._onClick.bind(this);
        this._elRef.nativeElement.addEventListener('click', this._mouseClickListener);
      }
      this._elRef.nativeElement.addEventListener('mouseenter', this._mouseEnterListener);
      this._elRef.nativeElement.addEventListener('mouseleave', this._mouseLeaveListener);
    });
  }

  /**
   * OnClick event handler.
   * @private
   */
  private _onClick(): void {
    let url = this.helpText.text;
    if (!/^http:\/\//gi.test(url)) {
      url = `http://${url}`;
    }

    window.open(url, '_blank');
  }

  /**
   * Unbinding previously existing events.
   * @private
   */
  private _unbindEvents() {
    if (this._mouseEnterListener) {
      this._elRef.nativeElement.removeEventListener('mouseenter', this._mouseEnterListener);
    }
    if (this._mouseLeaveListener) {
      this._elRef.nativeElement.removeEventListener('mouseleave', this._mouseLeaveListener);
    }
    if (this._mouseClickListener) {
      this._elRef.nativeElement.removeEventListener('click', this._mouseClickListener);
    }
  }

}
