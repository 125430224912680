import {TramitacioEstructuralNode} from "./tramitacio-estructural-node";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioNodeAction} from "./model";
import {CustomUtils} from "../../shared/utils/custom.utils";

export class TramitacioDocumentGefactNode extends TramitacioEstructuralNode {

  public idFacturaGefact: number;
  public sistemaOrigen: string;
  public esElectronica: boolean;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.icon = 'fa fa-file-pdf-o';
    this.idFacturaGefact = backendJSON.idFacturaGefact;
    this.sistemaOrigen = backendJSON.sistemaOrigen;
    this.esElectronica = backendJSON.esElectronica;
    if (this.idFacturaGefact && this.sistemaOrigen && CustomUtils.isDefined(this.esElectronica)) {
      this.selectable = true;
      this.actions.push({key: TramitacioNodeAction.veureDocumentsFactura, labelButton: 'Visualitzar factura', iconButton: 'fa fa-search'});
      this.actions.push({
        key: TramitacioNodeAction.downloadFacturaGefact,
        labelButton: 'Descargar factura',
        iconButton: 'fa fa-download'
      });
    }
  }

}
