import {AbstractControl, AsyncValidatorFn, FormArray, FormGroup, ValidatorFn} from "@angular/forms";
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";
import {CustomUtils} from "../../../shared/utils/custom.utils";
import {GeecFormControl} from "./geec-form-control";
import {AbstractControlOptions} from '@angular/forms/src/model';

export class GeecFormGroup extends FormGroup {

  public specificRequired: LooseObject<Boolean>;

  constructor(public controls: LooseObject<AbstractControl>,
              validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
              asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(controls, validatorOrOpts, asyncValidator);

    this.updateSpecificRequiredStructure();
  }

  /**
   * Adds a control into the FormGroup and recalculates specificRequired
   * @param {string} name
   * @param {AbstractControl} control
   * @override
   */
  public addControl(name: string, control: AbstractControl): void {
    super.addControl.apply(this, arguments);
    this.updateSpecificRequiredStructure();
  }

  /**
   * Handles the implementation for Array's "markAsDirty"
   * @param {FormArray} formArray
   * @param {{onlySelf?: boolean; propagateDown?: boolean}} opts
   */
  private handleFormArrayDirty(formArray: FormArray, opts: { onlySelf?: boolean, propagateDown?: boolean }): void {
    formArray.controls.forEach(control => control.markAsDirty(opts));
  }

  /**
   * Marks itself as dirty or itself and all its children
   * @param {object} [opts={}]
   * @property {boolean} opts.[onlySelf]
   * @property {boolean} opts.[propagateDown]
   * @override
   */
  public markAsDirty(opts: { onlySelf?: boolean, propagateDown?: boolean } = {}): void {
    super.markAsDirty.apply(this, opts);

    if (opts.onlySelf && opts.propagateDown) {
      const controls = this.controlsToArray();
      if (controls.length > 0) {
        controls.forEach(control => {
          if (control instanceof FormArray) {
            return this.handleFormArrayDirty(control, opts);
          }
          control.markAsDirty(opts);
        });
      }
    }
  }

  /**
   * Updates the specificRequired tree.
   */
  public updateSpecificRequiredStructure(): void {
    let map: LooseObject<Boolean> = {};

    this.controlsToArray().forEach((control: AbstractControl) => {
      this._abstractControlStructureHandler(map, control);
    });

    this._calculateSpecificRequired(map);

    this.specificRequired = map;

    if (CustomUtils.isDefined(this.parent) && (this.parent instanceof GeecFormGroup)) {
      (this.parent as GeecFormGroup).updateSpecificRequiredStructure();
    }
  }

  /**
   * Converts controls into an array.
   * @returns {Array<AbstractControl>}
   * @private
   */
  public controlsToArray(): Array<AbstractControl> {
    return Object.keys(this.controls).map(k => this.controls[k]);
  }

  /**
   * Searches for controls that fullfils a given condition.
   * @param {(c: AbstractControl) => boolean} condition
   * @returns {boolean}
   * @private
   */
  private _anyControls(condition: (c: AbstractControl) => boolean): boolean {
    return this.controlsToArray().some((control: AbstractControl) => condition(control));
  }

  /**
   * Searches for controls that are empty or have a false specificRequirement.
   * @param {string} requirement
   * @returns {boolean}
   * @private
   */
  private _anySpecificEmptyOrFalse(requirement: string): boolean {
    return this._anyControls((control: AbstractControl) => {
      if (control instanceof GeecFormGroup) {

        return !CustomUtils.isUndefinedOrNull(control.specificRequired) &&
          control.specificRequired.hasOwnProperty(requirement) &&
          !control.specificRequired[requirement];

      } else if (control instanceof GeecFormControl) {

        return !CustomUtils.isUndefinedOrNull(control.specificRequired) &&
          control.specificRequired.includes(requirement) &&
          control.isEmptyInputValue();

      }

      return false;
    });
  }

  /**
   * Evaluates the entire tree and sets results for specificRequired
   * @param {LooseObject<Boolean>} map
   * @private
   */
  private _calculateSpecificRequired(map: LooseObject<Boolean>): void {
    Object.keys(map).forEach(key => {
      map[key] = !this._anySpecificEmptyOrFalse(key);
    });
  }

  /**
   * Calls a function depending on the type of control param.
   * @param {LooseObject<Boolean>} map
   * @param {AbstractControl} control
   * @private
   */
  private _abstractControlStructureHandler(map: LooseObject<Boolean>, control: AbstractControl) {
    if (control instanceof GeecFormGroup) {
      this._formGroupStructureHandler(map, control);
    } else if (control instanceof GeecFormControl) {
      this._formControlStructureHandler(map, control);
    }
  }

  /**
   * Handles the structure building for a FormGroup.
   * @param {LooseObject<Boolean>} map
   * @param {GeecFormGroup} control
   * @private
   */
  private _formGroupStructureHandler(map: LooseObject<Boolean>, control: GeecFormGroup) {
    if (CustomUtils.HOPAID(control, 'specificRequired')) {
      Object.keys(control.specificRequired).forEach(requirement => {
        if (!map.hasOwnProperty(requirement)) {
          map[requirement] = control.specificRequired[requirement];
        }
      });
    }
  }

  /**
   * Handles the structure building for a FormControl.
   * @param {LooseObject<Boolean>} map
   * @param {GeecFormControl} control
   * @private
   */
  private _formControlStructureHandler(map: LooseObject<Boolean>, control: GeecFormControl) {
    if (CustomUtils.HOPAID(control, 'specificRequired')) {
      control.specificRequired.forEach(requirement => {
        if (!map.hasOwnProperty(requirement)) {
          map[requirement] = false;
        }
      });
    }
  }
}
