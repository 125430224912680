import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {TramitacioNode} from "./tramitacio-node";
import {TramitacioNodeAction} from "./model";

export class TramitacioEvidenciesPscpNode extends TramitacioNode {

  public urlDocument: string;
  public nomDocument: string;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);

    this.nom = backendJSON.nom;
    this.icon = "fa fa-gavel";
    this.urlDocument = backendJSON.urlDocument;
    this.nomDocument = backendJSON.nomDocument;
    this.selectable = true;
    this.actions.push({key: TramitacioNodeAction.download, labelButton: 'Descargar', iconButton: 'fa fa-download'});
  }

}
