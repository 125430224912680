import {TramitacioNode} from "./tramitacio-node";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";

export enum TramitacioDocumentComptableNodeStates {
  enTramit = "EN_TRAMIT",
  anulat = "ANULAT",
  rebutjat = "REBUTJAT",
  completat = "COMPLETAT",
  requereixEsmenes = "REQUEREIX_ESMENES",
  retornatEsperantEsmenes = "RETORNAT_ESPERANT_ESMENES"
}

export class TramitacioDocumentComptableNode extends TramitacioNode {

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);
    this.icon = "fa fa-money";
    if (backendJSON.estat) {
      switch (backendJSON.estat) {
        case TramitacioDocumentComptableNodeStates.enTramit:
        case TramitacioDocumentComptableNodeStates.requereixEsmenes:
        case TramitacioDocumentComptableNodeStates.retornatEsperantEsmenes:
          this.styleClass = "assigned";
          break;
        case TramitacioDocumentComptableNodeStates.completat:
          this.styleClass = "complete";
          break;
        case TramitacioDocumentComptableNodeStates.anulat:
          this.styleClass = "document-anulat";
          break;
        case TramitacioDocumentComptableNodeStates.rebutjat:
          this.styleClass = "canceled";
          break;
      }
    }
    if (backendJSON.enProcesAnulacio) {
      this.styleClass = "document-proces-anulacio";
    }
  }
}
