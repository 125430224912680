import {Pipe, PipeTransform} from '@angular/core';
import {coerceNumberProperty} from "../../utils/coercion/number-property";

@Pipe({
  name: 'fileSizeFormat',
  pure: true
})
export class FileSizeFormatPipe implements PipeTransform {

  transform(value: any): any {
    const bytes: number = coerceNumberProperty(value);
    if (bytes === 0) {
      return '0 B';
    }
    const k: number = 1000;
    const dm: number = 3;
    const sizes: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
