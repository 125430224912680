import {AppConstants} from "../../../app.constants";
import {Injectable} from "@angular/core";
import {FaseModel} from "../../models/expedient/fase.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {CustomUtils} from "../../utils/custom.utils";
import {LooseObject} from "../../models/forms/reactive-form-validator";

@Injectable()
export class FaseService {

  constructor(private http: HttpClient) {
  }

  loadFases(searchText: string): Observable<FaseModel[]> {
    let paramsMap: LooseObject<string> = {};
    if (CustomUtils.isNotEmpty(searchText)) {
      paramsMap['like'] = searchText;
    }
    let params: HttpParams = new HttpParams({fromObject: paramsMap});
    return this.http.get<Array<FaseModel>>(AppConstants.urlGetFases, {params: params});
  }

}
