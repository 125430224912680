export class PropertyTree {

  public propertyName: string;
  public parent: PropertyTree;
  public children: PropertyTree;

  constructor(value: string) {
    this.value = value;
    this._createHierarchy(this.value);
  }

  private _value: string;

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    if (value !== this._value) {
      this._value = value;
      this._createHierarchy(value);
    }

  }

  public static difference(partial: PropertyTree, total: PropertyTree): PropertyTree {
    let _difference: string = total.value.replace(partial.value, '');
    if (_difference.startsWith('.')) {
      _difference = _difference.substring(1);
    }

    return new PropertyTree(_difference);
  }

  private _createHierarchy(value: string): void {
    let splittedValue: Array<string> = value.split('.');
    this.propertyName = splittedValue.pop();
    if (splittedValue.length > 0) {
      this.parent = new PropertyTree(splittedValue.join('.'));
      this.parent.children = this;
    }
  }

  public backtrack(obj: object, treePart: PropertyTree = this): PropertyTree {
    let minPath: PropertyTree = treePart || null;

    if (!obj[treePart.value] || !obj[treePart.propertyName]) {

      const parent: PropertyTree = treePart.parent;
      if (parent) {
        if (obj[parent.value]) {
          minPath = parent;
        } else {
          minPath = this.backtrack(obj, parent);
        }
      } else {
        minPath = null;
      }

    }

    return minPath;
  }
}
