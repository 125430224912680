import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Notice} from "../../../shared/models/notices/notice";
import {AppConstants} from "../../../app.constants";
import {Page} from "../../../shared/interfaces/paging/page";
import {PageableConfig} from "../tasca/tasca.service";
import {LooseObject} from "../../../shared/models/forms/reactive-form-validator";
import {ControllerResponse} from "../../../shared/models/forms/controller-response.model";
import {Mapper} from "../../decorators/mapper/mapper.decorator";
import {Mapping} from "../../decorators/mapper/classes/mapping";
import {objectToFormData} from "../../../shared/utils/conversion-utils";
import {environment} from "../../../../environments/environment";

export interface OfflineNotice {
  title: string;
  body?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  constructor(private http: HttpClient) {
  }

  public getOfflineNotice(): Observable<OfflineNotice> {
    if (environment.offlineNoticeUrl) {
      return this.http.get<OfflineNotice>(environment.offlineNoticeUrl, {
        headers: new HttpHeaders().set('offline', 'true'),
      });
    } else {
      return of(null);
    }
  }

  @Mapper([
    new Mapping('startDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('endDate', null, {constructor: Date, params: 'SELF'})
  ])
  public findNotices(onlyUrgent: boolean, onlyGcon: boolean): Observable<Notice[]> {
    const searchParams: LooseObject<string> = {urgentOnly: onlyUrgent.toString(), gconOnly: onlyGcon.toString()};
    return this.http.get<Notice[]>(AppConstants.urlNotice, {
      params: new HttpParams({fromObject: searchParams})
    });
  }

  @Mapper([
    new Mapping('content.startDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('content.endDate', null, {constructor: Date, params: 'SELF'})
  ])
  public filterNotices(pageConfig: PageableConfig, searchParams: LooseObject<string> = {}): Observable<Page<Notice>> {
    searchParams["page"] = JSON.stringify(pageConfig.page);
    searchParams["size"] = JSON.stringify(pageConfig.size);

    const params: HttpParams = new HttpParams({fromObject: searchParams});
    return this.http.get<Page<Notice>>(`${AppConstants.urlNotice}/filter`, {
      params: params
    });
  }

  @Mapper([
    new Mapping('startDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('endDate', null, {constructor: Date, params: 'SELF'})
  ])
  public findNotice(id: number): Observable<Notice> {
    return this.http.get<Notice>(`${AppConstants.urlNotice}/${id}`);
  }

  @Mapper([
    new Mapping('data.startDate', null, {constructor: Date, params: 'SELF'}),
    new Mapping('data.endDate', null, {constructor: Date, params: 'SELF'})
  ])
  public saveNotice(notice: Notice): Observable<ControllerResponse<Notice>> {
    const formData: FormData = objectToFormData(notice, {indices: true, nullsAsUndefineds: true, includeEmptyArray: false});
    return this.http.post<ControllerResponse<Notice>>(AppConstants.urlNotice, formData);
  }

  public downloadAttachment(id: number): Observable<ArrayBuffer> {
    return this.http.get(`${AppConstants.urlNotice}/attachment/${id}`, {responseType: "arraybuffer"});
  }

  public deleteNotice(id: number): Observable<ControllerResponse<any>> {
    return this.http.delete<ControllerResponse<any>>(`${AppConstants.urlNotice}/${id}`);
  }
}
