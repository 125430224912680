import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'geec-error-login',
  templateUrl: './error-login.component.html',
  styleUrls: ['./error-login.component.scss']
})
export class ErrorLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
