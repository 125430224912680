import {MapHandler} from "../abstract/map-handler";
import {Mapping} from "../mapping";
import {OperationHandlerFactory} from "../../factories/operation-handler.factory";
import {OperationHandler} from "../abstract/operation-handler";
import {proxyHandler} from "../../utils/proxyHandler";
import {PropertyTree} from "../property-tree";

export class DefaultMapHandler extends MapHandler {

  constructor(target: object) {
    super(target);
  }

  public map(mappings: Array<Mapping>): object {
    let targetProxy: object = new Proxy(this.target, proxyHandler);

    mappings.forEach((mapping: Mapping) => {
      const operation: OperationHandler = OperationHandlerFactory.new(mapping);

      if (!mapping.target) {
        // Operate on itself if target's value is 'SELF'.
        this.target = operation.operate(this.target).value;
        targetProxy = new Proxy(this.target, proxyHandler);
      } else {
        const _target: object = targetProxy[mapping.target.value];

        if (typeof _target !== 'undefined') {
          targetProxy[mapping.target.value] = operation.operate(_target).value;
        } else {
          mapping.backtrack(targetProxy);
          if (mapping.valid) {
            this._recursiveMap(targetProxy[mapping.minPath.value], mapping.minPath, mapping.target, operation);
          }
        }
      }
    });

    return this.target;
  }

  private _recursiveMap(target: Array<any>, presentPath: PropertyTree, fullPath: PropertyTree, operation: OperationHandler): void {
    target.forEach((item: any) => {
      if (Array.isArray(item)) {
        this._recursiveMap(item, presentPath, fullPath, operation);
      } else {
        const itemProxy: object = new Proxy(item, proxyHandler);
        const difference: PropertyTree = PropertyTree.difference(presentPath, fullPath);
        const _target: object = itemProxy[difference.value];

        if (_target) {
          itemProxy[difference.value] = operation.operate(_target).value;
        } else {
          const minPath: PropertyTree = difference.backtrack(itemProxy);

          if (minPath) {
            const nextObj: any = itemProxy[minPath.value] || itemProxy[minPath.propertyName];

            if (nextObj && Array.isArray(nextObj)) {
              this._recursiveMap(nextObj, minPath, difference, operation);
            }
          }
        }
      }
    });
  }
}
