import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TascaService} from '../../services/tasca/tasca.service';
import {Tasca} from '../../../shared/models/bustia/tasca.model';
import {NavigationStart, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {AppConstants} from "../../../app.constants";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {PermisUsuari} from "../../../shared/models/usuari/usuari.model";
import {NoticeBoardService} from "../../services/notice-board/notice-board.service";
import {BustiaCountModel} from "../../../bustia/model/bustia-count.model";
import {FacturaDocumentsModel} from "../../../shared/models/factura/factura-documents.model";
import {FacturaService} from "../../../shared/services/factura/factura.service";
import {Page} from "../../../shared/interfaces/paging/page";
import {EnvironmentUtils} from "../../../../environments/environment.utils";

@Component({
  selector: 'geec-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {


  permisosUsuari: typeof PermisUsuari = PermisUsuari;

  @Input()
  menuActive: boolean;
  @Output()
  changeMenuActive = new EventEmitter<void>();
  tasques: Tasca[];
  totalTasques: number;
  selectedTasca: Tasca;
  displayDialog: boolean = false;
  displayVeureTasques: boolean = false;
  totalFactures: number;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private tascaService: TascaService, private router: Router, private authService: AuthService,
              private noticeBoardService: NoticeBoardService, private _facturaService: FacturaService) {
  }

  ngOnInit(): void {
    // Ens subscribim a la variable i cada vegada que canvia la llista de tasques actualitzem les dades
    this.tascaService.tasques$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((bustiaCountModel: BustiaCountModel) => {
        this.tasques = bustiaCountModel.tasquesBustia.content;
        this.totalTasques = bustiaCountModel.countTotalTasquesBustia;
      });

    this.tascaService.selectedTasca$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedTasca: Tasca) => {
        this.selectedTasca = selectedTasca;
      });

    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.displayVeureTasques = (e.url !== AppConstants.routeBustia);
      }
    });

    if (this.authService.usuariTePermisos([PermisUsuari.BUSTIA_FACTURES])) {
      this._facturaService.getAllFactures().pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: Page<FacturaDocumentsModel>) => {
        this.totalFactures = result.totalElements;
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  selectTasca(tasca: Tasca) {
    this.displayDialog = false;
    this.tascaService.navigateToTaskWithRouteReuse(tasca);
  }

  isSelected(tasca: Tasca) {
    return ((this.selectedTasca != null) && (this.selectedTasca.id === tasca.id));
  }

  showDialog() {
    this.displayDialog = true;
  }

  changeMenu() {
    this.changeMenuActive.emit();
  }

  refreshTasques() {
    this.tascaService.refreshTasques().subscribe();
  }

  usuariTePermisos(permisos: PermisUsuari[], onlyOneNeeded: boolean = false): boolean {
    return this.authService.usuariTePermisos(permisos, onlyOneNeeded);
  }

  openNoticeBoard(): void {
    this.noticeBoardService.openNoticeBoard(false);
  }

  isPreOrPro(): boolean {
    return EnvironmentUtils.isPRE || EnvironmentUtils.isPRO;
  }

  usuariIncasol(): boolean {
    return this.authService.usuariIncasol();
  }

}
