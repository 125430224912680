import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {CustomUtils} from '../../utils/custom.utils';
import {AutoCompleteConfig} from '../utils/auto-complete-config';
import {MapperUtils} from '../../utils/mapper-utils';
import {LooseObject} from "../../models/forms/reactive-form-validator";
import {of} from "rxjs/internal/observable/of";
import {tap} from "rxjs/operators";
import {HttpClientUtils} from "../utils/http-client-utils";

export enum DadaMestraType {
  MODALITAT = "Modalitat",
  PROCEDIMENT = "Procediment",
  TIPUS_CONTRACTE = "TipusContracte",
  SUBTIPO_CONTRATO = "Subtipos",
  ACCIO_VALIDACIO_SIGNATURA = "AccioValidacioSignatura",
  ESTAT_VALIDACIO_PORTA_SIGNATURES = "EstatValidacioPortaSignatures",
  ESTAT_DOCUMENT = "EstatDocument",
  PAIS = "Pais",
  DIRECTIVA_APLICACION = "Directiva",
  TERCER_SECTOR = "TercerSector",
  TIPUS_DOCUMENT = "TipusDoc",
  TIPUS_VIA = "TipusVia",
  CODI_CPV = "CodiCPV",
  AMBIT_SUBJECTIU = "AmbitSubjectiu",
  ECOFIN = "Ecofin",
  TIPUS_EMPRESA = "TipusEmpresa",
  CONCEPTE_COFINANCAMENT_SOC = "ConcepteCofinancamentSOC",
  VALOR_COFINANCAMENT_SOC = "ValorCofinancamentSOC",
  TIPUS_TRAMITACIO = "TipusTramitacio",
  DESPESA_INGRES = "DespesaIngres",
  PRORROGA_PRESSUPOST = "ProrrogaPressupost",
  PROVINCIA = "Provincia",
  SISTEMA_ADJUDICACIO = "SistemaAdjudicacio",
  SUPOSIT_NEGOCIAT = "SupositNegociat",
  RESERVA_SOCIAL = "ReservaSocial",
  REVISIO_PREUS_ESTABLERT = "RevisioPreusEstablert",
  CRITERI_VALORACIO = "CriteriValoracio",
  MOTIU_REBUIG_FACTURA = "MotiuRebuigFactura",
  GRUP_CLASSIFICACIO_EMPRESARIAL = "GrupClassificacioEmpresarial",
  SUBGRUP_CLASSIFICACIO_EMPRESARIAL = "SubgrupClassificacioEmpresarial",
  CATEGORIA = "Categoria",
  OFERTA_ELECTRONICA = "OfertaElectronica",
  ESTAT_FACTURA_WF = "EstatFacturaWF",
  ESTAT_REGISTRE_FACTURA = "EstatRegistreFactura",
  TERMINI = "Termini",
  SUBTIPUS_PSCP = "SubtipusPSCP",
  SUBTIPUS_GLIC = "SubtipusGLIC",
  TRAMITACIO_SIMPLIFICADA = "TramitacioSimplificada",
  CARRECS_MEMBRES_MESA = "CarrecMembreMesa",
  TIPUS_BASAT_ACORD_MARC = "TipusBasatAcordMarc",
  TIPUS_BASAT_SISTEMA_DINAMIC_ADQUISICIO = "TipusBasatSistemaDinamicAdquisicio",
  ESTAT_LIQUIDACIO_EXTINCIO = "EstatLiquidacioExtincio",
  ESTAT_BARRAT = "EstatBarrat",
  ESTAT_BARRAT_FINAL = "EstatBarratFinal",
  TIPUS_GARANTIA = "TipusGarantia",
  TIPUS_OPERACIO_COMPTABLE = "TipusOperacioComptable",
  TIPUS_FISCALITZACIO = "TipusFiscalitzacio",
  MODEL_DADES_ECONOMIQUES = "ModelDadesEconomiques",
  ESTAT_CLIENT = "EstatClient",
  CERTIFICACIO = "Certificacio",
  PERSONA_FISICA_JURIDICA = "PersonaFisicaJuridica",
  NUTS = "Nuts",
  TIPUS_NOTICE = "TipusNotice",
  TIPUS_IVA = "TipusIva",
  CONTRATACION_ESTRATEGICA = "ContratacionEstrategica",
  CLAUSULAS_SOCIALES = "ClausulaSocial",
  CLAUSULAS_AMBIENTALES = "ClausulaAmbiental",
  COMPRA_INNOVADORA = "CompraInnovadora",
  TIPUS_CRITERIO_EXCLUSION_LICITADOR = "TipusCriterioExclusionLicitador",
  VEHICULOS_DIRECTIVA = "VehiculosDirectiva",
  VEHICULOS_CATEGORIA = "CategoriaVehiculosLimpios",
  VEHICULOS_TIPO = "TipoVehiculosLimpios",
  MOTIVO_NO_ADJUDICADO = "MotivoNoAdjudicado",
  CONDICION_ESPECIAL_EJECUCION = "CondicionEspecialEjecucion"
}

export class DadaMestra {
  id: number = null;
  nom: string = null;

  static isValidDadesMestres(dadesMestres: any[]): boolean {
    if (CustomUtils.isArrayNotEmpty(dadesMestres)) {
      let dadaMestraModel: DadaMestra = new DadaMestra();
      for (let dadaMestra of dadesMestres) {
        if (!MapperUtils.isValidModel(dadaMestra, dadaMestraModel)) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }
}

@Injectable()
export class DadaMestraService {

  private dadesMestres = [];

  constructor(private http: HttpClient) {
  }

  getOption(type: DadaMestraType, id: number): Observable<DadaMestra> {
    return this.http.get<any>(AppConstants.urlgetDadesMestres + "/" + type + "/" + id);
  }

  getOptionMultiple(type: DadaMestraType, ids: number[]): Observable<DadaMestra> {
    return this.http.get<any>(AppConstants.urlgetDadesMestres + "/" + type + "/" + ids);
  }

  getOptions(type: DadaMestraType, config?: AutoCompleteConfig): Observable<DadaMestra[]> {
    if (!config) {
      if (CustomUtils.isDefined(this.dadesMestres[type])) {
        return of(this.dadesMestres[type]);
      } else {
        return this.http.get<any[]>(AppConstants.urlgetDadesMestres + "/" + type).pipe(tap(dadesMestres => {
          this.dadesMestres[type] = dadesMestres;
        }));
      }
    } else {
      let paramsMap: LooseObject<string> = {};
      paramsMap = AutoCompleteConfig.loadConfig(paramsMap, config);

      let params: HttpParams = new HttpParams({fromObject: paramsMap});
      return this.http.get<any[]>(AppConstants.urlgetDadesMestres + "/" + type, {
        headers: (CustomUtils.isDefined(config.loading) && !config.loading) ? HttpClientUtils.NO_LOAD_HTTP_HEADER : null,
        params: (params)
      });
    }
  }

  getOptionsWithFilter(type: DadaMestraType, searchText: string, config: AutoCompleteConfig): Observable<DadaMestra[]> {
    let paramsMap: LooseObject<string> = {};
    if (CustomUtils.isDefined(searchText) && (searchText !== '')) {
      paramsMap["like"] = searchText;
    }
    if (config) {
      paramsMap = AutoCompleteConfig.loadConfig(paramsMap, config);
    }

    let params: HttpParams = new HttpParams({fromObject: paramsMap});
    if (config && !CustomUtils.isUndefinedOrNull(config.loading) && !config.loading) {
      return this.http.get<any[]>(AppConstants.urlgetDadesMestres + "/" + type, {
        headers: new HttpHeaders().set('no-load', 'false'),
        params: params,
      });
    } else {
      return this.http.get<any[]>(AppConstants.urlgetDadesMestres + "/" + type, {
        params: params
      });
    }
  }

  private validationSubject = new Subject<void>();

  validationEvent$ = this.validationSubject.asObservable();

  triggerValidation() {
    this.validationSubject.next();
  }

}
