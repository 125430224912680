import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GeecFormGroup} from '../../../core/directives/reactive-forms/geec-form-group';
import {DadaMestra, DadaMestraService, DadaMestraType} from '../../services/dada-mestra/dada-mestra.service';
import {CustomUtils} from '../../utils/custom.utils';
import {BrowserStorageUtils} from '../../utils/browser-storage-utils';
import {FormUtils} from "../../utils/form-utils";
import {CodisSharedFormValidatorModel} from "../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {CacheItemList} from '../../models/cache/cache-item-list.model';
import {DadaMestraCache} from "../../models/cache/dada-mestra-cache.model";
import {EntityModel} from "../../models/entity.model";
import {Subject} from "rxjs";
import {AutocompleteSearchEvent} from "../../interfaces/primeng/autocomplete-search-event";
import {AuthService} from "../../services/auth/auth.service";

export const CACHE_ITEM_KEY_CODIS_CPV: string = "codis-cpv";
export const CACHE_ITEM_VERSION_CODIS_CPV: string = "1.0";

@Component({
  selector: 'geec-codis',
  templateUrl: './codis.component.html'
})
export class CodisComponent implements OnInit, OnDestroy {

  /** Corticon definition */
  @Input() public templateValidators: CodisSharedFormValidatorModel;
  /** CodiCPV autocomplete options */
  codisCPV: DadaMestraCache[] = [];

  codigosCacheCPV: DadaMestraCache[] = [];

  get parentFormGroup(): GeecFormGroup {
    return this._parentFormGroup;
  }

  @Input()
  set parentFormGroup(parentFormGroup: GeecFormGroup) {
    this._parentFormGroup = parentFormGroup;
  }

  get codisForm(): FormGroup {
    return this.parentFormGroup.get("codis") as FormGroup;
  }

  /** User id to search in cache */
  private readonly _userId: number;
  /** The parent form group containing the codis form */
  private _parentFormGroup: GeecFormGroup;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private dadaMestraService: DadaMestraService, private authService: AuthService) {
    this._userId = this.authService.usuari.id;
  }

  ngOnInit() {
  }

  public getSelectedCodiCPV(): DadaMestra {
    const codiCPV: number = this.codisForm.get("codiCPV").value;
    if (CustomUtils.isUndefinedOrNull(codiCPV)) {
      return null;
    }
    return this.codisCPV.find((val: DadaMestraCache) => val.id === codiCPV);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  filterCodis(event: AutocompleteSearchEvent) {
    FormUtils.autocompleteSearchType(event, this.codisForm.get('codiCPV').value, (searchText: string) => {
      this.loadCodisCPV(searchText, false);
    }, (searchText) => {
      const cacheItemList: CacheItemList = BrowserStorageUtils.getCacheItemList({
          key: CACHE_ITEM_KEY_CODIS_CPV,
          userId: this._userId
        },
        CACHE_ITEM_VERSION_CODIS_CPV, false);
      if (CustomUtils.isDefined(cacheItemList) && DadaMestra.isValidDadesMestres(cacheItemList.content)) {
        // put cached codis in autocomplete
        this.codigosCacheCPV = cacheItemList.content.map((cacheItem: EntityModel) => {
          return new DadaMestraCache(<DadaMestra>cacheItem, true);
        });
      }
      this.loadCodisCPV(searchText, true);
    });
  }

  loadCodisCPV(searchText: string, concatCache: boolean = false) {
    this.dadaMestraService.getOptionsWithFilter(DadaMestraType.CODI_CPV, searchText, {
      page: 0,
      size: 25,
      loading: false
    }).subscribe((codisCPV: DadaMestra[]) => {
        if (!CustomUtils.isUndefinedOrNull(codisCPV)) {
          const codisMapped: DadaMestraCache[] = codisCPV.map((dadaMestra: DadaMestra) => {
            return new DadaMestraCache(dadaMestra);
          });
          if (concatCache) {
            this.codisCPV = this.codigosCacheCPV.concat(codisMapped);
          } else {
            this.codisCPV = codisMapped;
          }
        }
      },
      () => {
        console.log("No s'han pogut carregar els codis CPV.");
      }
    );
  }

  public formValidatorEventEmitter(): void {
    this.dadaMestraService.triggerValidation();
  }

}
