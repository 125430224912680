import {LocaleSettings} from "primeng/primeng";
import * as _addDays from 'date-fns/add_days';
import {TipusTermini} from "../interfaces/expedient/tipus-termini";
import {CustomUtils} from "./custom.utils";
import {AbstractControl} from "@angular/forms";
import {FormUtils} from "./form-utils";

export interface GeecLocale extends LocaleSettings {
  firstDayOfWeek: number;
  closeText: string;
  prevText: string;
  nextText: string;
  monthNames: string[];
  monthNamesShort: string[];
  dayNames: string[];
  dayNamesShort: string[];
  dayNamesMin: string[];
  weekHeader: string;
  firstDay: number;
  isRTL: boolean;
  showMonthAfterYear: boolean;
  yearSuffix: string;
  currentText: string;
  ampm: boolean;
  month: string;
  week: string;
  day: string;
  allDayText: string;
  timeOnlyTitle: string;
  timeText: string;
  hourText: string;
  minuteText: string;
  secondText: string;
  millisecText: string;
  microsecText: string;
  timezoneText: string;
  timeSuffix: string;
  amNames: string[];
  pmNames: string[];
}

export const LOCALE_CA: GeecLocale = {
  clear: "Reset",
  today: "Avui",
  firstDayOfWeek: 1,
  closeText: 'Tancar',
  prevText: 'Anterior',
  nextText: 'Següent',
  monthNames: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'],
  monthNamesShort: ['Gen', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Des'],
  dayNames: ['Diumenge', 'Dilluns', 'Dimarts', 'Dimecres', 'Dijous', 'Divendres', 'Dissabte'],
  dayNamesShort: ['Dmg', 'Dl', 'Dmt', 'Dmc', 'Dj', 'Dv', 'Ds'],
  dayNamesMin: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds'],
  weekHeader: 'Setmana',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: '',
  currentText: 'Data actual',
  ampm: false,
  month: 'Mes',
  week: 'Setmana',
  day: 'Dia',
  allDayText: 'Tot el dia',

  // time
  timeOnlyTitle: 'Només hora',
  timeText: 'Temps',
  hourText: 'Hora',
  minuteText: 'Minut',
  secondText: 'Segon',
  millisecText: 'Milisegons',
  microsecText: 'Microsegons',
  timezoneText: 'Zona horaria',
  timeSuffix: '',
  amNames: ['a.m.', 'AM', 'A'],
  pmNames: ['p.m.', 'PM', 'P']
};


export const LOCALE_ES: GeecLocale = {
  clear: "Reset",
  today: "Hoy",
  // date
  firstDayOfWeek: 1,
  closeText: "Cerrar",
  prevText: "<Ant",
  nextText: "Sig>",
  currentText: "Hoy",
  monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
  monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun",
    "jul", "ago", "sep", "oct", "nov", "dic"],
  dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  weekHeader: "Sm",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: "",
  ampm: false,
  month: 'Mes',
  week: 'Semana',
  day: 'Dia',
  allDayText: 'Todo el dia',

  // time
  timeOnlyTitle: 'Elegir una hora',
  timeText: 'Hora',
  hourText: 'Horas',
  minuteText: 'Minutos',
  secondText: 'Segundos',
  millisecText: 'Milisegundos',
  microsecText: 'Microsegundos',
  timezoneText: 'Uso horario',
  timeSuffix: '',
  amNames: ['a.m.', 'AM', 'A'],
  pmNames: ['p.m.', 'PM', 'P']
};

// add 0 days and 1 days must behave the same
export function addDays(date: Date, days: number): Date {
  if (days > 0) {
    return _addDays(date, days - 1);
  } else {
    return date;
  }
}

// 1 month = 30 days in GEEC 1.0
export function addMonths(date: Date, months: number): Date {
  return addDays(date, months * 30);
}

// 1 year = 365 days in GEEC 1.0
export function addYears(date: Date, years: number): Date {
  return addDays(date, years * 365);
}

export function calculateDataFi(termini: AbstractControl, tipusTermini: AbstractControl,
                                dataInici: AbstractControl, dataFi: AbstractControl,
                                addOneDay: boolean = false): void {
  if (FormUtils.isControlValid(termini) &&
    FormUtils.isControlValid(tipusTermini) &&
    FormUtils.isControlValid(dataInici) &&
    CustomUtils.areDefined(tipusTermini.value, termini.value, dataInici.value, dataFi)) {
    let data: Date = null;
    switch (tipusTermini.value) {
      case TipusTermini.DIES: {
        data = addDays(dataInici.value, termini.value);
        break;
      }
      case TipusTermini.MESOS: {
        data = addMonths(dataInici.value, termini.value);
        break;
      }
      case TipusTermini.ANYS: {
        data = addYears(dataInici.value, termini.value);
        break;
      }
    }
    if (data && addOneDay) {
      // add one more day (2 - 1 = 1)
      data = addDays(data, 2);
    }
    dataFi.setValue(data);
  }
}
