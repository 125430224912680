import {Component, EventEmitter, Input, Output} from "@angular/core";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {SelectItem} from "primeng/api";
import {IndicadorsSharedFormValidatorModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {FormGroup} from "@angular/forms";
import {AppConstants} from "../../../../app.constants";
import {ContratacionEstrategica} from "../../../models/indicadors/contratacion-estrategica";
import {Router} from "@angular/router";
import {DadaMestraService} from "../../../services/dada-mestra/dada-mestra.service";
import {AuthService} from "../../../services/auth/auth.service";
import {catchError} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {IndicadorsControllerService} from "./indicadors-controller.service";
import {VehiculosDirectiva} from "../../../models/indicadors/vehiculos-directiva";

@Component({
  template: ''
})
export class IndicadorsControllerComponent {

  /** The parent form group */
  @Input() public parentFormGroup: GeecFormGroup;
  /** The corticon definition */
  @Input() public templateValidators: IndicadorsSharedFormValidatorModel;
  /** Event to call corticon */
  @Output() public triggerValidation: EventEmitter<any> = new EventEmitter();
  booleanOptions: SelectItem[] = [...AppConstants.booleanOptions];
  instrumentoPublicidadOptions: SelectItem[] = [...AppConstants.instrumentoPublicidadOptions];

  mostrar: boolean = false;

  organismoOptions: SelectItem[];

  hasClausulasSociales: boolean;
  hasClausulasAmbientales: boolean;
  hasCompraInnovadora: boolean;
  hasVehiculosLimpios: boolean;

  contratacionEstrategicaOptions: SelectItem[] = [];
  clausulasSocialesOptions: SelectItem[] = [];
  clausulasAmbientalesOptions: SelectItem[] = [];
  comprasInnovadorasOptions: SelectItem[] = [];
  vehiculosDirectivaOptions: SelectItem[] = [];

  private readonly _userId: number;
  _isMenor: boolean;

  constructor(protected router: Router,
              protected dadaMestraService: DadaMestraService,
              protected service: IndicadorsControllerService,
              protected authService: AuthService,
              protected isMenor: boolean) {
    this._userId = this.authService.usuari.id;
    this._isMenor = isMenor;
    this._loadOptions();
  }

  private _loadOptions(): void {
    forkJoin(
      this.service.getContratacionEstrategica(this._isMenor),
      this.service.getClausulasSociales(),
      this.service.getClausulasAmbientales(),
      this.service.getCompraInnovadora(),
      this.service.getVehiculosDirectiva(),
    ).pipe(
      catchError(() => this.router.navigateByUrl("error"))
    ).subscribe(
      ([contratacionEstrategica, clausulasSociales, clausulasAmbientales, comprasInnovadoras, vehiculosDirectiva]:
         [SelectItem[], SelectItem[], SelectItem[], SelectItem[], SelectItem[]]) => {
        this.contratacionEstrategicaOptions = contratacionEstrategica;
        this.clausulasSocialesOptions = clausulasSociales;
        this.clausulasAmbientalesOptions = clausulasAmbientales;
        this.comprasInnovadorasOptions = comprasInnovadoras;
        this.vehiculosDirectivaOptions = vehiculosDirectiva;
      });
  }

  get indicadorsForm(): FormGroup {
    return this.parentFormGroup.get("indicadors") as FormGroup;
  }

  public formValidatorEventEmitter(event?: Event): void {
    this.triggerValidation.next(event);
  }

  public changeCofinancament(event?: Event): void {
    this.parentFormGroup.get('cofinancats').get('cofinancaments').setValue([]);
    this.formValidatorEventEmitter(event);
  }

  public changeTeReservaSocial(): void {
    this.indicadorsForm.get("reservaSocial").setValue(null);
    this.formValidatorEventEmitter();
  }

  public onChangeSubcontratacion(event: any) {
    this.mostrar = event.value ? true : false;
    this.formValidatorEventEmitter();
  }

  public changeContratacionEstrategicaNM(): void {
    const contratacionEstrategica: number[] = this.indicadorsForm.get("contratacionEstrategica").value;

    this.hasClausulasSociales = false;
    this.hasClausulasAmbientales = false;
    this.hasCompraInnovadora = false;

    if (contratacionEstrategica.length !== 0) {
      contratacionEstrategica.forEach(value => {
        if (value === ContratacionEstrategica.COMPRA_INNOVADORA) {
          this.hasCompraInnovadora = true;
        }
        if (value === ContratacionEstrategica.CLAUSULAS_SOCIALES) {
          this.hasClausulasSociales = true;
        }
        if (value === ContratacionEstrategica.CLAUSULAS_AMBIENTALES) {
          this.hasClausulasAmbientales = true;
        }
      });
    }

    this.indicadorsForm.get("teClausulasSociales").setValue(this.hasClausulasSociales);
    this.indicadorsForm.get("teClausulasAmbientales").setValue(this.hasClausulasAmbientales);
    this.indicadorsForm.get("teCompraInnovadora").setValue(this.hasCompraInnovadora);
  }

  public changeContratacionEstrategica(): void {
    const contratacionEstrategica: number[] = this.indicadorsForm.get("contratacionEstrategica").value;

    this.hasClausulasSociales = false;
    this.hasClausulasAmbientales = false;

    if (contratacionEstrategica.length !== 0) {
      contratacionEstrategica.forEach(value => {
        if (value === ContratacionEstrategica.CLAUSULAS_SOCIALES) {
          this.hasClausulasSociales = true;
        } else if (value === ContratacionEstrategica.CLAUSULAS_AMBIENTALES) {
          this.hasClausulasAmbientales = true;
        }
      });
    }

    this.indicadorsForm.get("teClausulasSociales").setValue(this.hasClausulasSociales);
    this.indicadorsForm.get("teClausulasAmbientales").setValue(this.hasClausulasAmbientales);
  }

  public changeVehiculosLimpios(): void {
    const vehiculosLimpios: number[] = this.indicadorsForm.get("vehiculosLimpios").value;

    this.hasVehiculosLimpios = false;

    if (vehiculosLimpios.length !== 0) {
      vehiculosLimpios.forEach(value => {
        if (value !== VehiculosDirectiva.NO_CVD) {
          this.hasVehiculosLimpios = true;
        }
      });
    }
  }


}
