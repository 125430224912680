import {CustomUtils} from '../../utils/custom.utils';
import {MapperUtils} from '../../utils/mapper-utils';

export class CacheItem {
  modelVersion: string = null;
  content: any = null;

  static isValid(cacheItem: any): boolean {
    return (CustomUtils.isDefined(cacheItem) && MapperUtils.isValidModel(cacheItem, new CacheItem()));
  }
}
