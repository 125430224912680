import {DadesExpedientSharedFormModel} from "../dades-expedient/dades-expedient-form.model";

export class DadesExtincioLiquidacioFormModel {
  id: number;
  expedientId: number;
  dadesExpedient: DadesExpedientSharedFormModel;

  dadesGenerals: DadesGeneralsExtincioLiquidacioFormModel;
  imports: ImportsExtincioLiquidacioFormModel;
  datesExtincioLiquidacio: DatesExtincioLiquidacioFormModel;
}

export class DadesGeneralsExtincioLiquidacioFormModel {
  titol: string;
  causaResolucio: number;
  revisioDePreus: boolean;
  incautacioGarantiaDefinitiva: boolean;
}

export class ImportsExtincioLiquidacioFormModel {
  despesaTotalContracte: number;
  despesaTotalContracteIva: number;
  importFacturat: number;
  importPendentFacturar: number;
  importCofinancatSobreImportPagat: number;
  entitatsPressupostaries: EntitatImportFacturat[];
  entitatsAnalitiques: EntitatImportFacturat[];
}

export class DatesExtincioLiquidacioFormModel {
  dataFiExecucio: Date;
  dataCertificacioFinal: Date;
  dataDevolucioGarantia: Date;
  dataRecepcio: Date;
  dataLiquidacio: Date;
  dataResolucio: Date;
  dataAudienciaContractista: Date;
}

export class EntitatImportFacturat {
  id: number;
  entitatPeticionaria: string;
  importFacturat: number;
}
