import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {TipusIvaModel} from "../interfaces/expedient/tipus-iva";
import {AppConstants} from "../../app.constants";
import {LooseObject} from "../models/forms/reactive-form-validator";

@Injectable()
export class TipusIvaService {

  constructor(private _http: HttpClient) {

  }

  getTipusIvaWithFilter(filter: string): Observable<TipusIvaModel[]> {
    let params: LooseObject<string> = {};
    if (filter) {
      params["like"] = filter;
    }

    const httpParams: HttpParams = new HttpParams({fromObject: params});

    return this._http.get<TipusIvaModel[]>(`${AppConstants.urlTipusIva}/search`, {params: httpParams});
  }
}
