import {GeecFormGroup} from "../../../core/directives/reactive-forms/geec-form-group";
import {Observable} from "rxjs";
import {ControllerResponse} from "./controller-response.model";
import {GeecFormDTOModel} from "./geec-form-dto.model";
import {ActionSubmit, FormService} from "../../../core/services/form.service";
import {GeecFormBuilder} from "../../../core/directives/reactive-forms/geec-form-builder";
import {RootInjectorService} from "../../../core/services/root-injector.service";
import {FrontDTO} from "./front-dto.model";
import {ValidationsTriggerType} from "../../../core/classes/geec-form.controller";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

export abstract class ReactiveFormBuilder {

  protected _gfb: GeecFormBuilder;
  protected _http: HttpClient;
  protected _formService: FormService;
  protected _translate: TranslateService;

  protected constructor() {
    this._gfb = RootInjectorService.injector.get<GeecFormBuilder>(GeecFormBuilder);
    this._http = RootInjectorService.injector.get<HttpClient>(HttpClient);
    this._formService = RootInjectorService.injector.get<FormService>(FormService);
    this._translate = RootInjectorService.injector.get<TranslateService>(TranslateService);
  }

  public abstract buildForm(): GeecFormGroup;

  // TODO: Canviar 'any' per un tipus mes apropiat.
  public abstract convertFormData(obj: object): any;

  public abstract submitForm(taskId: string, formDTO: any, action: ActionSubmit, autorize?: boolean): Observable<any>;

  public getValidations(name: string, formObject: FrontDTO, method: ValidationsTriggerType | string): Observable<GeecFormDTOModel> {
    return this._formService.getHybridFormRules(name, formObject, method);
  }

  public reassignToUP(taskId: string): Observable<ControllerResponse<boolean>> {
    return this._formService.reassignarTascaUnitatPromotora(taskId);
  }

}
