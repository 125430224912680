import {Component, EventEmitter, OnInit} from '@angular/core';
import {Notice} from "../../../shared/models/notices/notice";
import {NoticeService} from "../../services/notice-board/notice.service";
import {NoticeAttachment} from "../../../shared/models/notices/notice-attachment";
import * as FileSaver from "file-saver";

@Component({
  selector: 'geec-news-board',
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.scss']
})
export class NoticeBoardComponent implements OnInit {
  /** Whether to load only urgent notices or all. */
  loadUrgentNoticeOnly: boolean = false;
  /** Event emitted when user closes the dialog. */
  onClose$: EventEmitter<any> = new EventEmitter();
  loadGconOnly: boolean = true;

  notices: Notice[];

  constructor(private noticeService: NoticeService) {
  }

  ngOnInit() {
    this.noticeService.findNotices(this.loadUrgentNoticeOnly, this.loadGconOnly).subscribe((notices: Notice[]) => {
      this.notices = notices;
    }, () => {
      this.close();
    });
  }

  public close(): void {
    this.onClose$.emit();
  }

  public downloadAttachment(noticeAttachment: NoticeAttachment): void {
    this.noticeService.downloadAttachment(noticeAttachment.id).subscribe((res: ArrayBuffer) => {
      FileSaver.saveAs(new Blob([res]), noticeAttachment.fileName);
    });
  }

}
