import {CustomUtils} from './custom.utils';

export class MapperUtils {

  /**
   * Validates that obj has the same properties as model with the exception of functions (constructor, etc).
   * @param {Object} obj
   * @param {Object} model
   * @returns {boolean} true if obj is defined and has the same properties as model, else false
   */
  public static isValidModel(obj: Object, model: Object): boolean {
    let result: boolean = CustomUtils.isDefined(obj);

    if (result) {
      Object.getOwnPropertyNames(model)
        .filter((prop: string) => this._isFunctionOrPrivate(prop, model[prop]))
        .forEach((prop: string) => {
          if (!(prop in obj)) {
            return false;
          }

          if (this._isObjectAndHasProperties(model[prop]) && this._isObjectAndHasProperties(obj[prop])) {
            result = this.isValidModel(obj[prop], model[prop]);
          }
        });
    }

    return result;
  }

  /**
   * Validates that obj is an array and their items have the same properties as model with the exception of functions (constructor, etc).
   * @param {Object[]} obj
   * @param {Object} model
   * @returns {boolean}
   */
  public static isValidModelArray(obj: Object[], model: Object): boolean {
    let result: boolean = CustomUtils.isDefined(obj) && Array.isArray(obj);
    if (result) {
      for (let item of obj) {
        if (!MapperUtils.isValidModel(item, model)) {
          return false;
        }
      }
    }
    return result;
  }

  /**
   * Checks item's type and property length (if any).
   * @param item
   * @returns {boolean}
   * @private
   */
  private static _isObjectAndHasProperties(item: any): boolean {
    return CustomUtils.isDefined(item) && CustomUtils.isObject(item) && Object.getOwnPropertyNames(item).length > 0;
  }

  /**
   * Evaluates property type and its privacy (underscore naming convention).
   * @param {string} prop
   * @param evaluated
   * @returns {boolean}
   * @private
   */
  private static _isFunctionOrPrivate(prop: string, evaluated: any): boolean {
    return !CustomUtils.isFunction(evaluated) && prop.charAt(0) !== '_';
  }

  public static resolveFieldData(data: any, field: string): any {
    if (!data || !field) {
      return null;
    }

    if (field.indexOf('.') === -1) {
      return data[field];
    }

    const fields: string[] = field.split('.');
    let value: any = data;

    for (let i = 0, len = fields.length; i < len; ++i) {
      if (value == null) {
        return null;
      }
      value = value[fields[i]];
    }

    return value;
  }

}
