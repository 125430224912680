import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/* tslint:disable:component-selector */
@Component({
  selector: 'fa-icon',
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {

  @Input() icon: string;

  @Input() style: any;

  @Input() styleClass: string;

}
