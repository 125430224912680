import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomUtils } from '../../utils/custom.utils';
import { NumberUtils } from "../../utils/number-utils";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from 'rxjs';

export const NUMBER_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberInputComponent),
  multi: true
};

@Component({
  selector: 'geec-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [NUMBER_INPUT_VALUE_ACCESSOR]
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @Input() step: number = 1;

  @Input() spinStep: number = 1;

  @Input() min: number;

  @Input() max: number;

  @Input() maxlength: number;

  @Input() maxIntegerDigits: number = 13;

  @Input() size: number;

  @Input() placeholder: string;

  @Input() inputId: string;

  @Input() disabled: boolean;

  @Input() readonly: boolean;

  @Input() decimalSeparator: string = ',';

  @Input() thousandSeparator: string = '.';

  @Input() tabindex: number;

  @Input() formatInput: boolean = true;

  @Input() required: boolean;

  @Input() inputStyle: string;

  @Input() inputStyleClass: string;

  @Input() allowNegatives = false;

  @Input() allowPositives = true;
  /** Allow negative and positive values and negate positive values. Default value is false */
  @Input() forceOnlyNegatives = false;

  pTooltipText: string = "";

  value: number;

  valueAsString: string = '';

  keyPattern: RegExp = /[0-9\+\-]/;

  public precision: number;

  private decimalsPattern: RegExp = /,(\d+)$/;

  private trailingZerosPattern: RegExp = /,[1-9]*(0+)$/;

  public focus: boolean;

  public filled: boolean;

  @ViewChild('inputfield') inputfieldViewChild: ElementRef;

  onModelChange: Function = () => void 0;

  onModelTouched: Function = () => void 0;

  constructor(public el: ElementRef, private _translate: TranslateService) {
  }

  ngOnInit() {
    // convert step to precision ("0.01" -> 2 decimals)
    if (Math.floor(this.step) === 0) {
      this.precision = this.step.toString().split(/[,]|[.]/)[1].length;
    }

    const limit: number = this._getMaxValue();

    if (this.forceOnlyNegatives) {
      this.allowPositives = true;
      this.allowNegatives = true;
    }

    if (CustomUtils.isUndefinedOrNull(this.max)) {
      this.max = this.allowPositives ? limit : 0;
    }

    if (CustomUtils.isUndefinedOrNull(this.min)) {
      this.min = this.allowNegatives ? limit * -1 : 0;
    }

    if (this.decimalSeparator === '.') {
      this.decimalsPattern = /\.(\d+)$/;
      this.trailingZerosPattern = /\.[1-9]*(0+)$/;
    }

    this.initPTooltip();
  }

  /**
   * Inits ptooltip with component setup related instructions.
   */
  initPTooltip() {
    // Initial setup independent text
    this._translate.get("number-input.initial").subscribe((res: string) => {
      this.pTooltipText += res;
    });

    // List of dependent setup texts
    this.pTooltipText += "<ul>";

    // Conditions to add the property related text
    if (!CustomUtils.isUndefinedOrNull(this.precision)) {
      this._translate
        .get("number-input.precision", { value: this.precision })
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });

        if (!CustomUtils.isUndefinedOrNull(this.decimalSeparator)) {
          this._translate
            .get("number-input.decimalSeparator", { value: this.decimalSeparator })
            .subscribe((res: string) => {
              this.pTooltipText += "<li>" + res + "</li>";
            });
        }
    }
    if (!CustomUtils.isUndefinedOrNull(this.spinStep)) {
      this._translate
        .get("number-input.spinStep", { value: this.spinStep })
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });
    }
    if (!CustomUtils.isUndefinedOrNull(this.min)) {
      this._translate
        .get("number-input.min", { value: this.min })
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });
    }
    if (!CustomUtils.isUndefinedOrNull(this.max)) {
      this._translate
        .get("number-input.max", { value: this.max })
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });
    }
    if (!CustomUtils.isUndefinedOrNull(this.maxlength)) {
      this._translate
        .get("number-input.maxlength", { value: this.maxlength })
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });
    }
    if (!CustomUtils.isUndefinedOrNull(this.maxIntegerDigits)) {
      this._translate
        .get("number-input.maxIntegerDigits", { value: this.maxIntegerDigits })
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });
    }
    if (!CustomUtils.isUndefinedOrNull(this.thousandSeparator)) {
      this._translate
        .get("number-input.thousandSeparator", {
          value: this.thousandSeparator,
        })
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });
    }
    if (!CustomUtils.isUndefinedOrNull(this.allowNegatives)) {
      if (this.allowNegatives) {
        this._translate
          .get("number-input.allowNegatives.true")
          .subscribe((res: string) => {
            this.pTooltipText += "<li>" + res + "</li>";
          });
      } else {
        this._translate
          .get("number-input.allowNegatives.false")
          .subscribe((res: string) => {
            this.pTooltipText += "<li>" + res + "</li>";
          });
      }
    }
    if (!CustomUtils.isUndefinedOrNull(this.formatInput)) {
      this._translate
        .get("number-input.formatInput.true")
        .subscribe((res: string) => {
          this.pTooltipText += "<li>" + res + "</li>";
        });
    }

    this.pTooltipText += "</ul>";
  }

  writeValue(value: any): void {
    this.value = value;
    this.formatValue();
    this.updateFilledState();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  private updateFilledState() {
    this.filled = (this.value !== undefined && this.value != null);
  }

  private spin(event: Event, dir: number) {
    let step = this.spinStep * dir;
    let currentValue = this.value || 0;

    if (this.precision) {
      this.value = NumberUtils.round(currentValue + step, this.precision);
    } else {
      this.value = currentValue + step;
    }

    if (this.maxlength !== undefined && this.value.toString().length > this.maxlength) {
      this.value = currentValue;
    }

    if (CustomUtils.isDefined(this.min) && this.value < this.min) {
      this.value = this.min;
    }

    if (CustomUtils.isDefined(this.max) && this.value > this.max) {
      this.value = this.max;
    }

    this.formatValue();
    this.onModelChange(this.value);
    this.onChange.emit(event);
  }

  onInputKeydown(event: KeyboardEvent) {
    if (event.which === 38) {
      this.spin(event, 1);
      event.preventDefault();
    } else if (event.which === 40) {
      this.spin(event, -1);
      event.preventDefault();
    }
  }

  onInputKeyPress(event: KeyboardEvent) {
    let inputChar = String.fromCharCode(event.charCode);
    // 8 = space
    // 9 = tab
    // 37 = left arrow
    // 39 = right arrow
    // 46 = delete
    if (!this.keyPattern.test(inputChar) && (inputChar !== this.decimalSeparator) && (event.keyCode !== 9) && (event.keyCode !== 8)
      && (event.keyCode !== 37) && (event.keyCode !== 39) && (event.keyCode !== 46)) {
      event.preventDefault();
    }
  }

  onInputKeyup(event: Event) {
    let currentStringValue = (<HTMLInputElement>event.target).value;
    this.value = this.parseValue(currentStringValue);
    this.formatValue(currentStringValue);
    this.onModelChange(this.value);
    this.updateFilledState();
    this.onChange.emit(event);
  }

  onInputBlur(event) {
    if (this.precision) {
      this.addPaddingDecimals();
    }
    this.focus = false;
    this.onModelTouched();
    this.onBlur.emit(event);
  }

  onInputFocus(event) {
    if (this.precision) {
      this.removePaddingDecimals();
    }
    this.focus = true;
    this.onFocus.emit(event);
  }

  handleChange(event: Event) {
    this.onChange.emit(event);
  }

  private parseValue(val: string): number {
    let value: number;

    // remove thousand separator from string
    if (this.formatInput) {
      val = val.split(this.thousandSeparator).join('');
    }

    if (val.trim() === '') {
      value = null;
    } else {
      if (this.precision) {
        value = parseFloat(val.replace(',', '.'));
      } else {
        value = parseInt(val, 10);
      }

      if (!isNaN(value)) {
        if (this.precision && (NumberUtils.countDecimals(value) > this.precision)) {
          // if value has more decimals than precision truncate it
          value = NumberUtils.truncate(value, this.precision);
        }

        if (CustomUtils.isDefined(this.max) && value > this.max) {
          value = this.max;
        }

        if (CustomUtils.isDefined(this.min) && value < this.min) {
          value = this.min;
        }

        if (this.forceOnlyNegatives && value > 0) {
          value = -value;
        }
      } else {
        value = null;
      }
    }

    return value;
  }

  private formatValue(stringValue?: string): void {
    if (this.value !== null && this.value !== undefined) {
      // replace number decimal character ('.') with decimalSeparator
      let textValue = String(this.value).replace('.', this.decimalSeparator);

      // add thousand separators each 3 digits
      if (this.formatInput) {
        // check for regex escape characters
        let shouldEscape = /[\.\\\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:\-]/.test(this.decimalSeparator);
        // match up to the decimalSeparator, escaping if necessary
        let numParts = textValue.match(new RegExp("^[^" + (shouldEscape ? "\\" : "") + this.decimalSeparator + "]*"));

        if (numParts.length > 0) {
          textValue = textValue.replace(numParts[0], numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator));
        }
      }

      if (this.precision) {
        let trailingZerosToAdd: number;
        if (CustomUtils.isDefined(stringValue)) {
          const decimalSeparatorPosition = stringValue.indexOf(this.decimalSeparator);
          const trailingZerosMatch: RegExpMatchArray = stringValue.match(this.trailingZerosPattern);
          if (CustomUtils.isNotEmpty(trailingZerosMatch)) {
            trailingZerosToAdd = trailingZerosMatch[1].length;
          }
          if (this.hasDecimals()) {
            if (trailingZerosToAdd) {
              // convert based on user input example: '53,1' into '53,10'
              textValue = this.addTrailingZeros(textValue, trailingZerosToAdd);
            }
          } else if (decimalSeparatorPosition !== -1) {
            if (decimalSeparatorPosition === (stringValue.length - 1)) {
              // convert based on user input example: '5' into '5,'
              textValue = textValue + this.decimalSeparator;
            } else if (trailingZerosToAdd) {
              // convert based on user input example: '5' into '5,0'
              textValue = this.addTrailingZeros(textValue + this.decimalSeparator, trailingZerosToAdd);
            }
          }
        } else {
          if (this.hasDecimals()) {
            // convert example: '1210,7' into '1210,70'
            trailingZerosToAdd = this.precision - NumberUtils.countDecimals(this.value);
            textValue = this.addTrailingZeros(textValue, trailingZerosToAdd);
          } else {
            // convert example: '1210' into '1210,00'
            textValue = this.addTrailingZeros(textValue + this.decimalSeparator, this.precision);
          }
        }
      }

      if (this.min < 0 && (stringValue === '-0' || stringValue === '-0,')) {
        this.valueAsString = stringValue;
      } else {
        this.valueAsString = textValue;
      }
    } else if ((this.min < 0) && CustomUtils.isDefined(stringValue) && (stringValue.length === 1) && stringValue === '-') {
      this.valueAsString = '-';
    } else {
      this.valueAsString = '';
    }

    this.inputfieldViewChild.nativeElement.value = this.valueAsString;
  }

  private addPaddingDecimals() {
    if (CustomUtils.isDefined(this.value)) {
      const decimalSeparatorPosition = this.valueAsString.indexOf(this.decimalSeparator);
      if (decimalSeparatorPosition !== -1) {
        this.valueAsString = this.addTrailingZeros(this.valueAsString, this.precision - (this.valueAsString.length - decimalSeparatorPosition - 1));
      } else {
        this.valueAsString = this.addTrailingZeros(this.valueAsString + this.decimalSeparator, this.precision);
      }
      this.inputfieldViewChild.nativeElement.value = this.valueAsString;
    }
  }

  private removePaddingDecimals() {
    if (CustomUtils.isDefined(this.value)) {
      const decimalSeparatorPosition = this.valueAsString.indexOf(this.decimalSeparator);
      if (decimalSeparatorPosition !== -1) {
        // delete all zeros before end of string (35,20 -> 35,2)
        const newValue = this.valueAsString.replace(/\.?0+$/, '');
        if (newValue[newValue.length - 1] === this.decimalSeparator) {
          // remove decimal separator
          this.valueAsString = newValue.slice(0, -1);
        } else {
          this.valueAsString = newValue;
        }
        this.inputfieldViewChild.nativeElement.value = this.valueAsString;
      }
    }
  }

  private hasDecimals() {
    return (this.value.toString().indexOf('.') !== -1);
  }

  private addTrailingZeros(text: string, times: number) {
    if (times > 0) {
      let decimalsMatcher = text.match(this.decimalsPattern);
      if (decimalsMatcher) {
        const decimals = decimalsMatcher[1].length;
        if (decimals + times <= this.precision) {
          return text + "0".repeat(times);
        } else {
          // add '0' up to maximum digits of precision
          return text + "0".repeat(this.precision - decimals);
        }
      } else if (times <= this.precision) {
        return text + "0".repeat(times);
      } else {
        return text + "0".repeat(this.precision);
      }
    }
    return text;
  }

  private _getMaxValue(): number {
    let limit: number = NumberUtils.maxValuePossible(this.maxIntegerDigits);
    if (CustomUtils.isDefined(this.precision)) {
      const precisionLimit: number = NumberUtils.maxValuePossible(this.precision);
      const multiplier: number = Math.pow(10, (this.precision * -1));
      limit = limit + (precisionLimit * multiplier);
    }
    return limit;
  }

}


